import React from "react";
import frame14 from "../images/products/frame_14.png";
import icon from "../images/icons/icon_4.png";
import icon2 from "../images/icons/icon_5.png";
import icon3 from "../images/icons/icon_6.png";
const InstaCheck = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px] main_padding mt-[75px]">
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        className=" text-start md:order-1 order-2"
      >
        <span className="title_border text-start">InstaCheck</span>
        <h1 className="text_bg leading-[45px] lg:my-[35px] my-5 text-start">
          Verification with privacy and security
        </h1>
        <div className="space-y-[10px]">
          <div className="flex items-start gap-[25px]">
            <img src={icon} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">Verify on the web</h1>
              <p className="knoct_pro_hero_child_text leading-[22px !important]">
                Your stakeholders can display the Verification QR Code on the
                web using a URL that holders can scan with their Knoct Dock
                Wallets.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon2} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">Data Minimization</h1>
              <p className="knoct_pro_hero_child_text leading-[22px !important]">
                Regulations such as GDPR require the minimization of data
                processing. This means that organizations are obligated to only
                use, collect, or access data necessary for the intended
                operation.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon3} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">Data Portability</h1>
              <p className="knoct_pro_hero_child_text leading-[22px !important]">
                Documents issued through Knoct Dock are portable. Knoct Dock's
                technology is built on W3C open standards such as Verifiable
                Credentials and Decentralized Identifiers.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        className="colorful_bg flex items-end justify-center p-[25px] pb-0 overflow-hidden md:order-2 order-1"
      >
        <img src={frame14} alt="" className=" lg:w-[418px] shadow_img" />
      </div>
    </div>
  );
};

export default InstaCheck;
