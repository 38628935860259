import React, { useEffect } from "react";
import hero from "../../../images/Resources/web_hero.png";

import { FaArrowRight } from "react-icons/fa";

import web1 from "../../../images/Resources/web_1.png";
import web2 from "../../../images/Resources/web_2.png";
import web3 from "../../../images/Resources/web_3.png";
import FreeAccount from "../../../components/FreeAccount";
import HearCustomers from "../../../components/HearCustomers";
import ProductFaqs from "../../../components/ProductFaqs";
import Questions from "../../../components/Questions";
import { productsWeb } from "../../../data/products";
import CustomeSlider from "../../../components/CustomeSlider";
import ScrollToTop from "react-scroll-to-top";
import { FiArrowUpCircle } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
const Web3Dapps = () => {

   const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="pt-[75px]">
      <div className="main_padding">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="lg:px-[75px] lg:py-[46px] p-5  mx-auto md:rounded-[50px] rounded-[30px] max-w-[1172px]"
        >
          <div
            data-aos-duration="1000"
            data-aos="zoom-in"
            className="flex flex-col justify-center gap-5 items-start text-start md:w-[562px] w-[75%]"
          >
            <span className="title_border">Knoct for WEB3 Dapps</span>
            <h1 className="md:text-[40px] lg:text-[56px] sm:text-[30px] leading-[64px] text-4xl font-semibold">
              Permissioned Access for your DAPP
            </h1>
            <p className="md:text-[16px] text-sm md:w-[75%]">
              Whether you’re operating a DAO, Discord Servers or NFT projects,
              use Knoct Citizen, and Integrated Permissioning tool.{" "}
            </p>
            <Link to={'/coming'} className="btn-solid px-4 py-2 flex items-center gap-3 rounded-lg">
              Get Started <FaArrowRight />
            </Link>
          </div>
        </div>
      </div>
      <div className="my-[91px] lg:px-[200px] px-5 main_padding">
        <div className="flex md:flex-row flex-col gap-5 justify-between items-center">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="flex flex-col justify-center gap-[10px] items-start text-start lg:w-[563px] order-2 md:order-1"
          >
            <span className="title_border">User Profile</span>
            <h1 className="lg:text-[64px] md:text-5xl  lg:leading-[75px] text-4xl font-semibold">
              <span className="text_gray_shadow">Your user Profile for </span>
              <span className="text-green-400 ">WEB3</span>
            </h1>
            <p className="text-sm">
              Access Web3 platforms with versatile identity owned by you, built
              around unique self expression
            </p>
            <Link to={'/coming'} className="btn-solid">Claim Your Profile</Link>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="order-1 md:order-2"
          >
            <img
              src={web1}
              alt=""
              className="lg:w-[444px] icon_shadow w-full"
            />
          </div>
        </div>
      </div>
      <div className="lg:px-[200px] px-5 main_padding">
        <div className="flex md:flex-row flex-col gap-[40px] justify-between items-center">
          <div data-aos="fade-left" data-aos-duration="1000">
            <img src={web2} alt="" className="lg:w-[370px] icon_shadow " />
          </div>

          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            className="flex flex-col justify-center gap-8 items-start text-start md:w-[637px]"
          >
            <span className="title_border">Selective Disclosure</span>
            <h1 className="lg:text-[64px] md:text-5xl  lg:leading-[75px] text-4xl font-semibold">
              <span className="text-green-400">Selective Disclosure</span>
              <span className="text_gray_shadow">
                {" "}
                gives full control over your profile
              </span>
            </h1>
            <p className="md:text-[16px] text-sm">
              You are more than your wallet. Create a public profile to showcase
              your NFTs
            </p>
          </div>
        </div>
      </div>
      <div className="lg:px-[200px] lg:pt-[91px]  main_padding mt-[75px]">
        <div className="flex md:flex-row flex-col gap-5 justify-between items-center">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="order-1 md:order-2 lg:w-[487px]"
          >
            <img src={web3} alt="" className=" object-contain lg:w-full " />
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="flex flex-col justify-center gap-[10px] items-start text-start md:w-[530px] order-2 md:order-1"
          >
            <span className="title_border">User Profile</span>
            <h1 className="lg:text-[64px] md:text-5xl text-4xl  lg:leading-[75px] font-semibold ">
              <span className="text_gray_shadow">
                Attach all Crypto addresses to
              </span>{" "}
              <br />
              <span className="text-green-400">One Identity</span>
            </h1>
            <p className="md:text-[16px] text-sm">
              Access Web3 platforms with versatile identity owned by you, built
              around unique self expression
            </p>
            <Link to={'/coming'} className="btn-solid">Get Started</Link>
          </div>
        </div>
      </div>
      <div>
        <div className=" others_display_content">
          <div className="mx-auto w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 py-16 g-5 md:gap-[200px] gap-7 main_padding mt-[75px]">
            {productsWeb.map((product, index) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                key={index}
                className="flex justify-center items-center flex-col gap-[20px] max-w-[261px] mx-auto"
              >
                <div className="customer_optimize_icon">
                  <img
                    src={product.image}
                    alt={product.value}
                    className="w-[100px]"
                  />
                </div>
                <h3 className="knoct_sub_normal_header leading-[29px !important] text-white">
                  {product.value}
                </h3>
              </div>
            ))}
          </div>
        </div>
        <div className="mobile_carousal">
          <CustomeSlider>
            {productsWeb.map((product, index) => (
              <div
                key={index}
                className="flex justify-center items-center flex-col gap-[20px] max-w-[261px] mx-auto"
              >
                <div className="customer_optimize_icon">
                  <img
                    src={product.image}
                    alt={product.value}
                    className="w-[100px]"
                  />
                </div>
                <h3 className="knoct_sub_normal_header leading-[29px !important] text-white">
                  {product.value}
                </h3>
              </div>
            ))}
          </CustomeSlider>
        </div>
      </div>
      <FreeAccount />
      <HearCustomers />
      <ProductFaqs />
      <Questions />
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default Web3Dapps;
