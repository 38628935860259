import React from "react";
import frame14 from "../images/products/flow_2.png";
import icon from "../images/icons/flow_3.png";
import icon2 from "../images/icons/flow_4.png";
const VerifyMade = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px] main_padding mt-[75px]">
      <div  data-aos="fade-left"
       data-aos-duration="1000" className="colorful_bg flex justify-center items-center md:px-[53px] md:py-[62px] p-5">
        <img src={frame14} alt="" className="w-[512px]" />
      </div>
      <div  data-aos="fade-left"
       data-aos-duration="1000" className=" text-start flex justify-center flex-col gap-[10px]">
        <h1 className="text_bg leading-[50px !important] text-start">
          Verifying made easy
        </h1>
        <div className="flex items-start gap-[25px]">
          <img src={icon} alt="" className="w-[45px] h-[45px]" />
          <div className="">
            <h1 className="knoct_sub_normal_header leading-[29px!important]">
              Custom Proof-request templates
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px!important]">
              Configure custom proof request templates to specify exactly what
              data you require
            </p>
          </div>
        </div>
        <div className="flex items-start gap-[25px]">
          <img src={icon2} alt="" className="w-[45px] h-[45px]" />
          <div className="">
            <h1 className="knoct_sub_normal_header leading-[29px!important]">
              Easily Verify and Manage Credentials
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px!important]">
              Request and verify credentials from a task-oriented app perfect
              for in-person use cases. Store, send, or discard data as required
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyMade;
