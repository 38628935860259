import React from "react";
import profile from "../images/profile.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ClientReviewsSlider = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 900, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };
  const data = [1];
  return (
    <Carousel
      infinite={true}
      autoPlaySpeed={3000}
      autoPlay={true}
      keyBoardControl={true}
      responsive={responsive}
      dotListClass="custom-dot-list-style"
      containerClass="carousel-container"
      itemClass="carousel-item-padding-40-px"
    >
      {data.map((item, index) => (
        <div className=" border-gray-400 border px-8 shadow flex justify-center flex-col gap-5 items-center rounded-xl bg-gradient-to-t to-gray-800 from-black py-16 lg:mx-0 md:mx-0  sm:mx-2 mx-0">
          <div>
            <div className="avatar">
              <div className="w-16 rounded-full">
                <img src={profile} alt="profile" />
              </div>
            </div>
          </div>
          <div className="rating space-x-3">
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-orange-300"
              checked
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-orange-300"
              checked
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-orange-300"
              checked
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-orange-300"
              checked
            />
            <input
              type="radio"
              name="rating-2"
              className="mask mask-star-2 bg-orange-300"
              checked
            />
          </div>
          <p>
            Knoct has been a life saver for our team. We work with numberous
            softwares, tools and websites, having the power to login with a
            touch saves our team hours in time in managing and remembering
            credentials.
          </p>
          <div className="space-x-3 flex items-center justify-center">
            <p>— Nirdesh Dwa</p>
            <p className="font-thin">CEO, veda-app.com</p>
          </div>
        </div>
      ))}
      <div className=" border-gray-400 border px-8 shadow flex justify-center flex-col gap-5 items-center rounded-xl bg-gradient-to-t to-gray-800 from-black py-16 h-full">
          
          <p className="text-[40px] font-bold">
            Coming Soon
          </p>
          
        </div>
      <div className=" border-gray-400 border px-8 shadow flex justify-center flex-col gap-5 items-center rounded-xl bg-gradient-to-t to-gray-800 from-black py-16 h-full">
          
          <p className="text-[40px] font-bold">
            Coming Soon
          </p>
          
        </div>
     
    </Carousel>
  );
};

export default ClientReviewsSlider;
