import React from "react";
import frame14 from "../images/products/wallet_4.png";
import icon from "../images/icons/wallet_7.png";
import icon2 from "../images/icons/wallet_8.png";
const DigitalWallet = () => {
  return (
    <div className="main_padding">
      <h1 className="text-center header my-[75px]">
        Turn any app into a secure digital wallet, with our SDK.
      </h1>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px] md:order-1 order-2">
        <div
           data-aos="fade-right"
           data-aos-duration="1000"
          className="flex justify-center items-center"
        >
          <div className=" text-start lg:space-y-[15px] space-y-[10px]">
            <h1 className="text_bg leading-[50px] text-start">
              A customizable look and feel.
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px!important]">
              the powerful multi-factor authentication (MFA) solution that
              enables users to securely access various protocols and
              applications using a single wallet.
            </p>
            <div className="flex items-start gap-[25px]">
              <img src={icon} alt="" className="w-[45px] h-[45px]" />
              <div className="space-y-1">
                <h1 className="knoct_sub_normal_header">Easy Customization.</h1>
                <p className="knoct_pro_hero_child_text leading-[22px!important]">
                  Easily adjust color schemes, iconography, and other user
                  interface components
                </p>
              </div>
            </div>
            <div className="flex items-start gap-[25px]">
              <img src={icon2} alt="" className="w-[45px] h-[45px]" />
              <div className="space-y-1">
                <h1 className="knoct_sub_normal_header">
                  Seamless Integration.
                </h1>
                <p className="knoct_pro_hero_child_text leading-[22px!important]">
                  Deploy a market-tested user interface, readily customizable
                  for your specific use cases — or integrate seamlessly into
                  your existing app experience
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
           data-aos="fade-right"
           data-aos-duration="1000"
          className=" colorful_bg flex justify-center items-end overflow-hidden md:order-2 order-1"
        >
          <img
            src={frame14}
            alt=""
            className="lg:w-[420px] shadow_img md:mt-[110px] px-[25px] pt-[25px]"
          />
        </div>
      </div>
    </div>
  );
};

export default DigitalWallet;
