import React from "react";
import { FiCheckCircle } from "react-icons/fi";

const Features = () => {
  const features = [
    "React Native sample app",
    "Push notifications and custom message support",
    "NPM packages",
    "Detailed documentation",
    "Built with Typescript",
    "Skipping Connection step (Aries Out-of-Band protocol)",
    "Easy configuration scripts",
    "Aries basic message",
    "Plug-and-play EULA terms",
    "Wallet export / import",
    "Integration with Knoct’s cloud agency for message routing",
    "Holder presents proof",
  ];
  return (
    <div className="mt-[75px] main_padding py-[10px]">
      <h1 className="header mb-[35px]">
        The features your developers are <br /> asking for.
      </h1>
      <ul className="grid md:grid-cols-2 grid-cols-1 gap-[22px] p-[25px] cardBg border border-gray-400 rounded-lg">
        {features.map((item, index) => (
          <li
            key={index}
            className="inline-flex items-center text-white font-semibold space-x-3"
          >
            <FiCheckCircle className="text-[#46DE93] text-[20px]" />
            <p className="features_text">{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Features;
