export const announcementData=[
    {
        version:"1.1",
        desc:"We are excited to announce the release of version 1 of our Knoct platform. As we prepare for pilots and demos, this version comes with several new features and enhancements that will help us demonstrate the full potential of Knoct.  "
    },
    {
        version:"1.2",
        desc:"We are excited to announce the release of version 1 of our Knoct platform. As we prepare for pilots and demos,this version comes with several new features and enhancements that  "
    },
    {
        version:"1.2.1",
        desc:"We are excited to announce the release of version 1.2.1 of our Knoct platform. As we prepare for  product launch of our platform with public  "
    },
]