import React, { useEffect } from "react";
import hero from "../../../images/Resources/business_hero.png";
import { FaArrowRight } from "react-icons/fa";
import CredentialStore from "../../../components/CredentialStore";
import DetailsForm from "../../../components/DetailsForm";
import OptimizeCard from "../../../components/OptimizeCard";
import VerifyIdentity from "../../../components/VerifyIdentity";
import AllServices from "../../../components/AllServices";
import FreeAccount from "../../../components/FreeAccount";
import ProductFaqs from "../../../components/ProductFaqs";
import "../../../styles/UserPages.css";
import { FiArrowUpCircle } from 'react-icons/fi'
import { Link, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
const Customers = () => {


    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
  
  return (
    <div className="pt-[75px]">
      <div className="mb-[75px] main_padding ">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="md:px-[75px] md:py-[46px] p-5 rounded-[30px] md:rounded-[50px] max-w-[1172] mx-auto"
        >
          <div
          data-aos-duration="1000" data-aos="zoom-in"
          
          className="flex flex-col justify-center gap-[20px] items-start text-start md:w-[562px] ">
            <span
              
              className="title_border"
            >
              Knoct for Consumers
            </span>
            <h1
             
              className="md:text-[40px] lg:text-[56px] text-[30px] leading-[65px] font-semibold"
            >
              One Wallet that lets you Share Credentials Instantly
            </h1>

            <Link to={'/coming'}
             
              className="btn-solid  flex items-center gap-3"
            >
              Get Started <FaArrowRight />
            </Link>
          </div>
        </div>
      </div>
      <CredentialStore />
      <DetailsForm />
      <div className="text-center space-y-[60px] md:py-[60px] mt-[75px] main_padding">
        <h1 className="lg:text-[64px] md:text-[50px] text-4xl leading-[77px] font-semibold max-w-[1010px] mx-auto">
          <span className="text-green-500">
            You’re in control of your Wallet.
          </span>
          <span className="sub_text_bg">
            Verify yourself anywhere only with your consent.
          </span>
        </h1>
        <OptimizeCard />
      </div>
      <VerifyIdentity />
      <AllServices />
      <FreeAccount />
      <ProductFaqs />
      <ScrollToTop smooth top={1000}    component={<div className="text-3xl text-green-500 flex justify-center items-center"><FiArrowUpCircle /></div>} />
    </div>
  );
};

export default Customers;
