import React, { useState } from "react";
import { BsShareFill } from "react-icons/bs";
import { BiShowAlt, BiMoney } from "react-icons/bi";
import { MdSecurity } from "react-icons/md";
import image1 from "../images/ecosystem_1.png";
import image2 from "../images/ecosystem_2.png";
import image3 from "../images/ecosystem_3.png";
import image4 from "../images/ecosystem_4.png";
import image5 from "../images/ecosystem_5.png";
const OurEcosystem = () => {
  const data = [
    { title: "Validators", icon: BsShareFill },
    { title: "SSI Vendors", icon: MdSecurity },
    { title: "Investors", icon: BiMoney },
    { title: "Consultancies", icon: BiShowAlt },
  ];

  const items = [image1, image2, image3, image4, image5];

  const [check, setCheck] = useState("Validators");
  return (
    <div className="main_padding mt-[75px] space-y-[41px]">
      <h1 className="header">Our Ecosystem</h1>

      <div className="flex items-center justify-center gap-[20px] flex-wrap">
        {data.map((item, index) => (
          <button
            key={index}
            onClick={() => setCheck(item.title)}
            className={`border border-green-500 rounded-lg md:text-[24px] text-lg leading-[29px] px-[18px] py-[8px] flex items-center gap-[10px] ${
              item.title === check ? "bg-green-800" : ""
            }`}
          >
            <item.icon /> {item.title}
          </button>
        ))}
      </div>

      <div className="grid md:grid-cols-6 grid-cols-3 gap-[30px]  ecosystem_item">
        {items.slice(0, 3).map((item, index) => (
          <div
            data-aos="flip-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="4000"
            key={index}
            className="border border-gray-700 h-[150px] cardBg  flex justify-center items-center rounded-[20px] md:col-span-2 col-span-3 p-[30px]"
          >
            <img src={item} className="w-[200px]" alt="frame" />
          </div>
        ))}
        <div
          data-aos="flip-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="4000"
          className="border border-gray-700 h-[150px] cardBg  flex justify-center items-center rounded-[20px] col-span-3 p-[30px]"
        >
          <img src={items[3]} className="w-[200px]" alt="frame" />
        </div>
        <div
          data-aos="flip-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="4000"
          className="border border-gray-700 h-[150px] cardBg  flex justify-center items-center rounded-[20px] col-span-3"
        >
          <img src={items[4]} className="w-[200px]" alt="frame" />
        </div>
      </div>
    </div>
  );
};

export default OurEcosystem;
