import React, { useEffect } from "react";
import "./Resource.css";
import HeroSection from "./HeroSection";
import WhitepapersPreview from "./WhitepapersPreview";
import UseCases from "../../components/UseCases";
import Subscribe from "../../components/Subscribe";
import ProductFaqs from "../../components/ProductFaqs";
import ScrollToTop from "react-scroll-to-top";
import { FiArrowUpCircle } from 'react-icons/fi'
import ResourceBlogs from "./ResourceBlogs";
import { useLocation } from "react-router-dom";

const Resources = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <HeroSection />
      <div className="refering-Container">
        <ResourceBlogs/>
        <WhitepapersPreview />
        <UseCases />
        <Subscribe />
        <ProductFaqs />
        <ScrollToTop smooth top={1000}    component={<div className="text-3xl text-green-500 flex justify-center items-center"><FiArrowUpCircle /></div>} />
      </div>
    </>
  );
};

export default Resources;
