import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
const SingleUseCases = ({ item }) => {
  const {pathname} = useLocation()

  return (
    <Link
    
      to={`/resources/use-cases/${item._id}`}
      className="p-5 border border-gray-400 cardBg rounded-lg flex justify-center flex-col text-start items-start gap-[20px] cursor-pointer h-full lg:mx-0 md:mx-0  sm:mx-2 mx-0"
    > 
      <h1 className="xl:text-[30px] lg:text-[28px] text-[25px] font-bold  leading-[40px]">{item?.title}</h1>
      { pathname==='/resources/use-cases'&&<span className="lg:text-[20px] text-sm border border-white px-[18px] py-[8px] rounded-[8px]">{item?.subTitle}</span>}

      <p className="text-[#BEB7C4] lg:text-[16px] text-sm text-start leading-[21px]">{item?.desc}</p>
      <button className="btn-solid w-full items-center flex justify-center mt-auto">
        Learn More <BsArrowRightShort className="text-3xl"/>
      </button>
    </Link>
  );
};

export default SingleUseCases;
