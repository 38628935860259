import logo from "../../images/logo.png";
import { BsFacebook, BsGithub } from "react-icons/bs";
import { FaLinkedin, FaRegHandPeace } from "react-icons/fa";
import { TiSocialTwitter } from "react-icons/ti";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="px-4 pt-[75px] mx-auto sm:max-w-xl md:max-w-full   lg:px-[78px] md:px-[50px] text-white">
      <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
        <div className="md:max-w-md lg:col-span-2">
          <img src={logo} alt="logo" className="w-[142px]" />
          <div className="mt-4 lg:max-w-xs text-start">
            <p className="text-sm ">
              The most secure identity wallet for all your applications.
            </p>
            <Link
              to={"/coming"}
              className="mt-4 text-sm hover:text-green-700 duration-300 text-[#46DE93]"
            >
              Get Started Now
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-6">
          <div className="text-start text-white">
            <p className="font-semibold tracking-wide text-[#98A2B3] ">
              Products
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to={"/flow"} className="">
                  Knoct Flow
                </Link>
              </li>
              <li>
                <Link to={"/wallet"} className="">
                  Knoct Wallet
                </Link>
              </li>
              <li>
                <Link to={"/dash"} className="">
                  Knoct Dash
                </Link>
              </li>
            </ul>
          </div>
          <div className="text-start text-white">
            <p className="font-semibold tracking-wide text-[#98A2B3] ">
              Developers
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to={"/user/developer"} className="">
                  Documentation
                </Link>
              </li>
              <li>
                <Link to={"/user/developer"} className="">
                  SSI SDK
                </Link>
              </li>
              <li>
                <Link to={"/user/developer"} className="">
                  Instacheck SDK
                </Link>
              </li>
              <li>
                <a href="/" className="">
                  Github
                </a>
              </li>
            </ul>
          </div>
          <div className="text-start text-white">
            <p className="font-semibold tracking-wide text-[#98A2B3] ">
              Company
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to={"/company/about"} className="">
                  About us
                </Link>
              </li>
              <li>
                <Link to={"/company/events"} className="">
                  Careers
                </Link>
              </li>
              <li>
                <Link to={"/company/partners"} className="">
                  Press
                </Link>
              </li>
              <li>
                <Link to={"/company/careers"} className="">
                  Events
                </Link>
              </li>
              <li>
                <Link to={"/company/press"} className="">
                  Partners
                </Link>
              </li>
              <li>
                <Link to={"/company/contact"} className="">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="text-start text-white">
            <p className="font-semibold tracking-wide text-[#98A2B3] ">
              Resources
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to={"/resources"} className="">
                  Blog
                </Link>
              </li>
              <li>
                <Link to={"/resources"} className="">
                  Newsletter
                </Link>
              </li>
              <li>
                <Link to={"/resources"} className="">
                  FAQs
                </Link>
              </li>
              <li>
                <Link to={"/resources"} className="">
                  Whitepapers
                </Link>
              </li>
              <li>
                <Link to={"/resources"} className="">
                  Use-cases
                </Link>
              </li>
            </ul>
          </div>
          <div className="text-start text-white">
            <p className="font-semibold tracking-wide text-[#98A2B3] ">
              Social
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <a href="/" className="">
                  Twitter
                </a>
              </li>
              <li>
                <a href="/" className="">
                  Linkedin
                </a>
              </li>
              <li>
                <a href="/" className="">
                  Facebook
                </a>
              </li>
              <li>
                <a href="/" className="">
                  GitHub
                </a>
              </li>
              <li>
                <a href="/" className="">
                  AngelList
                </a>
              </li>
            </ul>
          </div>
          <div className="text-start text-white">
            <p className="font-semibold tracking-wide text-[#98A2B3] ">
              Quick Links
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <a href="/pricing" className="">
                  Pricing
                </a>
              </li>
              <li>
                <a href="/technology" className="">
                  Technology
                </a>
              </li>
              <li>
                <a href="/user/developer" className="">
                  Users
                </a>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={"/RefundandCancellatoin"}
                  className=""
                >
                  Refund & Cancelation
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={"/TermsandConditions"}
                  className=""
                >
                  Terms & Condition
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to="/PrivacyPolicy"
                  className=""
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center pt-[42px] pb-10 gap-[42px]">
        <p className="text-sm text-gray-500">
          © {year} Knoct Inc. All rights reserved.
        </p>

        <div className="flex justify-center items-center gap-3 ">
          <TiSocialTwitter className="text-2xl text-gray-500" />
          <FaLinkedin className="text-2xl text-gray-500" />
          <BsFacebook className="text-2xl text-gray-500" />
          <BsGithub className="text-2xl text-gray-500" />
          <FaRegHandPeace className="text-2xl text-gray-500" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
