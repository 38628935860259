import frame1 from "../images/icons/customer_lock.png";
import frame2 from "../images/icons/customer_verified.png";
import frame3 from "../images/icons/customer_bank.png";
import intragated from "../images/icons/intragated.png";

import product5 from "../images/products/product_5.png";
import product6 from "../images/products/product_6.png";
import product7 from "../images/products/product_7.png";

import product8 from "../images/products/product_8.png";
import product9 from "../images/products/product_9.png";
import product10 from "../images/products/product_10.png";
import product11 from "../images/products/product_11.png";
import product12 from "../images/products/product_12.png";
import product13 from "../images/products/product_13.png";


import product15 from "../images/products/product_15.png";
import product16 from "../images/products/product_16.png";
import product17 from "../images/products/product_17.png";
import product18 from "../images/products/product_18.png";

import ex1 from "../images/icons/ex_1.png";
import ex2 from "../images/icons/ex_2.png";
import ex3 from "../images/icons/ex_3.png";
import ex4 from "../images/icons/ex_4.png";
import ex5 from "../images/icons/ex_5.png";
import ex6 from "../images/icons/ex_6.png";
import ex7 from "../images/icons/ex_7.png";
import ex8 from "../images/icons/ex_8.png";

export const productsFrame = [
  {
    image: frame1,
    value: "100% Secure and Private.",
  },
  {
    image: frame2,
    value: "You’re always in control",
  },
  {
    image: frame3,
    value: "Trusted by the Government",
  },
];
export const productsWeb = [
  {
    image: frame1,
    value: "100% Secure and Private.",
  },
  {
    image: frame2,
    value: "You’re always in control",
  },
  {
    image: intragated,
    value: "Easy Integration",
  },
];

export const productUseCases = [
  { _id:1,
    title: "Healthcare",
    subTitle:"Finance",
    desc: "Knoct could be used to securely store and share medical records, allowing patients to have full control over their health data and share it with healthcare providers as needed.",
  },
  { _id:2,
    title: "Government",
    subTitle:"Finance",
    desc: "Knoct could be used to provide secure and tamper-proof identity credentials for government agencies, improving security and reducing the risk of identity fraud.",
  },
  { _id:3,
    title: "Banking",
    subTitle:"Finance",
    desc: "Knoct's decentralized identity solution could simplify and streamline the KYC process for banks, reducing the time and cost of identity verification for customers.",
  },
  { _id:4,
    title: "Education",
    subTitle:"Finance",
    desc: "Knoct could provide secure and verified credentials for students, such as diplomas and transcripts, which they could use for job applications and further education opportunities.",
  },
  { _id:5,
    title: "Human Resources",
    subTitle:"Finance",
    desc: " Knoct could provide verified credentials for job seekers, simplifying the hiring process for employers and reducing the need for background checks.",
  },
  { _id:6,
    title: "Real Estate",
    subTitle:"Finance",
    desc: "Knoct could be used to provide secure and verified credentials for renters and landlords, streamlining the rental process and reducing the risk of fraud.",
  },
  { _id:7,
    title: "Travel",
    subTitle:"Finance",
    desc: "Knoct could provide secure and verified travel documents, such as passports and visas, reducing the risk of identity theft and improving security at borders.",
  },
  { _id:8,
    title: "E-commerce",
    subTitle:"Finance",
    desc: "Knoct could provide secure and verified identity credentials for online shoppers, reducing the risk of fraud and improving trust between buyers and sellers.",
  },
  { _id:9,
    title: "Social media",
    subTitle:"Finance",
    desc: "Knoct could provide secure and verified identity credentials for social media platforms, reducing the risk of fake profiles and improving user trust.",
  },

  

];

export const credentialExchangeData = [
  {
    img: ex1,
    title: "Private by Design",
    desc: "Supports issuer signature binding, selective disclosure, and zero-knowledge proofs",
  },
  {
    img: ex2,
    title: "Interoperable",
    desc: "Compliant with the Aries Interop Profile and tested with third-party wallets, issuers, and verifiers",
  },
  {
    img: ex3,
    title: "GDPR compliant",
    desc: "Built with the strictest data processing and retention requirements",
  },
  {
    img: ex4,
    title: "Based on open standards",
    desc: "Aligned with emerging standards from the W3C to ensure true data portability",
  },
  {
    img: ex5,
    title: "Built for scale",
    desc: "Rigorously tested for performance and scalability, and trusted by the world's largest organizations",
  },
  {
    img: ex6,
    title: "Truly decentralized",
    desc: "Our approach to peer DIDs keeps all personal data off the blockchain",
  },
  {
    img: ex7,
    title: "Public and transparent",
    desc: "Our source code and documentation are public under the Business Source License",
  },
  {
    img: ex8,
    title: "Supported by a world-class team",
    desc: "Our global team is here to ensure your success every step of the way",
  },
];

export const trustedData = [
  { title: "Access Management",desc:"Share employment credentials to access staff portals" ,date: "12 July 2023", sub:"Company Access Card",  to: "to-[#1A3527]", from: "from-[#08120D]",},
  { title: "Membership",desc:"Prove Membership status every time you check-in to a community resource",date: "7 Nov 2020",  sub:"Member Pass", to: "to-[#172932]", from: "from-[#0D181E]" },
  {  title: "Healthcare" ,desc:"Show a Covid-19 Report before boarding a Flight",date: "12 July 2023", sub:"Covid 19 Test Report",  to: "to-[#281A35]", from: "from-[#0D0812]"},
];
export const missionData = [
  {
    img: product5,
    title: "Empower Humans to regain control of their personal data",
  },
  { img: product6, title: "Eliminate data breaches" },
  {
    img: product7,
    title: "Democratize security for enterprises at an affordable pricepoint",
  },
];
export const technologyData = [
  {
    img: product8,
    title: "Decentralized Key Management",
    desc: " Frictionless access to web3 environments without the need for wallets and mnemonics",
  },
  {
    img: product9,
    title: "Zeroknowledge Proof",
    desc: "Prove your identity and other attributes without actually revealing it. Powered by ZK Proof.",
  },
  {
    img: product10,
    title: "DID Communications",
    desc: " Energy efficient Proof of Stake Network designed from scratch for Digital ID management",
  },
  {
    img: product11,
    title: "Pos Network",
    desc: " Energy efficient Proof of Stake Network designed from scratch for Digital ID management",
  },
  {
    img: product12,
    title: "Decentralized Identity ",
    desc: " The unstoppable stack for the ultimate digital Identity access management and control",
  },
  {
    img: product13,
    title: "Verifiable credentials",
    desc: " Next generation data vault to store, secure and manage your private data",
  },
];

export const ecosystemData = [
  {
    img: product15,
    title: "Consumers",
    desc: "We’ve got features to release, events like ",
    link:"/user/consumers"
  },
  {
    img: product16,
    title: "Business",
    desc: "We’ve got features to release, events like ",
    link:"/user/business"
  },
  {
    img: product17,
    title: "Developer",
    desc: " We’ve got features to release, events like ",
    link:"/user/developer"
  },
  {
    img: product18,
    title: "WEB3 dapps",
    desc: "We’ve got features to release, events like ",
    link:"/user/web3"
  },
];
