import React from "react";
import { idsData } from "../data/AllIdsData";
import SingleIdsCard from "./SingleIdsCard";
const CredentialStore = () => {
  return (
    <div className="text-center mt-[54px] space-y-[25px]">
      <span className="border border-gray-400 px-4 py-1 rounded-lg">
        Credential Store
      </span>
      <h1 className="header leading-[75px!important]">
        All your IDs in One Wallet. <br /> Share Instantly and Securely
      </h1>
      <p className="md:text-[16px] text-sm leading-[19px]">
        Fill Long forms and Share Credentials Instantly and Securely. Works like
        Magic ✨
      </p>
      <div className="space-y-5 overflow-hidden">
        <div className="flex flex-row items-center gap-[20px] px-12  left">
          {idsData.reverse().map((item, index) => (
            <SingleIdsCard key={index} item={item}/>
          ))}
        </div>
        <div className="flex flex-row items-center gap-[20px] px-12  right">
          {idsData.map((item, index) => (
            <SingleIdsCard key={index} item={item}/>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CredentialStore;
