import React from "react";
import frame14 from "../images/products/knoct_sdk.png";
import icon from "../images/icons/wallet_7.png";
import icon2 from "../images/icons/wallet_8.png";
const InstaCheck = () => {
  return (
    <div className="mt-[75px] main_padding">
      <h1 className="header mb-[75px]">Knoct Dash SDK</h1>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px]">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className=" text-start lg:space-y-[15px] space-y-[10px] md:order-1 order-2"
        >
          <h1 className="text_bg leading-[50px]  text-start">
            Easily Integrate workflows with Knoct Dash SDK
          </h1>
          <p className="knoct_pro_hero_child_text leading-[22px!important] lg:my-[35px] my-5">
            the powerful multi-factor authentication (MFA) solution that enables
            users to securely access various protocols and applications using a
            single wallet.
          </p>

          <div className="flex items-start gap-[25px]">
            <img src={icon} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">Easy Customization.</h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                Easily adjust color schemes, iconography, and other user
                interface components
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon2} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">Seamless Integration.</h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                Deploy a market-tested user interface, readily customizable for
                your specific use cases — or integrate seamlessly into your
                existing app experience
              </p>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="colorful_bg flex justify-center items-center p-[25px] md:order-2 order-1"
        >
          <img src={frame14} alt="" className="md:w-[512px]" />
        </div>
      </div>
    </div>
  );
};

export default InstaCheck;
