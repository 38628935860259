import React from "react";
import { Link } from "react-router-dom";
import wallet from "../images/products/knoct_wallet.png";
const ProductKINWallet = () => {
  return (
    <div className="mt-[75px] ">
      <div className="flex justify-start">
        <button className="mb-[30px] title_border">Product</button>
      </div>
      <div  className="  md:gap-[35px] gap-5 grid md:grid-cols-2 grid-cols-1 shadow-inner shadow-purple-200 rounded-lg knoct_bg  md:rounded-[30px] ">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="text-start md:order-1 order-2 lg:pl-[60px] lg:py-[74px] lg:pr-0  p-5"
        >
          <h1 className="knoct_header text-start">Knoct Wallet</h1>
          <p className="knoct_text lg:my-[35px] my-5 text-start">
            Access web3 services with a versatile identity, <br /> owned by you,
            built around your unique self-expression.
          </p>
          <Link to={"/wallet"}>
            <button className="btn-solid ">Learn More</button>
          </Link>
        </div>

        <div data-aos="fade-right" data-aos-duration="1000" className="xl:w-[564px] xl:h-[494px] md:order-2 order-1  sm:p-7 p-4 m-auto">
          <img src={wallet} alt="" className=" object-contain " />
        </div>
      </div>
    </div>
  );
};

export default ProductKINWallet;
