import React, { useEffect } from 'react'

import BuildForScale from '../../../components/BuildForScale'
import CaseStudy from '../../../components/CaseStudy'
import RepidlyDeploy from '../../../components/RepidlyDeploy'
import VerifyMade from '../../../components/VerifyMade'
import flow from "../../../images/icons/flow.png";
import hero from "../../../images/products/knoct_hero.png";
import HearCustomers from '../../../components/HearCustomers'
import ProductFaqs from '../../../components/ProductFaqs'
import Questions from '../../../components/Questions'
import OurClients from '../../../components/OurClients'
import { FiArrowRight, FiArrowUpCircle } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import ScrollToTop from 'react-scroll-to-top'
const KnoctFlow = () => {
     const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className='pt-[75px]' id='flow'>
        <div className="main_padding">
        <div
        style={{
          backgroundImage: `url(${hero})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
         className="md:py-[90px] p-5 md:rounded-[50px] rounded-[30px] max-w-[1255px] mx-auto">
          <div data-aos-duration="1000" data-aos="zoom-in" className="max-w-[655px] mx-auto">
          <div className="inline-block">
            <p className="title_border flex items-center gap-2">Product <FiArrowRight/> Knoct-Flow</p>
            </div>
            <div className="flex gap-[25px] knoct_product_hero mt-[33px] md:py-[40px] md:px-[25px] p-5">
              <div className='w-[90px] '>
                <img src={flow} alt="" className=" object-contain mx-auto" />
              </div>
              <div className=" text-start space-y-[25px]">
                <h2 className=" knoct_pro_hero_child_header">
                  Knoct Flow
                </h2>
                <p className="knoct_pro_hero_child_text">
                The easiest way to issue and verify credentials.
                </p>
                <div className="flex justify-start items-center gap-[25px] flex-wrap">
                  <Link to={'/coming'} className="btn-solid ">
                    Get Access
                  </Link>
                  <Link to={'/coming'} className="btn-outline ">
                    View Documentation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <RepidlyDeploy/>
        <VerifyMade/>
        <BuildForScale/>
        <CaseStudy/>
        <OurClients/>
        <HearCustomers/>
        <ProductFaqs/>
        <Questions/>
        <ScrollToTop smooth top={1000}    component={<div className="text-3xl text-green-500 flex justify-center items-center"><FiArrowUpCircle /></div>} />
    </div>
  )
}

export default KnoctFlow