import React from 'react'
import { BsDownload } from 'react-icons/bs'

const SingleWhitePaper = ({item}) => {
  return (
    <div   className="xl:px-[30px] xl:py-[25px] p-5 border border-gray-400 cardBg rounded-lg flex justify-center flex-col items-start gap-[20px] text-start  h-full">
    <h1 className="text-[36px] lg:text-[24px] md:text-[24px] sm:text-[24px]  leading-[32px] font-bold">
     {item?.title}
    </h1>
    <p className="md:text-[16px]  lg:text-sm leading-[20px] text-[#BEB7C4]">
      {item?.desc}
    </p>
    <button className="btn-solid w-full gap-2  items-center flex justify-center mt-auto">
      <BsDownload /> Download PDF
    </button>
  </div>
  )
}

export default SingleWhitePaper