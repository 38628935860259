import React from "react";
import { trustedData } from "../data/products";
import { FaArrowRight } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import icon from "../images/icons/account.png";
import CustomeSlider from "./CustomeSlider";
import { Link } from "react-router-dom";
const ExchangeTrusted = () => {
  return (
    <div className="mt-[75px] md:py-[10px] md:px-[84px] p-5">
      <span className="title_border ">Use-Cases and Case-Studies</span>
      <h1 className="header lg:my-[35px] my-5">
        {" "}
        Exchange trusted proofs with <br /> anyone, anywhere.
      </h1>
      <div className=" others_display_content">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-[35px] sm:gap-[35px] gap-[40px]">
          {trustedData.map((product, index) => (
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
              key={index}
              className="flex flex-col gap-5 items-start "
            >
              <div>
                <div
                  className={`text-start rounded-2xl bg-gradient-to-t ${product.from} ${product.to} store_certificate relative border border-gray-600`}
                >
                  <div className="absolute bottom-[5%] left-[5%] z-50">
                    <h1 className="text-xl mb-[10px]">{product.sub}</h1>
                    <div className="flex items-center justify-normal gap-5">
                      <p>15 Attributes</p>
                      <div className="flex justify-start items-center gap-2 text-green-500 font-semibold">
                        <FiCheckCircle className="text-[#46DE93] text-[20px]" />{" "}
                        <span>Valid</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`store_certificate  bg-gradient-to-tl border border-gray-600 ${product.from} ${product.to} rounded-2xl`}
                    style={{
                      clipPath: "polygon(80% 0, 100% 0%, 100% 100%, 40% 100%)",
                    }}
                  >
                    <div
                      className={` rounded-full bg-gradient-to-tl ${product.from} ${product.to} absolute right-5 top-3 p-[13px]`}
                    >
                      <img src={icon} alt="" className="w-[33px]" />
                    </div>
                  </div>
                </div>
              </div>
              <h1 className="knoct_sub_normal_header leading-[29px !important]  text-start flex items-center gap-3">
                {product.title} <FaArrowRight className="text-2xl" />{" "}
              </h1>
              <h1 className="knoct_pro_hero_child_text leading-[29px!important] text-start">
                {product.desc}{" "}
              </h1>
              <Link to={'/coming'}>
              <button className="btn-outline px-4 py-1 rounded-lg mt-auto">
                Learn More
              </button></Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mobile_carousal">
        <CustomeSlider>
          {trustedData.map((product, index) => (
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
              key={index}
              className="flex flex-col gap-5 items-start "
            >
              <div>
                <div
                  className={`text-start rounded-2xl bg-gradient-to-t ${product.from} ${product.to} store_certificate relative border border-gray-600`}
                >
                  <div className="absolute bottom-[5%] left-[5%] z-50">
                    <h1 className="text-xl mb-[10px]">{product.sub}</h1>
                    <div className="flex items-center justify-normal gap-5">
                      <p>15 Attributes</p>
                      <div className="flex justify-start items-center gap-2 text-green-500 font-semibold">
                        <FiCheckCircle className="text-[#46DE93] text-[20px]" />{" "}
                        <span>Valid</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`store_certificate  bg-gradient-to-tl border border-gray-600 ${product.from} ${product.to} rounded-2xl`}
                    style={{
                      clipPath: "polygon(80% 0, 100% 0%, 100% 100%, 40% 100%)",
                    }}
                  >
                    <div
                      className={` rounded-full bg-gradient-to-tl ${product.from} ${product.to} absolute right-5 top-3 p-[13px]`}
                    >
                      <img src={icon} alt="" className="w-[33px]" />
                    </div>
                  </div>
                </div>
              </div>
              <h1 className="knoct_sub_normal_header leading-[29px !important]  text-start flex items-center gap-3">
                {product.title} <FaArrowRight className="text-2xl" />{" "}
              </h1>
              <h1 className="knoct_pro_hero_child_text leading-[29px!important] text-start">
                {product.desc}{" "}
              </h1>
              <button className="btn-outline px-4 py-1 rounded-lg mt-auto">
                Learn More
              </button>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default ExchangeTrusted;
