import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Main = () => {
  return (
    <div className='mx-auto  text-white '>
      <Navbar />
     <div className="mt-[100px]">
     <Outlet />
     </div>
      <Footer/>
    </div>
  );
};

export default Main;
