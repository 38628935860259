import React from "react";
import { FaArrowRight } from 'react-icons/fa'
import { Link } from "react-router-dom";
const KnoctCareers = () => {
  return (
    <div className="hightlight  extra_padding  p-5 flex justify-between items-center flex-wrap gap-5 mt-[75px]">
      <div className="text-start max-w-[757px] md:w-[50%]">
        <h1 className="text_bg leading-[60px!important]" >Join Our Team to build the future of identity!</h1>
      
      </div>
      <Link to={'/coming'}   className="btn-solid-header flex items-center gap-3">
        Knoct Careers <FaArrowRight className="text-2xl"/>
      </Link>
    </div>
  );
};

export default KnoctCareers;