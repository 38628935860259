import React from "react";
import "../pages/Resources/Resource.css";
import { Link } from "react-router-dom";
import CustomeSlider from "./CustomeSlider";
import SingleUseCases from "./SingleUseCases";
import { productUseCases } from "../data/products";
import { FaArrowRight } from "react-icons/fa";
const UseCases = () => {
  return (
    <div className="mt-[75px] xl:px-[150px] lg:px-[120px] md:py-[60px] p-5">
      <div className="flex justify-between items-center flex-wrap gap-5 mb-[30px]">
        <div className=" ">
          <h1 className="header mb-[36px] text-start">Use-Cases</h1>
          <p className="md:text-[16px] text-sm text-start">
            Subsribe to our news-letter so you don’t missout on latest news.
          </p>
        </div>
        <Link to="/resources/use-cases">
        
        <button className="btn-solid flex items-center gap-3">
          See All Use-Cases <FaArrowRight className="FAarrow"/>
        </button>
        </Link>
      </div>
      <div>
        <CustomeSlider>
          {productUseCases.map((item, index) => (
            <SingleUseCases key={index} item={item} />
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default UseCases;
