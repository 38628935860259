import React from "react";
import { missionData } from "../data/products";
import CustomeSlider from "./CustomeSlider";
const OurMission = () => {
  return (
    <div className=" main_padding mt-[75px]">
      <h1 className="header mb-[45px]">Our Mission</h1>

      <div className=" others_display_content">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 mx-auto sm:grid-cols-2 grid-cols-1 lg:gap-[30px] gap-[20px] ">
          {missionData.map((product, index) => (
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              key={index}
              className="flex flex-col gap-[16px]  justify-start items-center w-[320px]  xl:w-[370px] mx-auto"
            >
              <img
                src={product.img}
                alt={product.title}
                className="  icon_shadow w-[200px] h-[200px] object-contain"
              />

              <h1 className="text-[clamp(14px,5vw,24px)] leading-[35px] font-medium ">
                {product.title}
              </h1>
            </div>
          ))}
        </div>
      </div>
      <div className="mobile_carousal">
        <CustomeSlider>
          {missionData.map((product, index) => (
            <div
              key={index}
              className="flex flex-col gap-[16px]  justify-center items-center w-[320px] md:h-[354px] xl:w-[370px] mx-auto"
            >
              <img
                src={product.img}
                alt={product.title}
                className=" w-[200px] h-[200px] icon_shadow"
              />
              <h1 className="md:text-[24px] text-lg leading-[35px] font-medium ">
                {product.title}
              </h1>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default OurMission;
