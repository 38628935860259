import React from "react";
import frame14 from "../images/products/flow_1.png";
import icon from "../images/icons/flow_1.png";
import icon2 from "../images/icons/flow_2.png";
const RepidlyDeploy = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px] main_padding mt-[75px]">
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className=" text-start flex justify-center flex-col gap-[35px] md:order-1 order-2"
      >
        <h1 className="text_bg leading-[55px !important] text-start">
          Rapidly deploy digital credential workflows.
        </h1>
        <div className="flex items-start gap-[25px]">
          <img src={icon} alt="" className="w-[45px] h-[45px]" />
          <div className="space-y-2">
            <h1 className="knoct_sub_normal_header">No-Code Platform</h1>
            <p className="knoct_pro_hero_child_text leading-[22px!important]">
              Verity Flow is our no-code web application for verifiable
              credential exchange. No coding or complex integrations required.
            </p>
          </div>
        </div>
        <div className="flex items-start gap-[25px]">
          <img src={icon2} alt="" className="w-[45px] h-[45px]" />
          <div className="space-y-2">
            <h1 className="knoct_sub_normal_header">
              Instantly setup Workflows
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px!important]">
              Within minutes, you can design custom templates, change the
              appearance to match your brand, and start issuing or verifying
              your first credentials remotely or in-person.
            </p>
          </div>
        </div>
      </div>

      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className="colorful_bg flex justify-center items-center md:px-[78px] md:py-[85px] p-5 md:order-2 order-1"
      >
        <img src={frame14} alt="" className="lg:w-[468px]" />
      </div>
    </div>
  );
};

export default RepidlyDeploy;
