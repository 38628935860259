import React from "react";
import ClientReviewsSlider from "./ClientReviewsSlider";
const HearCustomers = () => {

  return (
    <div className="mt-[75px] main_padding">
      <h1 className="header pt-[52px]">Hear From Our Customers</h1>
      <p className="md:text-[16px] text-sm text-[##BEB7C4] mb-[30px] mt-[10px] leading-[24px]">
        Testimonials from our prod Customers.
      </p>
      <div className="mt-[75px]">
      <ClientReviewsSlider/>
      </div>
    </div>
  );
};

export default HearCustomers;
