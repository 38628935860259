import React from "react";
import frame15 from "../images/products/frame_15.png";
import icon from "../images/icons/customer_lock.png";
import icon2 from "../images/icons/icon_5.png";
import icon3 from "../images/icons/flow_2.png";
import icon4 from "../images/icons/account_tree.png";
const Authenticaion = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px] main_padding mt-[75px]">
      <div  data-aos="fade-left"
       data-aos-duration="1000" className="colorful_bg flex justify-center items-start">
        <img src={frame15} alt="" className=" shadow_img w-[420px]" />
      </div>
      <div  data-aos="fade-left"
       data-aos-duration="1000" className=" text-start  lg:space-y-[15px] space-y-[10px]">
        <div className="">
          <span className="title_border">Auth</span>
          <span className="title_border ml-[15px]">On-Boarding</span>
        </div>
        <div className="lg:space-y-[15px] space-y-[10px]">
          <h1 className="text_bg">
            Password less and Multifactor Authentication
          </h1>
          <p className="knoct_pro_hero_child_text leading-[22px !important]">
            the powerful multi-factor authentication (MFA) solution that enables
            users to securely access various protocols and applications using a
            single wallet.
          </p>
        </div>

        <div className="flex items-start gap-[25px]">
          <img src={icon} alt="" className="w-[50px]" />
          <div className="space-y-[10px]">
            <h1 className="knoct_sub_normal_header">
              Convenient and Secure.
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px !important]">
              Utilizing advanced encryption technology and zero-knowledge
              proofs, Authy ensures that user data is fully protected and secure
              while providing a more convenient and streamlined login
              experience.
            </p>
          </div>
        </div>
        <div className="flex items-start gap-[25px]">
          <img src={icon2} alt="" className="w-[60px]" />
          <div className="space-y-[10px]">
            <h1 className="knoct_sub_normal_header">
              Easily manage Identities.
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px !important]">
              Authy simplifies digital identity management, enabling users to
              store and manage their digital identities in a secure and
              efficient manner.
            </p>
          </div>
        </div>
        <div className="flex items-start gap-[25px]">
          <img src={icon3} alt="" className="w-[60px]" />
          <div className="space-y-[10px]">
            <h1 className="knoct_sub_normal_header">
              Fast, Secure, and Customizable onboarding process
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px !important]">
              With API integration and biometric authentication, our platform
              integrates with a variety of tools and technologies, allowing you
              to create a fully customized onboarding process that is fast,
              efficient, and effective.
            </p>
          </div>
        </div>
        <div className="flex items-start gap-[25px]">
          <img src={icon4} alt="" className="w-[60px]" />
          <div className=" space-y-[10px]">
            <h1 className="knoct_sub_normal_header">
              Fully Decentralized. Less Vulnerable
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px !important]">
              More secure and less vulnerable solution to data breaches and
              hacking attempts. You can trust that your data and privacy are
              always protected with our cutting-edge blockchain technology.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authenticaion;
