import React from "react";
import tech3 from "../images/technology/tech_3.png";
const SelfCustodial = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[43px] mt-[75px] main_padding">
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
      >
        <img src={tech3} alt="Self-Custodial" className="lg:w-full" />
      </div>
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className="flex flex-col lg:gap-[35px] gap-5 justify-center items-start text-start lg:w-[582px]"
      >
        <h1 className="lg:text-[64px] leading-[77px] text-4xl font-semibold">
          <span className="text-green-400 ">Self-Custodial </span>{" "}
          <span className="white">Key Management</span>
        </h1>
        <p className="md:text-[20px] text-sm font-medium leading-[24px]">
          Users need not store and manage keys on their own.
        </p>
        <p className="md:text-[20px] text-sm font-medium leading-[24px]">
          Files are encoded into shards. Only a subset of these files are
          required to retrieve the file.
        </p>
      </div>
    </div>
  );
};

export default SelfCustodial;
