import React, { useState } from "react";
import { MdAlternateEmail } from "react-icons/md";

const Subscribe = () => {

const [email, setEmail] = useState(null)

  return (
    <div className="hightlight lg:px-[150px] lg:py-[44px] md:px-12 md:py-6  p-5 flex justify-between items-center flex-wrap gap-5 lg:gap-0 md:gap-0 mt-[75px]">
      <div className="text-start sm:w-[50%]  ">
        <h1 className="text_bg mb-[10px]">
          Subscribe. So you don’t miss an update.
        </h1>
        <p className="text-[20x] text-[#F8F9FB]">
          Subscribe to our news-letter.
        </p>
      </div>
      <form  className="sm:w-[40%] ">
        <div className="bg-black rounded-[11px] flex justify-start items-center pl-2 shadow-sm shadow-slate-100 w-full mb-[20px]">
          <MdAlternateEmail className="text-2xl" />
          <input
            type={"email"}
            required
            onChange={(e)=>setEmail(e.target.value)}
            placeholder="Enter email-id"
            className="bg-black px-[17px] py-[16px] text-white outline-none"
          />
        </div>

        <button
          type="submit" className="w-full my_btn text-black rounded-[16px] px-[34px] py-[17px] md:px-7 md:py-3  font-bold lg:text-[27px] md:text-[20px] text-lg  leading-[34px]"
        >
          Get Started for Free
        </button>
      </form>
    </div>
  );
};

export default Subscribe;
