import React from "react";
import { Link } from "react-router-dom";

const GetStarted = () => {
  return (
    <div className="hightlight flex justify-between items-center flex-wrap gap-4 p-5  extra_padding  mt-[75px]">
      <div className="text-start space-y-[10px] md:w-[557px]">
        <h1 className="text_bg leading-[60px!important]">
          Its Free to Get Started on Knoct.
        </h1>
        <p className="text-[20px] leading-[30px!important]">
          Explore Knoct Dock Certs in our Sandbox environment.
        </p>
      </div>
      <Link to={"/coming"}>
        <button className="btn-solid-header">Get Started for Free</button>
      </Link>
    </div>
  );
};

export default GetStarted;
