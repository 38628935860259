import React from "react";
import "../../pages/Resources/Resource.css";
import { FaSistrix, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { blogData } from "../../data/blogData";
import CustomeSlider from "../../components/CustomeSlider";
import press from "../../images/press.png";
const ResourceBlogs = () => {
  return (
    <>
      <div className="blog-container WB-Container xl:px-[150px] lg:px-[120px] md:py-[60px] p-5">
        <div className="flex justify-between   lg:flex-nowrap md:flex-nowrap flex-wrap">
          <div className="text-start  ">
            <h1 className="header mb-3 leading-[90px!important]">Blogs</h1>
            <p className="blog-para">
              Subsribe to our news-letter so you don’t missout on latest news.
            </p>
            <div className="lg:w-[340px] my-[30px]">
              <div className=" flex items-center shadow-md border border-gray-600 shadow-gray-600 rounded-lg pl-2 w-full py-1">
                <FaSistrix className="text-2xl" />
                <input
                  type="text"
                  className=" bg-transparent text-lg p-1 outline-none w-full"
                  placeholder="      Search"
                />
              </div>
            </div>
          </div>
          <Link to="/resources/blogs">
            <button className="page-button">
              Go to Blogs <FaArrowRight className="FAarrow" />{" "}
            </button>
          </Link>
        </div>
        <div>
          <CustomeSlider>
            {blogData?.slice(0, 3)?.map((item, index) => (
              <Link to={`/resources/viewblog/${item._id}`} key={index}>
                <img src={press} alt="" className="w-full" />
                <div className="lg:p-5 p-3 space-y-3 cardBg text-start  border border-gray-700 rounded-bl-xl rounded-br-xl h-[250px] ">
                  <h1 className="lg:text-[30px] md:text-xl  sm:text-[16px] lg:leading-[30px] font-semibold">
                    {item.title}
                  </h1>
                  <p className="lg:text-[16px]  text-sm leading-[20px] text-start text-accent">
                    {item.content.length > 150
                      ? `${item.content.slice(0, 150)}...`
                      : item.content}
                  </p>
                </div>
              </Link>
            ))}
          </CustomeSlider>
        </div>
      </div>
    </>
  );
};

export default ResourceBlogs;
