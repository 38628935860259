import React from "react";
import { Link } from "react-router-dom";

const Market = () => {
  return (
    <div className="hightlight mt-[75px]  flex justify-between items-center lg:flex-nowrap  flex-wrap gap-4 extra_padding">
      <div className="text-start space-y-[10px] w-[750px] max-w-[798px]">
        <h1 className="text_bg leading-[60px!important] ">
          Get up and running, fast, with the most trusted solution in the market
        </h1>
        <h1 className="md:text-[20px] text-sm leading-[30px] ">
          Ready to go, with neutral branding and easy adaptability to a wide
          range of use. If you’re looking for a more tailored solution complete
          with custom branding, we also offer a <br />
          <span className="text-green-400 border-b border-green-500">
            Mobile SDK
          </span>{" "}
          and{" "}
          <span className="text-green-400 border-b border-green-500">
            React Native White Label
          </span>{" "}
          App
        </h1>
      </div>
      <Link to={"/coming"} className="btn-solid-header">
        Get Started
      </Link>
    </div>
  );
};

export default Market;
