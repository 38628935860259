import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="text-white text-left main_padding">
      <div className="p-5 space-y-5">
        <div>
          <h2 className="text-3xl mb-4 font-semibold">Terms of Service</h2>
        </div>
        <div className="space-y-4">
          <p className="text-[16px] text-white text-left">
            These Terms of Service (the “Terms” or this “Agreement”) apply to
            your access and use of all websites, services, software,
            applications, content, programs and products (collectively, the
            “Services”) provided to you (“you” or “your”) directly or indirectly
            through the website and associated domains of www.knoct.in (the
            “Site”) by Vesak Technologies Pvt. Ltd. (“knoct”, “we”, “our” or
            “us”). In addition to the Terms, your access to and use of the
            Services are governed by the knoct Labs AG Privacy Policy, which can
            be found at https://www.knoct.io/privacy (the “Privacy Policy”), and
            any additional rules and/or terms identified or displayed on the
            Site or in connection with a particular Service or program. The
            Privacy Policy and any such additional rules and terms are
            incorporated by reference herein and constitute a part of this
            Agreement. Please read the Terms, the Privacy Policy and any such
            additional rules and terms carefully.
          </p>
          <p className="text-[16px] text-white text-left">
            THESE TERMS GOVERN YOUR ACCESS AND USE OF THE SERVICES AND
            CONSTITUTE A BINDING AGREEMENT BETWEEN YOU (ON BEHALF OF YOURSELF OR
            THE ENTITY YOU REPRESENT) AND knoct. YOUR ACCESS TO OR USE OF THE
            SERVICES CONSTITUTES YOUR ACCEPTANCE TO THIS AGREEMENT AND YOUR
            REPRESENTATION THAT YOU HAVE THE RIGHT, AUTHORITY AND CAPACITY TO
            ENTER INTO THIS AGREEMENT (ON BEHALF OF YOURSELF OR THE ENTITY YOU
            REPRESENT). IF YOU DO NOT ACCEPT THESE TERMS STATED HERE, DO NOT USE
            THE SERVICES.
          </p>
          <p className="text-[16px] text-white text-left">
            knoct reserves the right to revise these Terms at any time, at its
            sole discretion, by posting an updated version to the Site. You
            should visit this page periodically to review the most current
            Terms, because they are binding on you. By continuing to access or
            use the Services after updated Terms have been posted, you agree to
            be bound by the updated Terms.
          </p>
        </div>

        <div>
          <p className="text-[25px] text-white text-left font-medium">
            1. Our Services
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              knoct is an API and SaaS platform for issuing and managing
              verifiable credentials and decentralized identities. knoct
              provides tools to enable developers to integrate these
              capabilities into the products and applications, including
              performing transactions on the knoct network which is a
              custom-built public blockchain.
            </p>
            <p className="text-[16px] text-white text-left">
              You may use the Services for your personal and business use or for
              internal business purposes in the entity that you represent as
              long as you are in compliance with all provisions of this
              Agreement. For purposes of this Agreement, “Content” means all
              content contained in the Site and Services that knoct has rights
              to use, display, publish or otherwise process (as described below)
              including all text, images, designs, graphics, information, logos,
              downloadable content, software and any other content contained
              therein and all related patents, copyrights, trademarks, service
              marks, intellectual property and/or other proprietary information
              of knoct.
            </p>
            <p className="text-[16px] text-white text-left">
              We reserve the right, in our sole and absolute discretion, to
              modify, replace, refuse access to, suspend or discontinue the Site
              or the Services, partially or entirely, without prior notice. You
              agree that we will not be liable to you or any third party for any
              such modifications, suspensions or discountenance of the Site or
              the Services, or any part thereof. We further reserve the right to
              withhold, remove and or discard any of Your Content (defined
              below) available as part of your Account (defined below), with or
              without notice if deemed by us to be contrary to this Agreement or
              a violation of law. We have no obligation to store, maintain or
              provide you a copy of any content that you or others provide when
              using the Services, except to the extent required by applicable
              law.
            </p>
            <p className="text-[16px] text-white text-left">
              It is your obligation to ensure that you fully comply with all
              applicable laws, regulations and directives with regard to the use
              of the Site and the Services. For the avoidance of doubt, the
              ability to access our Site or the Services does not necessarily
              mean that your use thereto are legal under relevant laws,
              regulations and directives.
            </p>
            <p className="text-[16px] text-white text-left">
              You agree that we will not be liable to you or any third party for
              any such modifications, suspensions or discountenance of the Site
              or the Services, or any part thereof. We further reserve the right
              to withhold, remove and or discard any of Your Content (defined
              below) available as part of your Account (defined below), with or
              without notice if deemed by us to be contrary to this Agreement or
              a violation of law. We have no obligation to store, maintain or
              provide you a copy of any content that you or others provide when
              using the Services, except to the extent required by applicable
              law.
            </p>
            <p className="text-[16px] text-white text-left">
              It is your obligation to ensure that you fully comply with all
              applicable laws, regulations and directives with regard to the use
              of the Site and the Services. For the avoidance of doubt, the
              ability to access our Site or the Services does not necessarily
              mean that your use there to are legal under relevant laws,
              regulations and directives.
            </p>
            <p className="text-[16px] text-white text-left">
              For as long as we continue to offer the Services, we will provide
              and seek to update, improve and expand the Services. As a result,
              we allow you to access the Service as it may exist and be
              available on any given day and have no other obligations, except
              as expressly stated in this Agreement. We may modify, replace,
              refuse access to, suspend or discontinue the Service, partially or
              entirely, or change and modify prices for all or part of the
              Services for you or for all our users in our sole discretion. All
              of these changes shall be effective upon their posting on our site
              or by direct communication to you unless otherwise noted.
            </p>
            <p className="text-[16px] text-white text-left">
              Description of Service for Integrated Applications
            </p>
            <p className="text-[16px] text-white text-left">
              As an application integrating, users may share data from knoct
              and/or other applications that have added data to their knoct
              wallet with your application. knoct makes no guarantees towards
              the accuracy, verification, or assessment of the data that is
              provided through the applications.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            2. Licenses
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              In connection with accessing and using the Services (including
              creating an Account with us), you may provide to us information,
              data, text, software, graphics, messages or other materials
              uploaded, transferred, transmitted, posted or stored, including
              without limitation third party data (“Your Content”). knoct does
              not own nor claim ownership of Your Content and you are legally
              responsible for Your Content and the applications which are able
              to access that data on knoct.
            </p>
            <p className="text-[16px] text-white text-left">
              Any downloads of software from authorized third party websites
              which enable you to access and use the Services, including without
              limitation any files, images incorporated in or generated by the
              software, and data accompanying the software (the “Software”) is
              licensed to you by third-party licensors for your personal use.
              You shall keep intact all copyright and other proprietary notices
              and your use of such Software is strictly subject to the terms of
              this Agreement and any agreement accompanying the Software.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            3. Registration; Account Management; Cancellation and Termination
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              Some functions of the Services require you to register with us and
              create an account (your “Account”), and as part of that process
              you will be requested to provide certain information, including
              without limitation your name and email address, and you may choose
              to provide additional information (all such information,
              collectively, “Registration Data”).
            </p>
            <p className="text-[16px] text-white text-left">
              By using the Services, you agree to:
            </p>
            <ul className="list-disc p-5 space-y-3 capitalize">
              <li className="text-[16px] text-white text-left">
                provide true, accurate, current and complete Registration Data
                as prompted by the registration process;
              </li>
              <li className="text-[16px] text-white text-left">
                maintain and promptly update the Registration Data to keep it
                accurate, current and complete;
              </li>
              <li>
                {" "}
                className="text-[16px] text-white text-left" maintain the
                security and confidentiality of your password and any other
                security or access information used by you to access the Site or
                Services;
              </li>
              <li className="text-[16px] text-white text-left">
                ensure that you log out of the Services at the end of each
                session using the Services;
              </li>
              <li className="text-[16px] text-white text-left">
                refrain from transferring your Account on the Services to any
                other party without our prior consent;
              </li>
              <li className="text-[16px] text-white text-left">
                refrain from impersonating any person or entity or misrepresent
                your identity or affiliation with any person or entity,
                including using another person's username, password or other
                Registration Data; and
              </li>
              <li className="text-[16px] text-white text-left">
                immediately notify us in writing if you become aware of any
                loss, theft or use by any other person or entity of any of your
                Registration Data in connection with the Site or the Services or
                any other breach of security that you become aware of involving
                or relating to the Site.
              </li>
            </ul>
            <p className="text-[16px] text-white text-left">
              We assume that any communications and other activities through use
              of your Registration Data were sent or authorized by you, and you
              are fully responsible for all activities that occur under your
              Registration Data.
            </p>
            <p className="text-[16px] text-white text-left">
              By providing your Registration Data to us, you hereby consent to
              us sending, and you receiving, by means of email or other
              communications containing content of a commercial nature relating
              to your use of the Site, the Services and related services,
              including further information and offers from us that we believe
              you may find useful or interesting, such as newsletters, marketing
              or promotional materials. By registering on the Site, you are
              allowing us to send such communications to you, provided that we
              shall immediately cease to send any such further communications
              should you notify us in writing that you do not wish to receive
              such content anymore.
            </p>
            <p className="text-[16px] text-white text-left">
              You may update or delete information stored in your knoct Account
              at any time. You may also delete your Account through the Setting
              section of your Account. If you cancel the Services or delete your
              Account, your cancellation will take effect immediately. After
              cancellation, you will no longer have access to Your Content, your
              profile or any other information through the Services, all Your
              Content, your profile and any other information contained on our
              Site or through the Services. We accept no liability for such
              deleted information.
            </p>
            <p className="text-[16px] text-white text-left">
              It is your obligation to ensure that you fully comply with all
              applicable laws, regulations and directives with regard to the use
              of the Site and the Services. For the avoidance of doubt, the
              ability to access our Site or the Services does not necessarily
              mean that your use thereto are legal under relevant laws,
              regulations and directives.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            4. User Conduct
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              You may access and use the Site and Services only for its purposes
              as intended by the normal functionality of the Site and Services,
              as long as you are in compliance with all provisions of this
              Agreement. In connection with your use of the Site and the
              Services, you agree to abide by all applicable local, state,
              national and international laws and regulations and not, nor allow
              or facilitate a third party to, violate or infringe any rights
              (including without limitation copyrights, rights of publicity or
              privacy and trademarks) of others, our policies or the operational
              or security mechanisms of the Services, and without limiting the
              foregoing you may not:
            </p>
            <p className="text-[16px] text-white text-left">
              use (i) the Site, (ii) the Services, or (iii) any Content,
              services, features, data, information, text, images, photographs,
              graphics, scripts, sounds, video, music, sound recordings,
              programming, logos, trademarks, services marks, HTML code,
              compilation of content, format, design, user interface and
              software made available through or which appears on the Site or
              Services to promote, conduct, or contribute to fraudulent,
              obscene, pornographic, inappropriate or illegal activities,
              including without limitation deceptive impersonation, in
              connection with contests, pyramid schemes, surveys, chain letters,
              junk e-mail, spamming or any duplicative or unsolicited messages
              (commercial or otherwise); interfere with the access, use or
              enjoyment of this Site or the Services by others (including
              without limitation causing greater demand on the Services than is
              deemed by us reasonable,. You may not display, import or export
              feedback information relating to the Site or use it for purposes
              unrelated to the Services without our prior written consent;
              alter, modify, delete, forge, frame, hyper-link, create derivative
              works or otherwise interfere with or in any manner disrupt,
              circumvent, or compromise any part of the Site, the Services, any
              Content, or features; access or attempt to access any of our
              systems, programs or data that are not made available for public
              use, or attempt to bypass any registration processes on the
              Services; decompile, disassemble, reverse engineer or otherwise
              attempt to discover any source code or underlying ideas or
              algorithms of the Site or the Services except if and to the extent
              permitted by applicable law; copy, distribute, transmit,
              broadcast, publicly display, publicly perform, rent or sell any
              portion of the Services, the Site or the Content; use any robot,
              spider, other automated device or any tool-bar, web-bar, other
              web-client, device, software, routine or manual process, to
              monitor or scrape information from this Site or the Services, or
              bypass any robot exclusion request (either on headers or anywhere
              else on the Site); fail to deliver timely payment for your
              purchases; use any meta tags or any other “hidden text” utilizing
              any trademarks or intellectual property owned or licensed by us;
              create or provide any other means through which the Services may
              be accessed, for example, through server emulators, whether for
              profit or not; ‘deep-link’, redistribute or facilitate the
              redistribution of Content; abuse or use any other means to affect
              or manipulate the Services in general or the prices quoted through
              the Services; engage in cookie stuffing or include pop-ups, false
              or misleading links on your website, email or other
              communications, or attempt to mask the referring url information
              (i.e. the page from where the click is originating); represent
              that you are acting on behalf of knoct or that you have the
              authority to enter into any agreement on behalf of knoct; and make
              any representations or warranties regarding knoct or our Services.
            </p>
            <p className="text-[16px] text-white text-left">
              We are not responsible for the accuracy, usefulness, safety or
              intellectual property rights of or relating to any of Your Content
              or the content provided by third parties.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            5. Privacy Policy
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              In addition to the Terms, your use of the Site and Services as
              well as certain information about you is also subject to our
              Privacy Policy which informs you of our policies and procedures
              regarding the collection, use and disclosure of information we
              receive when you visit our Site and use any part of our Services.
              By using or accessing the Site and the Services, you consent to
              the collection and use of information as described in our Privacy
              Policy, as may be amended by us from time to time.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            6. Proprietary Rights
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              All material and services available on the Site and through the
              Services, and all material and services provided by or through
              knoct, its affiliates, subsidiaries, employees, agents, licensors
              or other commercial partners including, but not limited to, the
              Software, the Content, all informational text, software
              documentation, design of and “look and feel,” layout, photographs,
              graphics, audio, video, messages, interactive and instant
              messaging, design and functions, files, documents, images, or
              other materials, whether publicly posted or privately transmitted
              as well as all derivative works thereof (collectively, the
              “Materials”), are owned by knoct or other parties that have
              licensed their material or provided services to knoct, and are
              protected by copyright, trademark, trade secret and other
              intellectual property laws. All knoct trademarks and service
              marks, logos, slogans and taglines are the property of knoct.
              Except as otherwise specifically provided herein, nothing should
              be construed as granting any license or right to use any
              trademarks, service marks, logos, slogans or taglines displayed on
              or by knoct, without our express written permission, or the
              express written permission of such third-party that may own the
              trademark, service mark, logo, slogan or tagline
            </p>
            <p className="text-[16px] text-white text-left">
              We do not want to receive confidential or proprietary information
              from you through the Services or by email. Unless otherwise agreed
              in writing by an authorized knoct representative, any material,
              information or idea you transmit to us by any means may be
              disseminated or used by us or our affiliates without compensation
              or liability to you for any purpose whatsoever, including, but not
              limited to, developing, manufacturing and marketing products.
              However, this provision does not apply to personal information
              that is subject to our Privacy Policy.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            7. Links to Other Websites; Access to Other Accounts and Services
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              The Site contains links and references to websites of others. We
              may, from time to time, at our sole discretion, add or remove
              links to other websites. These links are provided solely as a
              convenience to you, and we have no control nor responsibility for
              such links or websites, and access to any such websites is at your
              own risk. We encourage you to be aware when you leave the Site,
              and to read the terms and conditions and privacy policy of each
              other website that you visit. If you decide to use such third
              party services, your use of such services is governed by the terms
              and conditions of use and privacy policies for those websites, and
              not by this Agreement or our Privacy Policy. We do not review,
              approve, monitor, endorse, warrant, or make any representations
              with respect to such websites. In no event will we be responsible
              for the information contained in such websites, their practices or
              for your use of or inability to use such websites, or
              transmissions received from such sites. You expressly relieve us
              from any and all liability arising from your use of any
              third-party website.
            </p>
            <p className="text-[16px] text-white text-left">
              As part of the functionality of the Services, you may link your
              Account with online Accounts you may have with third party service
              providers (each such Account, a “Third Party Account”) by either:
              (i) providing your Third Party Account login information through
              the Services; or (ii) allowing us to access your Third Party
              Account, as is permitted under the applicable terms and conditions
              that govern your use of each Third Party Account. You represent
              that you are entitled to disclose your Third Party Account login
              information to us and/or grant us access to your Third Party
              Account (including, but not limited to, for use for the purposes
              described herein), without breach by you of any of the terms and
              conditions that govern your use of the applicable Third Party
              Account and without obligating us to pay any fees or making us
              subject to any usage limitations imposed by such third party
              service providers.
            </p>
            <p className="text-[16px] text-white text-left">
              By granting us access to any Third Party Accounts, you understand
              that (i) we may access, make available and store (if applicable)
              any content that you have provided to and stored in your Third
              Party Account (the “SNS Content”) so that it is available on and
              through the Services via your Account, including without
              limitation any friend or contact lists, and (ii) such third party
              site or app may be able to access information on the Services
              related to you, Your Content and your connections. Unless
              otherwise specified in this Agreement, all SNS Content, if any,
              shall be considered to be Your Content for all purposes of this
              Agreement. Depending on the Third Party Accounts you choose and
              subject to the privacy settings that you have set in such Third
              Party Accounts, personally identifiable information that you post
              to your Third Party Accounts may be available on and through your
              Account on the Services. Please note that if a Third Party Account
              or associated service becomes unavailable or our access to such
              Third Party Account is terminated by the third party service
              provider, then SNS Content may no longer be available on and
              through the Services. You will have the ability to disable the
              connection between your Account on the Services and your Third
              Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP
              WITH THE THIRD PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD
              PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
              THIRD PARTY SERVICE PROVIDERS. We make no effort to review any SNS
              Content for any purpose, including but not limited to, for
              accuracy, legality or non-infringement, and we are not responsible
              for any SNS Content. You acknowledge and agree that we may access
              your e-mail address book associated with a Third Party Account and
              your contacts lists solely for the purposes of identifying and
              informing you of those contacts who have also registered to use
              the Services. At your request made via email to support@knoct.io
              we will deactivate the connection between the Services and your
              Third Party Account and delete any information stored on our
              servers that was obtained through such Third Party Account, except
              the username and profile picture that become associated with your
              Account.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            8. Links to this Site
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              Subject to the terms of this Agreement and as long as this
              Agreement is not terminated, we grant to you, a nonexclusive,
              non-transferable, non-sublicensable right to display on your
              website a link to the Site's homepage, as long as your use is not
              misleading, illegal or defamatory, or your website is not obscene,
              pornographic, inappropriate or illegal. You may display our logo
              only in the form and size provided to you. You may not use our
              logo in any way that suggests that we endorse or sponsor your
              site, or that tarnishes, blurs or dilutes the quality of our
              trademarks or any associated goodwill, including without
              limitation displaying the logo on any page that contains
              infringing, misleading or illegal content, or in any other way
              that we determine in our sole discretion reflects badly on knoct.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            9. Feedback
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              Any material, including without limitation, idea, knowledge,
              technique, marketing plan, information, questions, answers,
              suggestions, emails and comments, provided by you to us shall not
              be considered confidential or of a proprietary nature. In
              providing us with material, you authorize us to use it and any
              developments or derivatives thereto, according to our needs as
              determined in our sole discretion, including for public relations
              and promotion of the Site and the Services in the online and
              offline media, and all without requirement of any additional
              permission from you or the payment of any compensation to you. You
              also agree that (i) by submitting unsolicited ideas to us or any
              of our or representatives, you automatically forfeit your right to
              any intellectual property rights in these ideas; and (ii)
              unsolicited ideas submitted to us or any of our employees or
              representatives automatically become the property of knoct. You
              hereby assign and agree to assign all rights, title and interest
              you have in such feedback and ideas to branded.me together with
              all intellectual property rights therein.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            10. Disclaimers of all Warranties
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              THE CONTENT, THE SITE AND THE SERVICES ARE PROVIDED ON AN “AS IS”
              AND “AS AVAILABLE” BASIS. WE EXPRESSLY DISCLAIM ALL WARRANTIES OF
              ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
              THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE AND NON-INFRINGEMENT.
            </p>
            <p className="text-[16px] text-white text-left">
              WITHOUT LIMITING THE ABOVE, (I) WE MAKE NO WARRANTY THAT THE SITE,
              THE SERVICES OR THE CONTENT WILL MEET YOUR REQUIREMENTS, OR WILL
              BE UNINTERRUPTED, CONTINUOUS, TIMELY, SECURE, ACCURATE, CORRECT,
              COMPLETE OR AVAILABLE; (II) WE DO NOT ENDORSE OR APPROVE ANY
              CONTENT PROVIDED BY ANY PARTY OTHER THAN US AND DISCLAIM ALL
              LIABILITY WHATSOEVER THERETO; AND (III) WE DO NOT WARRANT OR MAKE
              ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THIS SITE,
              THE SERVICES OR THE CONTENT PROVIDED BY US OR ANY OTHER PARTY IN
              TERMS OF ITS CORRECTNESS, COMPLETENESS, RESULTS, AVAILABILITY,
              ACCURACY, RELIABILITY OR OTHERWISE.
            </p>
            <p className="text-[16px] text-white text-left">
              FURTHER, knoct AND ITS LICENSORS MAKE NO REPRESENTATION OR
              WARRANTIES THAT THE SERVICES OR THE MATERIALS OR THE SITE ARE
              APPROPRIATE OR AVAILABLE FOR USE IN ALL GEOGRAPHIC LOCATIONS.
              NEITHER knoct NOR ANY THIRD PARTY PROVIDERS, PARTNERS OR
              AFFILIATES WARRANT THAT THE SITE, ITS SERVERS, THE MATERIALS, THE
              SERVICES OR ANY E-MAIL OR OTHER COMMUNICATION SENT FROM THE SITE
              OR ANY THIRD PARTY PROVIDERS, PARTNERS OR AFFILIATES ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            11. Limitation of Liability
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              TO THE FULLEST EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES
              SHALL WE BE LIABLE FOR ANY INJURY, DEATH, ACT OF GOD, ACCIDENT,
              DELAY, DIRECT OR INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL,
              EXEMPLARY OR CONSEQUENTIAL DAMAGES ARISING OUT OF ANY (I) USE OR
              THE INABILITY (FOR ANY REASON) TO USE ANY PART OF THIS SITE OR THE
              SERVICES (INCLUDING WITHOUT LIMITATION INACCURACIES OR ERRORS OF
              INFORMATION AS A RESULT OF ACCESSING THIS SITE OR THE SERVICES),
              (II) ACTION OR INACTION IN CONNECTION WITH THIS AGREEMENT OR FOR
              COST OF PROCUREMENT OF SUBSTITUTE SERVICES, OR (III) STATEMENTS OR
              CONDUCT OF YOU OR ANY THIRD PARTY ON THIS SITE OR YOUR WEBSITE,
              INCLUDING WITHOUT LIMITATION ANY SUBMISSIONS THEREON; IN EACH
              CASE, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
              EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
              whether based on contract, tort, negligence, strict liability or
              otherwise. All of the above limitations shall apply
              notwithstanding any failure of essential purpose of any limited
              remedy and are fundamental elements of the bargain between us and
              you. You agree that regardless of any statute or law to the
              contrary, any claim or cause of action arising out of or in
              connection with the Site, the Services, the Content or this
              Agreement must be filed within thirty (30) days after such claim
              or cause of action arose or be forever barred.
            </p>
            <p className="text-[16px] text-white text-left">
              IN THE EVENT OF ANY PROBLEM WITH THE SITE, THE SERVICES, THE
              MATERIALS, YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY IS TO
              CEASE USING THE SITE, THE SERVICES AND THE MATERIALS. UNDER NO
              CIRCUMSTANCES SHALL knoct, ITS AFFILIATES, OR LICENSORS BE LIABLE
              IN ANY WAY FOR YOUR USE OF THE SITE, THE SERVICES, THE MATERIALS,
              YOUR CONTENT, THE COMMERCIAL PRODUCTS OR THIRD PARTY USER
              GENERATED CONTENT AVAILABLE ON OR THROUGH THE SITE, INCLUDING, BUT
              NOT LIMITED TO, ANY ERRORS OR OMISSIONS, ANY INFRINGEMENT OF THE
              INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS OF THIRD PARTIES, OR
              FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF, OR
              RELATED TO, THE USE OF THE SITE, THE SERVICES, THE MATERIALS, YOUR
              CONTENT, THE COMMERCIAL PRODUCTS OR ANY THIRD PARTY USER GENERATED
              CONTENT AVAILABLE ON OR THROUGH THE SITE. Certain states and/or
              jurisdictions do not allow the exclusion of implied warranties or
              limitation of liability for incidental or consequential damages,
              so the exclusions set forth above may not apply to you.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            12. Indemnification
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              YOU HEREBY AGREE, AT YOUR OWN EXPENSE, TO INDEMNIFY, DEFEND AND
              HOLD HARMLESS US, OUR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND
              AFFILIATES, FROM ALL CLAIMS, LIABILITIES, LOSS AND DAMAGES (OF
              EVERY KIND, WHETHER KNOWN OR UNKNOWN AND SUSPECTED OR UNSUSPECTED)
              RELATED TO IN ANY WAY TO: (I) YOUR USE OF, ACCESS TO OR RELIANCE
              ON THIS SITE, THE SERVICES OR THE CONTENT, (II) YOUR CONTENT OR
              (III) YOUR VIOLATION OF ANY OF THE TERMS OF THIS AGREEMENT OR ANY
              APPLICABLE LAWS OR REGULATIONS. We will provide you with written
              notice of such a claim. You will not enter into any settlement or
              compromise of any such claim without our prior written consent. We
              may assume the exclusive defense and control of any matter subject
              to indemnification by you. In all events, you shall cooperate
              fully in the defense of any claim.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            13. Copyrighted Materials, Infringement Notices and Takedown
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              YOU SHALL NOT USE THE SERVICES TO TRANSMIT, ROUTE, PROVIDE
              CONNECTIONS TO OR STORE ANY MATERIAL THAT INFRINGES COPYRIGHTED
              WORKS OR OTHERWISE VIOLATES OR PROMOTES THE VIOLATION OF THE
              INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY. We have adopted
              and implemented a policy, stated below, that provides for the
              termination in appropriate circumstances of users of the Services
              who infringe or are believed to be infringing the rights of
              copyright holders:
            </p>
            <p className="text-[16px] text-white text-left">
              If you believe that any material contained on this Site infringes
              your copyright, you should notify knoct immediately.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            14. Governing Law and Geographical Restrictions
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              This Agreement will be governed by laws of the Country of
              Switzerland without regard to its choice of law or conflicts of
              law principles. The parties consent to the exclusive jurisdiction
              and venue in the courts in Zug, Switzerland, except that temporary
              relief to enjoin infringement of intellectual property rights may
              be sought in any court.
            </p>
            <p className="text-[16px] text-white text-left">
              While we strive to make our Services universally accessible, due
              to certain regulatory and compliance considerations, we are unable
              to offer our Services to organizations or individuals based in
              certain countries. Currently, this includes, but is not limited
              to, organizations based in Germany. We reserve the right to update
              this list of countries at any time in accordance with changes in
              regulations and our company policies.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            15. Miscellaneous
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              No waiver of any term of this Agreement shall be deemed a further
              or continuing waiver of such term or any other term, and any
              failure to assert any right or provision under this Agreement
              shall not constitute a waiver of such term. This Agreement, and
              any rights and licenses granted hereunder, may not be transferred
              or assigned by you, but may be assigned by us without restriction.
              You agree that no joint venture, partnership, employment,
              franchise or agency relationship exists between you and us as a
              result of the use of the Site or the Services. This Agreement
              comprises the entire agreement between you and us, states our and
              our suppliers' entire liability and your exclusive remedy with
              respect to the Site and Services, and supersedes all prior
              agreements pertaining to the subject matter thereof. If any
              provision(s) of this Agreement is held to be contrary to law, then
              such provision(s) shall be construed, as nearly as possible, to
              reflect the original provision and the other provisions remain in
              full force and effect. The section titles in this Agreement are
              solely used for convenience and have no legal or contractual
              significance. No provision of this Agreement shall be construed
              against us but rather shall be construed in a neutral and fair
              manner as terms entered into by a fully-informed party on a
              voluntary basis after opportunity to confer with advisors and
              legal counsel about the meaning and effects of the terms of this
              Agreement.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[25px] text-white text-left font-medium">
            16. Contacting Us
          </p>
          <div className=" space-y-3">
            <p className="text-[16px] text-white text-left">
              If you have any concerns or questions about this Policy, please
              contact us at team@knoct.in
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
