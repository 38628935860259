import React from "react";
import { useState } from "react";


const NormalFAQ = ({questions}) => {
  const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div   className="border-b border-gray-400">
        <button
        
          type="button"
          aria-label="Open item"
          title="Open item"
          className="flex items-center justify-between w-full p-4 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p  className="text-[clamp(16px,5vw,24px)] font-normal text-start py-[20px]">{title}</p>
          <svg
            viewBox="0 0 24 24"
            className={`w-4 text-white transform transition-transform duration-200 ${
              isOpen ? "rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {isOpen && (
          <div  className=" py-[20px] border-t border-gray-400">
            <p className="text-white font-normal text-start text-[clamp(13px,5vw,16px)] my-3">{children}</p>
          </div>
        )}
      </div>
    );
  };
  return (
    <div class="max-w-xl sm:mx-auto lg:max-w-full">
      <div >
        {questions.map((item,index) => (
          <Item key={index} title={item?.title}>
            {item?.value}
          </Item>
        ))}
      </div>
    </div>
  );
};

export default NormalFAQ;
