import React, { useState } from "react";
import { announcementData } from "../data/announcmentData";
import { Link } from "react-router-dom";

const Announcment = () => {
  const [check, setCheck] = useState("All");
  const value = ["InstaCheck SDK", "Knoct Wallet SDK", "SSI SDK", "Other"];
  return (
    <div className="mt-[90px] ">
      <div className="main_padding px-5 ">
        <h1 className="header text-start mb-[40px]">
          Updates and Announcments
        </h1>

        <div className="flex gap-5 lg:flex-nowrap md:flex-nowrap flex-wrap ">
          <div className="flex flex-col justify-start items-start w-[400px] gap-[20px]">
            {value.map((item, index) => (
              <button
                key={index}
                onClick={() => setCheck(item)}
                className={`border border-green-500 rounded-lg px-4 py-1 inline text-sm ${
                  item === check ? "bg-green-800" : ""
                }`}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="border-l pl-4"></div>
          <div className="space-y-[25px]">
            {announcementData.map((item, index) => (
              <div className="text-start cardBg shadow-sm shadow-accent p-5 space-y-4 rounded-lg">
                <h2 className="text-[36px] leading-[50px] font-semibold text-start ">
                  Change log: version {item.version}
                </h2>
                <p className="md:text-[16px] text-sm leading-[20px] text-accent">
                  {item.desc} ....{" "}
                  <span className="text-lg text-white font-semibold">More</span>{" "}
                </p>
              </div>
            ))}
            <div className="flex justify-center items-center my-3">
            <Link to={'/coming'} className="text-lg font-semibold text-center underline">
              Load More
            </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hightlight  extra_padding  p-5 flex justify-between items-center flex-wrap gap-2 mt-[75px]">
        <h1 className="text_bg lg:w-[70%] text-start">
          Create a free Developer Account to Get Started.
        </h1>

        <Link to={'/coming'} className="btn-solid-header">Get Started</Link>
      </div>
    </div>
  );
};

export default Announcment;
