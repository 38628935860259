import React from "react";
import frame14 from "../images/products/wallet_1.png";
import icon from "../images/icons/wallet_1.png";
import icon2 from "../images/icons/wallet_2.png";
const UserControl = () => {
  return (
    <div className="grid md:grid-cols-2  grid-cols-1 gap-[35px] main_padding mt-[75px]">
      <div  data-aos="fade-right"
       data-aos-duration="1000" className="flex justify-center items-center md:order-1 order-2">
        <div className=" text-start space-y-[10px] ">
          <h1 className="text_bg leading-[50px]">
            Put your Users in Control of their data.
          </h1>
          <div className="flex items-start gap-[25px]">
            <img src={icon} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">
                Secure Credential Store
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                Our free digital wallet app that offers your customers,
                employees, and users an intuitive interface for storing,
                managing, and sharing their digital credentials.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon2} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">
                Free SDK to turn any app into Knoct wallet
              </h1>
              <p className="md:text-[16px] text-sm">
                This general-purpose solution can be used out-of-the-box and
                enables the sharing of credentials across any number of
                organizations and use cases. If you’re looking for a more
                tailored solution complete with custom branding, we also offer a{" "}
                <span className="text-green-400 border-b border-green-500">
                  Mobile SDK
                </span>{" "}
                and{" "}
                <span className="text-green-400 border-b border-green-500">
                  React Native White Label App{" "}
                </span>{" "}
                that make it easy to turn any app into a privacy-preserving
                digital wallet.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div  data-aos="fade-right"
       data-aos-duration="1000" className=" colorful_bg flex justify-center items-end pt-[80px] px-[25px] md:order-2 order-1">
        <img src={frame14} alt="" className="md:w-[429px]  shadow_img" />
      </div>
    </div>
  );
};

export default UserControl;
