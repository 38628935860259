import React, { useEffect, useState } from "react";
import { FaSistrix } from "react-icons/fa";
import { FiArrowUpCircle } from "react-icons/fi";
import "../../pages/Resources/Resource.css";
import Subscribe from "../../components/Subscribe";
import SingleWhitePaper from "../../components/SingleWhitePaper";
import { whitepapersData } from "../../data/whitepapersData";
import { useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

const ExploreWhitepapersPage = () => {
  const data = [
    "All",
    "InstaCheck",
    "SSI SDK",
    "Wallet SDK",
    "Technology",
    "Other",
  ];

  const [check, setCheck] = useState("All");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className=" pt-[75px] ">
      <div className="text-start main_padding">
        <h1 className="header text-start mb-[50px]">Explore Whitepapers</h1>
        <div className="flex items-center gap-3 flex-wrap ">
          <div className=" flex items-center border border-gray-700 rounded-lg pl-2 py-1 w-[490px]">
            <FaSistrix className="text-2xl" />
            <input
              type="text"
              className=" bg-transparent text-lg p-1 outline-none w-full"
              placeholder="    Search"
            />
          </div>

          <button className=" px-6 py-2  border border-green-500 rounded-lg">
            Search
          </button>
        </div>
        <div className="flex items-center gap-2 flex-wrap my-[33px]">
          {data.map((item, index) => (
            <button
              key={index}
              onClick={() => setCheck(item)}
              className={`border border-green-500 rounded-lg px-[18px] py-[8px] text-sm  ${
                item === check ? "bg-green-800" : ""
              }`}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] main_padding">
        {whitepapersData.map((item, index) => (
          <SingleWhitePaper key={index} item={item} />
        ))}
      </div>

      <div className="mb-[75px]">
        <Subscribe />
      </div>
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default ExploreWhitepapersPage;
