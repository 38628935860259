import React from "react";

const SingleIdsCard = ({ item }) => {
  return (
    <div
      className={`text-start rounded-2xl bg-gradient-to-t ${item.from} ${item.to}  w-[270px] h-[140px] relative`}
    >
      <h1 className="text-lg font-semibold  absolute top-4 left-5 z-50">
        {item.value}
      </h1>
      <div
        className={`w-[270px] h-[140px]  ${item.child} rounded-2xl`}
        style={{
          clipPath: "polygon(80% 0, 100% 0%, 100% 100%, 40% 100%)",
        }}
      >
        <div
          className={`w-[50px] h-[50px] rounded-full bg-gradient-to-tl ${item.from} ${item.to} absolute right-5 bottom-3`}
        ></div>
      </div>
    </div>
  );
};

export default SingleIdsCard;
