import {
  HiOutlineMenuAlt2,
  HiIdentification,
  HiTemplate,
  HiOutlineIdentification,
} from "react-icons/hi";
import {
  MdComputer,
  MdDashboard,
  MdMarkEmailRead,
  MdOutlineBackupTable,
} from "react-icons/md";
import { GrShieldSecurity } from "react-icons/gr";

import include1 from "../images/icons/include_1.png";
import include2 from "../images/icons/include_2.png";
import include3 from "../images/icons/include_3.png";
import include4 from "../images/icons/include_4.png";
import include5 from "../images/icons/include_5.png";
import include6 from "../images/icons/include_6.png";
import include7 from "../images/icons/include_7.png";
import include8 from "../images/icons/include_8.png";
import include9 from "../images/icons/include_9.png";

export const pricingData = [
  {
    title: { value: "14 day free trail", color: "#9797FF" },
    month: { time: "0", required: "No credit Card required" },
    year: "",
    pv: "",
    value: [
      "1 Issuer profile",
      "Credential template designer",
      "Knoct Dock Wallet App",
      "Create credential templates",
      "Test only environment ",
      "API access",
      "Email support",
    ],
  },
  {
    title: { value: "Basic", color: "#F477FF" },
    month: { time: "89", required: "" },
    pv: "Everything in Free, and:",
    year: "billed yearly",
    value: [
      "Up to 100 credentials /mo",
      "Unlimited test credentials",
      "Revoke credentials",
      "Production environment",
      "Encrypted credential backup",
    ],
  },
  {
    title: { value: "Business", color: "#FF7979" },
    month: { time: "449", required: "" },
    pv: "Everything in Basic, and:",
    year: "billed yearly",
    value: [
      "Up to 550 credentials /mo",
      "10 Issuer Profiles",
      "Branded Mobile Wallet",
      "Anonymous Credentials",
      "Slack support",
    ],
  },
  {
    title: { value: "Enterprise", color: "#36D399" },
    month: { time: "", required: "" },
    pv: "Everything in Business, and:",
    year: "",
    value: [
      "1000+ credentials / mo",
      "Unlimited Issuer Profiles",
      "Mobile wallet SDK and support",
      "Priority Support",
      "SLA & Service Agreement",
      "White Glove Onboarding",
    ],
  },
];

export const enterPrizeData = [
  "100 transaction's /mo",
  "Production and Sandbox Environment",
  "Credential Issuance",
  "API access",
  "DID creation",
  "Email support",
  "Knoct Dock Wallet App",
  "Mobile SDK",
  "Revoke credentials",
  "Custom Branding",
  "Create Schemas and Revocation Registries",
  "Custom Templates",
  "Encrypted backup of credentials ",
];

export const includedData = [
  {
    image: include1,
    title: "Issue Verifiable Credentials",
    value:
      "Create W3C-compliant Verifiable Credentials in PDF and JSON format.",
  },
  {
    image: include2,
    title: "Knoct Dock Certs Dashboard and API",
    value:
      "With Knoct Dock Certs, you can quickly issue, verify, and revoke verifiable credentials, manage DIDs, and interact with the Knoct Dock Blockchain.",
  },
  {
    image: include3,
    title: "Create Decentralized Identifiers (DIDs)",
    value:
      "A DID is your organization's identity on the blockchain. Your DID will generate a unique cryptographic signature on every credential you issue, making them tamper-proof and verifiable.",
  },
  {
    image: include4,
    title: "Create Schemas (Templates)",
    value:
      "The Schema defines what fields - like Name, Address, Degree Name, etc - are required for the Verifiable Credential.",
  },
  {
    image: include5,
    title: "Revoke Credentials",
    value:
      "When a Verifiable Credential needs to be updated, expire, or is no longer valid, you can revoke it.",
  },
  {
    image: include6,
    title: "Create Revocation Registries",
    value:
      "There's a Revocation Registry on the blockchain, which lists all the credentials that have been revoked. It contains no personal information and is checked whenever a Verifier wants to verify a credential.",
  },
  {
    image: include7,
    title: "Email and Slack Support",
    value:
      "Receive assistance from knoct Dock's expert support team to integrate the Knoct Dock API and use knoct Dock Certs.",
  },
  {
    image: include8,
    title: "Product and Sandbox Environment",
    value:
      "You can test out knoct Dock Certs' features on Sandbox. Once you're ready, move to Production and create your first DID on the blockchain",
  },
  {
    image: include9,
    title: "Encrypted Backups",
    value:
      "You can encrypt a Credential and store it on our servers. The credential can be accessed and verified via a URL or QR Code and can be deleted from Knoct Dock's cloud whenever you decide.",
  },
];
