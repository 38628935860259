import React from "react";
import eys from "../images/products/eys.png";
const EyesRoadProduct = () => {
  return (
    <div className=" mt-[75px] main_padding">
      <div className="md:w-[603px] mx-auto">
        <span className="text-[20px] leading-[24px] border px-[18px] py-[8px] rounded-[8px] mb-[38px]">
          Road Map
        </span>
        <h1 className="header my-[30px] ">Eyes on the road.</h1>
        <p className="text-[16px] text-sm  text-center  mb-[45px] leading-[21px] ">
          We’re working hard to make privacy and security a de-facto computing
          standard. Look below to see what we’ve accomplished so far and what
          we’re working on next. And if you’re inspired, get involved!
        </p>
      </div>

      <div className="relative ">
        <img src={eys} alt="" className="object-contain" />
        <div className="absolute top-[58%] xl:left-[11%] left-[12%]"><h1 className="md:text-[24px] sm:text-lg text-[10px]">Q1 2023</h1></div>
        <div className="absolute top-[34%] xl:left-[28%] left-[27%]"><h1 className="md:text-[24px] sm:text-lg text-[10px]">Q2 2023</h1></div>
        <div className="absolute top-[28%] xl:left-[46%] left-[45%]"><h1 className="md:text-[24px] sm:text-lg text-[10px]">Q3 2023</h1></div>
        <div className="absolute top-[36%] xl:right-[29%] right-[28%]"><h1 className="md:text-[24px] sm:text-lg text-[10px]">Q4 2023</h1></div>
        <div className="absolute top-[68%] xl:right-[12%] right-[11%]"><h1 className="md:text-[24px] sm:text-lg text-[10px]">Q1 2024</h1></div>
      </div>
    </div>
  );
};

export default EyesRoadProduct;
