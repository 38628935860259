import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const CustomeSlider = ({children}) => {
   const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 900, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      infinite={true}
      autoPlaySpeed={3000}
      autoPlay={true}
      keyBoardControl={true}
      responsive={responsive}
      dotListClass="custom-dot-list-style"
      containerClass="carousel-container"
      itemClass="carousel-item-padding-40-px"
    >
      {children}
    </Carousel>
  );
};

export default CustomeSlider;
