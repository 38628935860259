import React from "react";
import "./Resource.css";
import hero from "../../images/resources_hero.png";

const HeroSection = () => {
  return (
    <div className=" mb-[75px] pt-[75px]">
      <div className=" main_padding">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className=" extra_padding  px-8 py-4 flex flex-col gap-4 justify-center items-start text-start rounded-[30px] md:rounded-[50px] max-w-[1172px] mx-auto lg:h-[402px]"
        >
          <div data-aos-duration="1000" data-aos="zoom-in">
          <h1  className="md:text-[72px] text-[50px] leading-[90px] font-semibold">Resources</h1>
          <p  className="md:text-[16px] text-sm leading-[24px]">Blog, White-Papers, Use-Cases, FAQs and more.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
