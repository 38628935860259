import React, { useEffect } from "react";
import hero from "../../images/technology/hero_tech.png";
import Decentrailized from "../../components/Decentrailized";
import Encryption from "../../components/Encryption";
import SelfCustodial from "../../components/SelfCustodial";
import KnoctForDevelopers from "../../components/KnoctForDevelopers";
import KnoctCareers from "../../components/KnoctCareers";
import EyesRoad from "../../components/EyesRoad";
import ProductFaqs from "../../components/ProductFaqs";
import ScrollToTop from "react-scroll-to-top";
import { FiArrowUpCircle } from 'react-icons/fi'
import { useLocation } from "react-router-dom";
const Technology = () => {

   const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="pt-[78px]">
      <div className=" main_padding mb-[75px]">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className=" lg:px-[73px] lg:py-[43px] md:px-[60px] md:py-[30px] p-5 md:rounded-[50px] rounded-[30px] max-w-[1172px] mx-auto"
        >
          <div data-aos-duration="1000" data-aos="zoom-in" className="flex flex-col justify-center gap-5 items-center text-center ">
            <span  className="title_border">Technology</span>
            <h1  className="text-[clamp(36px,5vw,64px)]  font-semibold sm:leading-[50px] md:leading-[64px] leading-[40px]">
              A Comprehensive Stack to help Developers build privacy  <br /> preserving
              apps
            </h1>
            <p  className="md:text-[16px] text-sm leading-[24px] lg:w-[588px] mx-auto">
              Decentralized storage, Identity access management, and KMS to help
              developers to build secure and privacy preserving apps.
            </p>
          </div>
        </div>
      </div>
      <Decentrailized />
      <Encryption />
      <SelfCustodial />
      <KnoctForDevelopers />
      <EyesRoad />
      <KnoctCareers />
      <ProductFaqs />
      <ScrollToTop smooth top={1000}    component={<div className="text-3xl text-green-500 flex justify-center items-center"><FiArrowUpCircle /></div>} />
    </div>
  );
};

export default Technology;
