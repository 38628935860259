import React from "react";
import tech2 from "../images/technology/tech_2.png";
const Encryption = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[43px] main_padding mt-[75px]">
      
      <div  data-aos="fade-left"
       data-aos-duration="1000" className="flex flex-col lg:gap-[35px] gap-5 justify-center items-start text-start lg:w-[530px] lg:order-1 md:order-1 order-2">
        <h1 className="lg:text-[64px] leading-[77px] text-4xl font-semibold">
          <span className="text-green-400 ">End-to-End </span> <span className="white">Encryption</span>  
        </h1>
        <p className="md:text-[20px] text-sm font-medium leading-[24px]">No-one except the intended recipients can access the message.</p>
        <p className="md:text-[20px] text-sm font-medium leading-[24px]">Files are encoded into shards. Only a subset of these files are required to retrieve the file.</p>
      </div>
      <div  data-aos="fade-left"
       data-aos-duration="1000" className="lg:order-2 order-1 md:order-2">
        <img src={tech2} alt="Encrytion" className="lg:w-full"/>
      </div>
    </div>
  );
};

export default Encryption;
