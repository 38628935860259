import React from 'react'

const ComingPage = () => {
  return (
    <div className='w-full h-[400px] flex justify-center items-center'>

      <h1 className='md:text-4xl font-semibold text-xl'>Coming Soon</h1>
    </div>
  )
}

export default ComingPage