import React from "react";
import frame14 from "../images/products/wallet_3.png";
import icon from "../images/icons/wallet_5.png";
import icon2 from "../images/icons/wallet_6.png";
import icon3 from "../images/icons/wallet_7.png";
const Privacy = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[24px] mt-[75px] main_padding">
      <div data-aos="fade-left"
       data-aos-duration="1000" className="flex justify-center items-center md:order-1 order-2">
        <div className=" text-start lg:space-y-[15px] space-y-[10px]">
          <h1 className="text_bg leading-[50px] text-start">
            Privacy and interoperability by design
          </h1>
          <p className="knoct_pro_hero_child_text leading-[22px!important]">
            the powerful multi-factor authentication (MFA) solution that enables
            users to securely access various protocols and applications using a
            single wallet.
          </p>
          <div className="flex items-start gap-[25px]">
            <img src={icon} alt="" className="w-[45px] h-[45px]" />
            <div className="">
              <h1 className="knoct_sub_normal_header leading-[26px!important]">
                Private by Design.
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                A true edge wallet from a company serious about privacy—personal
                data lives strictly with the user, not on Knoct’s servers
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon2} alt="" className="w-[45px] h-[45px]" />
            <div className="">
              <h1 className="knoct_sub_normal_header leading-[26px!important]">
                Based on Open Standards.
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                Based on open standards, interoperable with all the best
                Hyperledger Aries based products
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon3} alt="" className="w-[45px] h-[45px]" />
            <div className="">
              <h1 className="knoct_sub_normal_header leading-[26px!important]">
                Flexible Workflows
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                Flexible workflows, where organizations can invite users to
                register by scanning QR codes or clicking links in email,
                [16px]S or other apps.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div  data-aos="fade-left"
       data-aos-duration="1000" className=" colorful_bg flex justify-center items-end overflow-hidden md:order-2 order-1">
        <img
          src={frame14}
          alt=""
          className="lg:w-[420px] shadow_img md:mt-[110px] px-[25px] pt-[25px] md:pt-0 lg:pt-0"
        />
      </div>
    </div>
  );
};

export default Privacy;
