import React from "react";
import { GoClock, GoLocation } from "react-icons/go";
import { Link } from "react-router-dom";
const TalentedPeople = () => {
  const data = [1, 2, 3, 4, 5, 6];
  return (
    <div className="main_padding md:py-[50px] ">
      <div className="space-y-[32px]">
        <h1 className="title_border inline">
          Open Positions
        </h1>
        <h2 className="header leading-[50px!important] my-[32px]">We’re looking for talented people</h2>
        <p className="text-[20px] leading-[30px]">
          We’re a 100% remote team spread all across the world. Join us!
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-[30px] my-[64px]">
        {data.map((item, index) => (
          <Link
          data-aos="flip-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000" 

            key={index}
            to={`/company/about/jobs/${item}`}
            className=" shadow-sm shadow-white  p-[30px] rounded-[20px] cardBg   hover:shadow-[#46DE93] border-transparent border-2 hover:border-[#46DE93]  duration-500 cursor-pointer"
          >
            <div className="flex items-center gap-[8px] mb-[8px] flex-wrap">
              <h1 className="text-lg ">Product Designer</h1>
              <span className="bg-white text-black px-[10px] py-[4px] rounded-full text-[14px] leading-[20px]">
                Product Designer
              </span>
            </div>
            <p className="md:text-[16px] text-sm leading-[24px] text-start mb-[30px]">
              We’re looking for a mid-level product designer to join our team.
            </p>
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-[8px]">
                <GoLocation />
                <p className="md:text-[16px] text-sm leading-[24px]">Remote</p>
              </div>
              <div className="flex items-center gap-[8px]">
                <GoClock />
                <p className="md:text-[16px] text-sm leading-[24px]">Full-time</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div>
      <Link to={'/coming'} className="text-[20px] leading-[24px] font-[600px] border-b">View more</Link>
      </div>
    </div>
  );
};

export default TalentedPeople;
