import React from 'react'
import hero from '../../images/products/product_1.png'
const ProductHero = () => {
  return (
    <div data-aos="fade-up"
    data-aos-duration="1000" className='md:px-[57px] px-5 bg-gradient-to-t from-green-800 to-black'>
        <img src={hero} alt="" className='w-full ' />
    </div>
  )
}

export default ProductHero