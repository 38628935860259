
import NormalFAQ from "./NormalFAQ";
import { questionData } from "../data/questionData";
const Faq = () => {

    return (
      <div class="px-4  mx-auto  lg:px-8  pt-[35px] pb-[50px] mt-[75px]">
        <h1 className="header text-center mt-5 mb-12">FAQs</h1>
        <div class=" md:w-[70%] mx-auto">
         <NormalFAQ questions={questionData}/>
        </div>
      </div>
    );
  };

  export default Faq
