export const idsData = [
  { value: "Aadhar Card",  to: "to-green-600", from: "from-green-900",child:"bg-green-800"  },
  { value: "Passport",  to: "to-purple-600", from: "from-purple-900" ,child:"bg-purple-800" },
  { value: "Graduation Certificate",  to: "to-green-600", from: "from-green-900",child:"bg-green-800"  },
  { value: "Credit Report",  to: "to-purple-600", from: "from-purple-900",child:"bg-purple-800"  },
  { value: "Pan Card", to: "to-green-600", from: "from-green-900",child:"bg-green-800" },
  { value: "Boarding Pass",  to: "to-purple-600", from: "from-purple-900" ,child:"bg-purple-800" },
  { value: "Vaccination Report", to: "to-green-600", from: "from-green-900" ,child:"bg-green-800" },
  { value: "Insurance Policy",  to: "to-purple-600", from: "from-purple-900",child:"bg-purple-800"  },
  { value: "Driver's License",  to: "to-green-600", from: "from-green-900",child:"bg-green-800"  },
];

export const storeCertificate = [
  { date: "12 July 2023", title:"Company Access Card",  to: "to-[#1A3527]", from: "from-[#08120D]",  },
  { date: "7 Nov 2020",  title:"Member Pass", to: "to-[#172932]", from: "from-[#0D181E]" },
  { date: "12 July 2023", title:"Covid 19 Test Report",  to: "to-[#281A35]", from: "from-[#0D0812]" },
 
];
