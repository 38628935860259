import React from "react";
import { Link } from "react-router-dom";

const Questions = () => {
  return (
    <div className="hightlight  extra_padding mt-[75px] flex justify-between items-center flex-wrap gap-2">
      <div className="text-start md:w-[557px]">
        <h1 className="text_bg mb-[10px]">Still have Questions?</h1>
        <p className="md:text-[16px] text-sm text-[#BEB7C4]">
          You can reach out to our team about pricing and supported features.
          We're here to help.
        </p>
      </div>
      <Link to={'/company/contact'} className="btn-solid-header">Contact Support</Link>
    </div>
  );
};

export default Questions;
