import React from "react";
import frame12 from "../images/products/frame_12.png";
const KINIdentity = () => {
  return (
    <div data-aos="fade-right"
    data-aos-duration="1000" className="grid md:grid-cols-2 grid-cols-1 gap-[35px]  main_padding mt-[75px]">
      <div
        
        className=" text-start md:order-1 order-2 "
      >
        <span className="title_border ">Knoct Identity</span>
        <h1 className="text_bg leading-[45px] lg:my-[35px] my-5 ">
          Connect and interact with your customers in a whole new way.
        </h1>
        <p className=" knoct_pro_hero_child_text leading-[22px!important]">
          Verity is our enterprise-grade verifiable credential platform. It
          provides organizations the ability to issue and verify digital
          credentials, and to transform user experiences with more trusted data
          and secure communication channels.
          <br />
          <br />
          Built for developers, Verity can be accessed through the Verity SDK or
          the Verity REST API and works with a growing list of networks,
          including Sovrin, other Hyperledger Indy-based ledgers, and cheqd
          (coming soon).
        </p>
      </div>
      <div
        
        className=" colorful_bg flex items-center justify-center  p-[25px] md:order-2 order-1"
      >
        <img src={frame12} alt="" className="lg:w-[444px] shadow_img" />
      </div>
    </div>
  );
};

export default KINIdentity;
