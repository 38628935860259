import React, { useEffect } from "react";

import EnterPize from "../../components/EnterPize";
import Faq from "../../components/Faq";
import GetStarted from "../../components/GetStarted";
import Included from "../../components/Included";
import OurClients from "../../components/OurClients";
import PricingCarts from "../../components/PricingCarts";
import Questions from "../../components/Questions";
import HearCustomers from "../../components/HearCustomers"
import "../../styles/Pricing.css"
import ScrollToTop from "react-scroll-to-top";
import { FiArrowUpCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";
const Pricing = () => {

   const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="text-center  pt-[30px] ">
      <div className="mb-[75px] main_padding">
        <h1 className="heading sm:text-6xl">
          Clear and Flexible Pricing.
        </h1>
        <p className="heading_text">
          Our pricing plans are easy to understand and designed around <br /> your
          needs, and our Sandbox environment lets you try before you buy.
        </p>
      </div>
      <PricingCarts/>
      <EnterPize />
      <Included/>
      <GetStarted/>
      <OurClients/>
      <HearCustomers/>
      <Faq/>
      <Questions/>
      <ScrollToTop smooth top={1000}    component={<div className="text-3xl text-green-500 flex justify-center items-center"><FiArrowUpCircle /></div>} />
    </div>
  );
};

export default Pricing;
