import React from "react";
import { technologyData } from "../../data/products";
import CustomeSlider from "../../components/CustomeSlider";
import { Link } from "react-router-dom";
const Technology = () => {
  return (
    <div className="mt-[75px] main_padding">
      <h1 className="header mb-[60px]">Technology</h1>

      <div className=" others_display_content">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[45px]">
          {technologyData.map((product, index) => (
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              key={index}
              className="flex flex-col gap-[20px] justify-center items-center w-[308px] h-[305px] mx-auto"
            >
              <div className="w-[87px] h-[87px] tech_bg rounded-[10px] p-[26px]">
                <img
                  src={product.img}
                  alt={product.title}
                  className="  w-[36px] h-[36px] "
                />
              </div>
              <h1 className="text-[20px] font-medium text-start leading-[30px]">
                {product.title}
              </h1>
              <p className=" md:text-[16px] text-sm text-[#ddd]">
                {product.desc}
              </p>
              <Link to={'/coming'} className="btn-outline">Read More</Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mobile_carousal">
        <CustomeSlider>
          {technologyData.map((product, index) => (
            <div
              key={index}
              className="flex flex-col gap-[20px] justify-center items-center w-[308px] h-[305px] mx-auto"
            >
              <div className="w-[87px] h-[87px] tech_bg rounded-[10px] p-[26px]">
                <img
                  src={product.img}
                  alt={product.title}
                  className="  w-[36px] h-[36px] "
                />
              </div>
              <h1 className="text-[20px] font-medium text-start leading-[30px]">
                {product.title}
              </h1>
              <p className=" md:text-[16px] text-sm text-[#ddd]">
                {product.desc}
              </p>
              <Link to={'/coming'} className="btn-outline">Read More</Link>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default Technology;
