import React from "react";
const OurClients = () => {
  return (
    <div className="text-center main_padding mt-[75px]">
      <h1 className="header mb-[10px]">Our Clients</h1>
      <p className="md:text-[16px] text-sm mb-[35px] leading-[30px]">
        Join hundreds of organizations and developers issuing credentials using
        Knoct.
      </p>

      <div
        className="flex justify-around items-center flex-wrap  gap-5 
        "
      >
        <div className="flex  items-center gap-2">
          <p className="text-white md:text-[28px] leading-[30px] text-lg font-bold">
            Coming <br /> Soon
          </p>
        </div>
        <div className="flex  items-center gap-2">
          <p className="text-white md:text-[28px] leading-[30px] text-lg font-bold">
            Coming <br /> Soon
          </p>
        </div>
        <div className="flex  items-center gap-2">
          <p className="text-white md:text-[28px] leading-[30px] text-lg font-bold">
            Coming <br /> Soon
          </p>
        </div>
        <div className="flex  items-center gap-2">
          <p className="text-white md:text-[28px] leading-[30px] text-lg font-bold">
            Coming <br /> Soon
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
