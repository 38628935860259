import { createBrowserRouter } from "react-router-dom";
import Main from "../layout/Main/Main";
import About from "../pages/Compnay/About/About";
import Contact from "../pages/Compnay/Contact/Contact";
import Pricing from "../pages/Pricing/Pricing";
import KnoctDash from "../pages/Products/KnoctDash/KnoctDash";
import KnoctFlow from "../pages/Products/KnoctFlow/KnoctFlow";
import KnoctWallet from "../pages/Products/KnoctWallet/KnoctWallet";
import Products from "../pages/Products/Products";
import ExploreWhitepapersPage from "../pages/Resources/ExploreWhitepapersPage";
import Resources from "../pages/Resources/Resources";
import UseCasesPage from "../pages/Resources/UseCasesPage";
import Technology from "../pages/Technology/Technology";
import Developer from "../pages/Users/Developer/Developer";
import ShowBlogs from "../pages/Resources/ShowBlogs";
import ViewBlog from "../pages/Resources/ViewBlog";
import Customers from "../pages/Users/Customers/Customers";
import Business from "../pages/Users/Business/Business";
import Web3Dapps from "../pages/Users/Web3Dapps/Web3Dapps";
import SingleJob from "../pages/Compnay/SingleJobs/SingleJob";
import SingleUseCasePage from "../pages/Resources/SingleUseCasePage/SingleUseCasePage";
import ComingPage from "../components/ComingPage";
import NotFound from "../components/NotFound";
import TermsAndCondition from "../pages/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RefundAndCancelation from "../pages/RefundAndCancelation/RefundAndCancelation";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Products />,
      },
      {
        path: "/products",
        element: <Products />,
      },

      {
        path: "/dash",
        element: <KnoctDash />,
      },
      {
        path: "/wallet",
        element: <KnoctWallet />,
      },
      {
        path: "/flow",
        element: <KnoctFlow />,
      },

      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "technology",
        element: <Technology />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
      {
        path: "/resources/use-cases",
        element: <UseCasesPage />,
      },
      {
        path: "/resources/use-cases/:id",
        element: <SingleUseCasePage />,
      },
      {
        path: "/resources/whitepapers",
        element: <ExploreWhitepapersPage />,
      },
      {
        path: "/resources/blogs",
        element: <ShowBlogs />,
      },
      {
        path: "/resources/viewblog/:id",
        element: <ViewBlog />,
      },
      {
        path: "/user/developer",
        element: <Developer />,
      },
      {
        path: "/user/consumers",
        element: <Customers />,
      },
      {
        path: "/user/business",
        element: <Business />,
      },
      {
        path: "/user/web3",
        element: <Web3Dapps />,
      },
      {
        path: "/company/about",
        element: <About />,
      },
      {
        path: "/company/about/jobs/:id",
        element: <SingleJob />,
      },
      {
        path: "/company/contact",
        element: <Contact />,
      },
      {
        path: "/company/events",
        element: <ComingPage />,
      },
      {
        path: "/company/partners",
        element: <ComingPage />,
      },
      {
        path: "/company/careers",
        element: <ComingPage />,
      },
      {
        path: "/company/press",
        element: <ComingPage />,
      },
      {
        path: "/login",
        element: <ComingPage />,
      },
      {
        path: "/signup",
        element: <ComingPage />,
      },
      {
        path: "/demo",
        element: <ComingPage />,
      },
      {
        path: "/identity",
        element: <ComingPage />,
      },
      {
        path: "/coming",
        element: <ComingPage />,
      },
      {
        path: "/TermsandConditions",
        element: <TermsAndCondition />,
      },
      {
        path: "/RefundandCancellatoin",
        element: <RefundAndCancelation />,
      },
      {
        path: "/PrivacyPolicy",
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default routes;
