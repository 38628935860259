import React from "react";
import frame14 from "../images/products/wallet_2.png";
import icon from "../images/icons/wallet_3.png";
import icon2 from "../images/icons/wallet_4.png";
const IdentityFunction = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px] main_padding mt-[75px]">
      <div  data-aos="fade-left"
       data-aos-duration="1000" className="colorful_bg flex justify-center items-center ">
        <img src={frame14} alt="" className=" py-[50px] px-[25px] lg:ml-[90px] sm:ml-[40px]" />
      </div>
      <div  data-aos="fade-left"
       data-aos-duration="1000" className="flex justify-center items-center">
        <div className=" text-start space-y-[10px] ">
          <h1 className="text_bg text-start leading-[50px]">
            Core decentralized identity functions made easy
          </h1>
          <div className="flex items-start gap-[25px]">
            <img src={icon} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">
                Full Control over your Credentials
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                Receive and store verifiable credentials. Maintain full control
                over what data is shared and with whom.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon2} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-2">
              <h1 className="knoct_sub_normal_header">
                Secure Messaging over Private Channels
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px!important]">
                Engage in structured two-way messaging over secure and private
                channels. Protect customer data with cutting-edge privacy
                features, including zero knowledge proofs as standard
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentityFunction;
