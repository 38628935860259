import React, { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineCall, MdOutlineMail } from "react-icons/md";
import { BsChatLeftText } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import Subscribe from "../../../components/Subscribe";
import { FiArrowUpCircle } from "react-icons/fi";
import hero from "../../../images/products/knoct_hero.png";
import ScrollToTop from "react-scroll-to-top";
import { Link, useLocation } from "react-router-dom";

const Contact = () => {
  const contacts = [
    {
      icon: BsChatLeftText,
      title: "Chat with us",
      desc: "We’re here to help.",
      btn: "Chat with us ",
    },
    {
      icon: GoLocation,
      title: "Visit us",
      desc: "Visit our office HQ",
      btn: " Maps ",
    },
    {
      icon: MdOutlineCall,
      title: "Call us",
      desc: "We’re here to help.",
      btn: " Call us ",
    },
  ];
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="pt-[75px] ">
      <div className="main_padding">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="xl:p-[60px] lg:p-[30px] md:p-[30px] p-5 mx-auto md:rounded-[50px] rounded-[30px]"
        >
          <div className="">
            <div>
              <p className="inline-block title_border mb-[18px]">Contact us</p>
            </div>
            <h2 className=" header leading-[77px!important]  mb-[10px]">
              We’d love to hear from you.
            </h2>
            <p className=" md:text-[20px]  sm:text-[16px]  leading-[30px] mb-[60px]">
              Our friendly team is always here to chat.
            </p>
          </div>
          <div className="grid gap-[25px] lg:grid-cols-4 sm:grid-cols-2 grid-cols-1">
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="flex flex-col justify-center gap-[15px] items-start contact_bg"
            >
              <MdOutlineMail className="text-white text-[30px] text-start" />
              <h1 className="text-xl font-semibold">E-mail us</h1>
              <p className="text-white text-xs text-start">
                Email us directly at{" "}
                <span className="text-[#46DE93]">team@knoct.io</span>
              </p>
              <Link to={'/coming'} className="btn-solid w-full  flex items-center justify-center gap-2 mt-auto">
                E-mail us <FaArrowRight />
              </Link>
            </div>
            {contacts.map((contact, index) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                key={index}
                className="flex flex-col justify-center gap-[15px] items-start contact_bg"
              >
                <contact.icon className="text-white text-2xl text-start" />
                <h1 className="text-xl font-semibold">{contact.title}</h1>
                <p className="text-white text-xs text-start">{contact.desc}</p>
                <Link to={'/coming'} className="btn-solid w-full  flex items-center justify-center gap-2 mt-auto">
                  {contact.btn} <FaArrowRight />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" my-[75px]">
        <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px] items-center">
          <div className="sm:text-center w-full mx-auto">
            <h6 className="text-[20px] leading-[30px] font-semibold mb-[6px]">
              Support
            </h6>
            <p className="  md:text-[16px] text-sm text-white mb-[20px] ">
              Our friendly team is here to help.
            </p>
            <p className="  md:text-[16px] text-sm text-[#18D678] ">
              team@knoct.in
            </p>
          </div>
          <div className="sm:text-center w-full mx-auto">
            <h6 className="text-[20px] leading-[30px] font-semibold mb-[6px]">
              Sales
            </h6>
            <p className=" md:text-[16px] text-sm text-white mb-[20px]">
              Questions or queries? Get in touch!
            </p>
            <p className=" md:text-[16px] text-sm text-[#18D678] sm:mx-auto">
              team@knoct.in
            </p>
          </div>
          <div className="sm:text-center w-full mx-auto">
            <h6 className="text-[20px] leading-[30px] font-semibold mb-[6px]">
              Phone
            </h6>
            <p className=" md:text-[16px] text-sm text-white mb-[20px]">
              Mon-Fri from 8am to 5pm.
            </p>
            <p className="  md:text-[16px] text-sm text-[#18D678]">
              +91 (990) 876-0036
            </p>
          </div>
        </div>
      </div>
      <Subscribe />
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default Contact;
