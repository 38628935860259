import React, { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { BsBox, BsGithub } from "react-icons/bs";
import { CgFileDocument } from "react-icons/cg";
import hero from "../../../images/products/knoct_hero.png";
import Announcment from "../../../components/Announcment";
import ProductFaqs from "../../../components/ProductFaqs";
import ScrollToTop from "react-scroll-to-top";
import { FiArrowUpCircle } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
const Developer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const developers = [
    {
      icon: CgFileDocument,
      title: "Documentation",
      desc: "Welcome to the Knoct documentation! Here, you'll find all the information you need to get started with Knoct's cutting-edge digital identity solutions. Our comprehensive documentation covers everything from the basics of our technology to advanced features and use cases",
      btn: "View Documentation",
    },
    {
      icon: BsGithub,
      title: "GitHub",
      desc: "Here on our GitHub page, you'll find our open-source codebase, along with documentation and resources to help you get started with Knoct. Whether you're a developer looking to integrate Knoct's API or a business interested in implementing our solution, we invite you to explore our code and join our community.",
      btn: "View on GitHub",
    },
    {
      icon: BsBox,
      title: "SDKs",
      desc: "Knoct SDK provides a seamless and secure way for developers to interact with the Knoct Network, empowering them to create robust and reliable decentralized identity solutions",
      btn: " View SDK Docs",
    },
  ];

  return (
    <div className="pt-[75px]">
      <div className=" main_padding">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="py-[45px]  max-w-[1172px] mx-auto rounded-[30px] md:rounded-[50px] p-5 "
        >
          <div className=" ">
            <div>
              <p className="inline-block title_border ">Knoct for Developers</p>
            </div>
            <h2 className="header leading-[90px!important] font-bold ">
              One Platform. Many Possibilities
            </h2>
            <p className="md:text-[16px] text-sm leading-[24px]">
              A wide suite of Tools to get bring the power of Knoct to your
              users.
            </p>
            <Link to={"/coming"}>
              <button className="btn-solid flex items-center justify-center gap-2 mx-auto my-[20px]">
                Get Started <FaArrowRight />
              </button>
            </Link>
          </div>
          <div className="grid gap-[25px] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1  mx-auto px-[45px] ">
            {developers.map((contact, index) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                key={index}
                className="flex flex-col justify-center gap-[15px] items-start p-[20px] rounded-[12px] text-start contact_bg"
              >
                <contact.icon className="text-white text-[30px]" />
                <h1 className="lg:text-[24px] text-lg font-semibold">
                  {contact.title}
                </h1>
                <p className="text-white text-[12px]">{contact.desc}</p>
                <Link to={"/coming"} className="w-full  mt-auto">
                  
                  <button className=" w-full  flex items-center justify-center gap-2 mt-auto btn-solid ">
                    {contact.btn} <FaArrowRight />
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Announcment />

      <ProductFaqs />
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default Developer;
