import React from "react";
import { credentialExchangeData } from "../data/products";
import CustomeSlider from "./CustomeSlider";
const CredentialExchange = () => {
  return (
    <div className="mt-[75px] main_padding">
      <h1 className="header">
        The Gold-Standard in Verifiable <br /> Credential Exchange
      </h1>
      <div className=" others_display_content">
        <div className="pt-[35px] grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-[40px] ">
          {credentialExchangeData.map((item, index) => (
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              key={index}
              className="flex flex-col items-center justify-start gap-[25px]  p-[30px] "
            >
              <div className="w-[80px] h-[80px]">
                <img
                  src={item.img}
                  className=" w-full h-full object-contain  icon_shadow"
                  alt=""
                />
              </div>
              <h2 className="exchange_sub_header">{item.title}</h2>
              <p className="exchange_sub_text">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mobile_carousal">
        <CustomeSlider>
          {credentialExchangeData.map((item, index) => (
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              key={index}
              className="flex flex-col items-center justify-start gap-[25px]  p-[30px] "
            >
              <div className="w-[80px] h-[80px]">
                <img
                  src={item.img}
                  className=" w-full h-full object-contain  icon_shadow"
                  alt=""
                />
              </div>
              <h2 className="exchange_sub_header">{item.title}</h2>
              <p className="exchange_sub_text">{item.desc}</p>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default CredentialExchange;
