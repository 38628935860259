export const inPressData=[
    {
        title:"Streamlined KYC Compliance",
        desc:" Discover how Knoct's permanent and secure certificate storage simplifies and streamlines the KYC process for financial institutions."
    },
    {
        title:"Knoct and Digital Voting",
        desc:"Learn how Knoct's digital identity solution can revolutionize the way we vote, ensuring secure and efficient voting processes."
    },
    {
        title:"Securing Supply Chain Identity",
        desc:"Explore how Knoct's decentralized identity solution can help secure supply chain identity, enhancing transparency and efficiency."
    },
]