import React from "react";
import { includedData } from "../data/pricing";
import CustomeSlider from "./CustomeSlider";
const Included = () => {
  return (
    <div className="md:py-[40px] md:px-[92px] p-5">
      <h1 className="header leading-[77px !important] mb-[60px]">What’s included</h1>
      <div className=" others_display_content">
        <div className="pt-[60px] grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1  gap-[85px]">
          {includedData.map((item, index) => (
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              key={index}
              className="flex flex-col items-start gap-[24px]  text-start  lg:w-[370px] mx-auto"
            >
              <div className="w-[74px] h-[74px] p-[11px] bg-[#46DE93] rounded-[12px]">
                <img
                  src={item.image}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <h2 className="text-[20px] text-white font-medium">
                {item.title}
              </h2>
              <p className="md:text-[16px] text-sm text-white text-start">
                {item.value}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="mobile_carousal">
        <CustomeSlider>
          {includedData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-start gap-[24px]  text-start md:w-[370px] mx-auto "
            >
              <div className="w-[74px] h-[74px] p-[11px] bg-[#46DE93] rounded-[12px]">
                <img
                  src={item.image}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
              <h2 className="text-[20px] text-white font-medium">
                {item.title}
              </h2>
              <p className="md:text-[16px] text-sm text-white text-start">
                {item.value}
              </p>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default Included;
