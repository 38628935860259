import React, { useEffect } from "react";
import hero from "../../../images/about_hero.png";

import InPress from "../../../components/InPress";
import OurPhilosophy from "../../../components/OurPhilosophy";
import TalentedPeople from "../../../components/TalentedPeople";
import Team from "../../../components/Team";
import Subscribe from "../../../components/Subscribe";
import OurEcosystem from "../../../components/OurEcosystem";

import { FiArrowUpCircle } from "react-icons/fi";
import ScrollToTop from "react-scroll-to-top";
import { useLocation } from "react-router-dom";
const About = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="pt-[75px] mb-[75px]">
      <div className="main_padding">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="max-w-[1172px] lg:px-[100px] md:py-[75px] md:rounded-[50px] rounded-[30px]   px-5 py-8  md:px-12 sm:px-8 mx-auto"
        >
          <h1
            data-aos-duration="1000"
            data-aos="zoom-in"
            className="lg:text-[64px] md:text-[56px] text-4xl leading-[90px] font-semibold"
          >
            We're not Dreaming of a Better World, we're Building it
          </h1>
        </div>
      </div>
      <OurPhilosophy />
      <Team />
      <TalentedPeople />
      <Subscribe />
      <OurEcosystem />
      <InPress />
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default About;
