import React from "react";
import { allServices } from "../data/AllService";
import "../styles/UserPages.css";
const AllServices = () => {
  return (
    <div className="mt-[90px] main_padding ">
      <div className="flex justify-center items-center lg:flex-nowrap flex-wrap gap-[35px]  ">
        <div className="flex justify-center items-center basis-[700px] flex-grow  ">
          <div className="  mx-auto space-y-[30px]">
            <div className="flex justify-center gap-[30px] mx-auto md:w-[70%] md:flex-nowrap flex-wrap">
              {allServices.slice(0, 2).map((service, index) => (
                <div
                  // data-aos="fade-right"
                  // data-aos-duration="1000"
                  className="all_service_card border-t border-r border-[#BE74FB] mx-auto w-full"
                  key={index}
                >
                  <div className="all_service_card_img shadow-sm ">
                    <img src={service.img} alt="" className="object-contain w-full"/>
                  </div>
                  <h1 className=" lg:text-[20px] leading-[50px]">{service.title}</h1>
                </div>
              ))}
            </div>
            <div className="flex justify-center gap-[30px] mx-auto md:w-[80%] md:flex-nowrap flex-wrap">
              {allServices.slice(2, 4).map((service, index) => (
                <div
                  // data-aos="fade-right"
                  // data-aos-duration="1000"
                  className="all_service_card border-t border-r border-[#BE74FB] mx-auto w-full"
                  key={index}
                >
                  <div className="all_service_card_img shadow-sm ">
                    <img src={service.img} alt="" className="object-contain w-full"/>
                  </div>
                  <h1 className=" lg:text-[20px] leading-[50px]">{service.title}</h1>
                </div>
              ))}
            </div>
            <div className="flex justify-center gap-[30px] mx-auto w-full md:flex-nowrap flex-wrap">
              {allServices.slice(4, 7).map((service, index) => (
                <div
                  // data-aos="fade-right"
                  // data-aos-duration="1000"
                  className="all_service_card border-t border-r border-[#BE74FB] mx-auto w-full"
                  key={index}
                >
                  <div className="all_service_card_img shadow-sm ">
                    <img src={service.img} alt="" className="object-contain w-full"/>
                  </div>
                  <h1 className=" lg:text-[20px] leading-[50px]">{service.title}</h1>
                </div>
              ))}
            </div>
            <div className="flex justify-center gap-[30px] mx-auto md:w-[90%] md:flex-nowrap flex-wrap">
              {allServices.slice(7, 9).map((service, index) => (
                <div
                  // data-aos="fade-right"
                  // data-aos-duration="1000"
                  className="all_service_card border-t border-r border-[#BE74FB] mx-auto w-full"
                  key={index}
                >
                  <div className="all_service_card_img shadow-sm ">
                    <img src={service.img} alt="" className="object-contain w-full"/>
                  </div>
                  <h1 className=" lg:text-[20px] leading-[50px]">{service.title}</h1>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-start text-start gap-5 basis-[400px]"
        >
          <h1 className="text-[clamp(36px,5vw,64px)]  font-bold text-start">
            <span className="sub_text_bg">All of your Services in</span>
            <span className="text-green-500"> One Place</span>
          </h1>
          <p className="md:text-[16px] text-sm leading-[24px]">
            You are more than your wallet. Create a public profile to showcase
            your NFTs
          </p>
        </div>
      </div>
    </div>
  );
};

export default AllServices;
