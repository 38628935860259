import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
const SinglePriceCart = ({ item }) => {
  const { title, month, value, pv, year } = item;

  let content;
  if (month.time) {
    content = (
      <p className="text-4xl font-medium leading-[20px!important] text-white ">
        ${month?.time} <span className="text-sm">/mo</span>
      </p>
    );
  } else {
    content = (
      <p className="text-4xl font-medium leading-[20px!important] text-white ">
        Talk to us
      </p>
    );
  }

  return (
    <div
    data-aos="flip-left"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="1000" 
      className={`shadow w-full  rounded-lg border border-gray-500 space-y-5  first:bg-opacity-10 first:bg-indigo-400   last:bg-opacity-10 last:bg-green-400 bg-opacity-10 bg-pink-400  mx-auto`}
    >
      <div className=" h-[140px] flex flex-col items-start gap-[30px] p-5">
        <p
          style={{ color: title.color }}
          className={` text-[25px] font-normal leading-[24px!important]`}
        >
          {title?.value}
        </p>
        <div className=" ">
          {content}
          <p className="text-[10px] font-normal leading-[24px!important] text-slate-300">
            {month?.required}
          </p>
          <p className="text-[10px] font-normal leading-[24px!important] text-slate-300 text-start">
            {year}
          </p>
        </div>
      </div>

    { month.time? <Link to={'/coming'}> <button className="btn-solid  w-full rounded-[0px!important] py-[12px]">
        Get Started Now
      </button> </Link>:<Link to={'/coming'}> <button className="btn-solid  w-full rounded-[0px!important] py-[12px]">
        Contact sales
      </button></Link>}

      <div className="">
        <p className="text-[15px] text-start font-semibold px-[25px] pt-[10px]">{pv}</p>
        <ul className="grid grid-cols-1 gap-[10px] p-[25px]">
          {value.map((item, index) => (
            <li
              key={index}
              className="inline-flex items-center text-white font-normal space-x-3"
            >
              <FiCheckCircle className="text-[#46DE93] text-[20px]" />
              <p className="pricing_text text-start">{item}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SinglePriceCart;
