import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { enterPrizeData } from "../data/pricing";
import { Link } from "react-router-dom";
const EnterPize = () => {
  return (
    <div className="hightlight flex justify-center items-center md:py-[75px] p-5 my-[75px]">
      <div className="md:w-[627px] mx-auto w-full border border-gray-700 shadow-xl rounded-2xl bg-black text-white space-y-7  p-5 ">
        <div className="w-full flex justify-between items-center flex-wrap gap-3">
          <h1 className="text-2xl font-bold text-[#D0F6E4]">
            Knoct<span className="enterPrize_text"> EnterPize</span>
          </h1>
         <Link to={'/company/contact'}> <button className="btn-solid">Contact Sales</button></Link>
        </div>
        <p className="md:text-[16px] text-sm text-start text-[#E5E2E7]">
          Built for enterprises who want to scale with confidence, Knoct
          Enterprise offers advanced security, powerful admin controls, and
          more.
        </p>
        <ul  className="grid md:grid-cols-2 grid-cols-1 gap-3">
          {enterPrizeData.map((item, index) => (
            <li
             
              key={index}
              className="inline-flex items-center text-white font-normal space-x-3"
            >
              <FiCheckCircle className="text-[#46DE93] text-lg" />
              <p className="text-sm text-start text-[#E5E2E7]">{item}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EnterPize;
