import React from "react";
import { productsFrame } from "../data/products";
import CustomeSlider from "./CustomeSlider";
const OptimizeCard = () => {
  return (
    <div>
      <div className=" others_display_content">
        <div className="mx-auto w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 py-16 g-5 md:gap-[200px] gap-7 main_padding ">
          {productsFrame.map((product, index) => (
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              key={index}
              className="flex justify-center items-center flex-col gap-[20px] max-w-[261px] mx-auto"
            >
              <div className="customer_optimize_icon">
                <img
                  src={product.image}
                  alt={product.value}
                  className="w-[100px]"
                />
              </div>
              <h3 className="knoct_sub_normal_header leading-[29px !important] text-white">
                {product.value}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <div className="mobile_carousal">
        <CustomeSlider>
          {productsFrame.map((product, index) => (
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              key={index}
              className="flex justify-center items-center flex-col gap-[20px] max-w-[261px] mx-auto"
            >
              <div className="customer_optimize_icon">
                <img
                  src={product.image}
                  alt={product.value}
                  className="w-[100px]"
                />
              </div>
              <h3 className="knoct_sub_normal_header leading-[29px !important] text-white">
                {product.value}
              </h3>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default OptimizeCard;
