import React from "react";
import tech1 from "../images/technology/tech_1.png";
const Decentrailized = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[43px] main_padding">
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
      >
        <img src={tech1} alt="Decentralized" className="lg:w-full" />
      </div>
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className="flex flex-col lg:gap-[35px] gap-5 justify-center items-start text-start lg:w-[504px]"
      >
        <h1 className="lg:text-[64px] leading-[77px] text-4xl  font-semibold">
          <span className="text-green-400 ">Decentralized</span>
          <span className="white"> Storage</span>
        </h1>
        <p className="md:text-[20px] text-sm font-medium leading-[24px]">
          Storage Nodes only store a piece of information after encrypting it.
          ensuring data security at rest.
        </p>
        <p className="md:text-[20px] text-sm font-medium leading-[24px]">
          Files are encoded into shards. Only a subset of these files are
          required to retrieve the file.
        </p>
      </div>
    </div>
  );
};

export default Decentrailized;
