import React from "react";
import "../../pages/Resources/Resource.css";
import { FaSistrix, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import SingleWhitePaper from "../../components/SingleWhitePaper";
import { whitepapersData } from "../../data/whitepapersData";
import CustomeSlider from "../../components/CustomeSlider";

const WhitepapersPreview = () => {
  return (
    <>
      <div className="blog-container WB-Container xl:px-[150px] lg:px-[120px] md:py-[60px] p-5">
        <div className="flex justify-between   lg:flex-nowrap md:flex-nowrap flex-wrap">
          <div className="text-start  ">
            <h1 className="header lg:mb-[30px] mb-5">Whitepapers</h1>
            <p className="blog-para">
              Subsribe to our news-letter so you don’t missout on latest news.
            </p>
            <div className="lg:w-[340px] my-[30px]">
              <div className=" flex items-center shadow-md border border-gray-600 shadow-gray-600 rounded-lg pl-2 w-full py-1">
                <FaSistrix className="text-2xl" />
                <input
                  type="text"
                  className=" bg-transparent text-lg p-1 outline-none w-full"
                  placeholder="      Search"
                />
              </div>
            </div>
          </div>
          <Link to="/resources/whitepapers">
            <button className="page-button">
              See All Whitepapers <FaArrowRight className="FAarrow" />
            </button>
          </Link>
        </div>
        <div className="others_display_content">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-[30px] ">
            {whitepapersData?.slice(0, 3).map((item, index) => (
              <SingleWhitePaper item={item} key={index} />
            ))}
          </div>
        </div>
        <div className="mobile_carousal">
          <CustomeSlider>
            {whitepapersData?.slice(0, 3)?.map((item, index) => (
              <SingleWhitePaper item={item} key={index} />
            ))}
          </CustomeSlider>
        </div>
      </div>
    </>
  );
};

export default WhitepapersPreview;
