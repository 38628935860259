import React from 'react'
import SinglePriceCart from "./SinglePriceCart";
import { pricingData } from "../data/pricing";
const PricingCarts = () => {
  return (
    <div className='main_padding'>
        <div class="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-5  ">
          {pricingData.map((item, index) => (
            <SinglePriceCart key={index} item={item} />
          ))}

          
        </div>
      </div>
  )
}

export default PricingCarts