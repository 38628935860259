import React from "react";
import { Link } from "react-router-dom";
import flow from "../images/products/product_4.png";
const ProductKnoctFlow = () => {
  return (
    <div className="">
      <div className=" knoct_bg grid md:grid-cols-2 grid-cols-1 md:gap-[35px] gap-5  shadow-inner shadow-purple-200 md:rounded-[30px] rounded-lg">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="text-start  md:order-1 order-2 lg:pl-[60px] lg:pr-0 lg:py-[74px] p-5"
        >
          <h1 className="knoct_header text-start">Knoct Flow</h1>
          <p className="knoct_text lg:my-[35px] my-5">
            You’re more than a wallet. Create a public profile for your alt and
            showcase your NFTs across wallets. Profiles are stored using IPFS, a
            distributed file storage protocol.
          </p>
          <Link to={"/flow"}>
            <button className="btn-solid rounded-lg px-3 py-1">
              Learn More
            </button>
          </Link>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="flex justify-center items-end md:order-2 order-1 sm:px-7 sm:pt-7 px-4 pt-4"
        >
          <img src={flow} alt="" className="md:w-[657px]  " />
        </div>
      </div>
    </div>
  );
};

export default ProductKnoctFlow;
