import React from "react";
import { ecosystemData } from "../data/products";
import CustomeSlider from "./CustomeSlider";
import { Link } from "react-router-dom";
const ProductEcosystem = () => {
  return (
    <div className=" mx-auto mt-[75px] main_padding">
      <h1 className="header mb-[60px] ">Built for everyone in the ecosystem</h1>
      <div className=" others_display_content">
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px] ">
          {ecosystemData.map((product, index) => (
            <div
              data-aos="flip-right"
              data-aos-duration="1000"
              key={index}
              className="flex flex-col gap-5 items-start bg-gradient-to-t from-purple-950 to-black bg-opacity-30 shadow-inner shadow-purple-200 p-[25px]  rounded-[18px] text-start"
            >
              <div className="w-[100px] h-[100px] tech_bg rounded-full p-[30px] flex justify-center items-center">
                <img src={product.img} alt={product.title} className=" " />
              </div>
              <h1 className="  text-[clamp(18px,5vw,32px)]">{product.title}</h1>
              <p className="text-[clamp(12px,5vw,16px)]">{product.desc}</p>
              <Link to={`${product.link}`} className="w-full mt-auto">
                <button className="btn-solid w-full px-4 py-2 rounded-lg mt-auto">
                  View More
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mobile_carousal">
        <CustomeSlider>
          {ecosystemData.map((product, index) => (
            <div
              data-aos="flip-right"
              data-aos-duration="1000"
              key={index}
              className="flex flex-col gap-5 items-start bg-gradient-to-t from-purple-950 to-black bg-opacity-30 shadow-inner shadow-purple-200 p-[25px]  rounded-[18px] text-start"
            >
              <div className="w-[100px] h-[100px] tech_bg rounded-full p-[30px] flex justify-center items-center">
                <img src={product.img} alt={product.title} className=" " />
              </div>
              <h1 className=" text-[32px]">{product.title}</h1>
              <p className="md:text-[16px] text-sm">{product.desc}</p>
              <Link to={`${product.link}`} className="w-full mt-auto">
                <button className="btn-solid w-full px-4 py-2 rounded-lg mt-auto">
                  View More
                </button>
              </Link>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default ProductEcosystem;
