import React, { useEffect } from "react";
import hero from "../../../images/Resources/business_hero.png";
import crediential from "../../../images/products/frame_4.png";

import old from "../../../images/products/old.png";
import newKnoct from "../../../images/products/new.png";

import { FaArrowRight } from "react-icons/fa";
import SimpleIntergation from "../../../components/SimpleIntergation";
import OptimizeCard from "../../../components/OptimizeCard";
import UseCases from "../../../components/UseCases";
import FreeBusinessAccount from "../../../components/FreeBusinessAccount";
import OurEcosystem from "../../../components/OurEcosystem";
import HearCustomers from "../../../components/HearCustomers";
import FlexiblePricing from "../../../components/FlexiblePricing";
import ProductFaqs from "../../../components/ProductFaqs";
import Questions from "../../../components/Questions";
import OurClients from "../../../components/OurClients";
import "../../../styles/UserPages.css";
import { FiArrowUpCircle } from 'react-icons/fi'
import { Link, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
const Business = () => {
   const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="pt-[75px]">
      <div className="main_padding">
      <div
        style={{
          backgroundImage: `url(${hero})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="sm:p-[20px]  p-5 max-w-[1172px] mx-auto   md:rounded-[50px] rounded-[30px] md:px-[75px] md:py-[46px]"
      >
        <div
        data-aos-duration="1000" data-aos="zoom-in"
         className="flex flex-col justify-center gap-5 items-start text-start md:w-[562px]">
          <span
           
            className="title_border"
          >
            Knoct for Business
          </span>
          <h1
            
            className="lg:text-[56px] leading-[65px] text-4xl font-semibold"
          >
            Meet the ID Gateway. The simplest way to verify identities.
          </h1>
          <Link to={'/coming'}
           
            className="btn-solid px-4 py-2 flex items-center gap-3 rounded-lg"
          >
            Get Started <FaArrowRight />
          </Link>
        </div>
      </div></div>
      <div className="md:pt-[91px] md:px-[10px] p-5">
        <div className="purpleBg w-full flex flex-col gap-[25px] justify-center items-center">
          <span className="px-[18px] py-[8px] rounded-[8px] text-[14px] border border-white">
            Credential Store
          </span>
          <h1 className="lg:text-[64px] md:text-[56px] leading-[77px] text-4xl  font-bold">
            <span className="sub_text_bg">Increase conversion rate by</span>{" "}
            <span className="text-green-500">34%</span>{" "}
          </h1>
          <p className="md:text-[16px] text-sm leading-[150%]">
            Reduce drop-off using our 1-Click verification. No long forms, no
            document uploads.
          </p>
          <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
            className="mx-auto"
          >
            <img src={crediential} alt="" className="max-w-[290px]"/>
          </div>
        </div>
      </div>
      <SimpleIntergation />
      <div className="text-center my-[75px] main_padding ">
        <h1 className="header">Accelerate Verification by 50%</h1>
        <p className="md:text-[16px] text-sm leading-[30px]">
          Optimized to perfectly fit in your workflow. Verify IDs instantly{" "}
        </p>
        <div className="flex justify-center items-center p-5 gap-[30px] md:flex-nowrap flex-wrap mt-[50px]">
          <div
             data-aos="fade-right"
             data-aos-duration="1000"
            className="old_knoct md:w-[555px] "
          >
            <h1 className="text-[36px] leading-[30px] text-start mb-[72px] font-semibold">
              Old Way
            </h1>
            <div className=" flex justify-center items-center">
              <img src={old} alt="" className="w-[312px]" />
            </div>
          </div>
          <div
             data-aos="fade-right"
             data-aos-duration="1000"
            className="new_knoct md:w-[555px] "
          >
            <h1 className="text-[36px] leading-[30px] text-start mb-[72px] font-semibold  text-black">
              New way with knoct
            </h1>
            <div className="flex justify-center items-center">
              <img src={newKnoct} alt="" className="w-[312px]" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[75px]">
        <OptimizeCard />
      </div>
      <UseCases />
      <FreeBusinessAccount />
      <OurEcosystem />
      <OurClients />
      <HearCustomers />
      <FlexiblePricing />
      <ProductFaqs />
      <Questions />

      <ScrollToTop smooth top={1000}    component={<div className="text-3xl text-green-500 flex justify-center items-center"><FiArrowUpCircle /></div>} />
    </div>
  );
};

export default Business;
