import React from "react";

const RefundAndCancelation = () => {
  return (
    <div className="text-white text-left main_padding">
      <div className="p-5  space-y-5">
        <div>
          <h2 className="text-3xl mb-4 font-semibold">
            Refund and Cancellations Policy for Knoct, DID Company
          </h2>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            At Knoct, customer satisfaction is our utmost priority. We
            understand that circumstances may arise where you may need to
            request a refund or cancellation of our services. To facilitate this
            process, we have established the following Refund and Cancellations
            Policy:
          </p>
        </div>

        <div>
          <p className="text-[16px] text-white text-left">
            1. Refund Eligibility:
          </p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              Customers are eligible for a refund within 10 days of initially
              using our services. Refund requests beyond this period will not be
              entertained.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            2. Cancellation Process:
          </p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              To initiate a cancellation, customers must contact our customer
              support team within 10 days of using the service.
            </p>
            <p className="text-[16px] text-white text-left">
              Cancellation requests made after the 10-day period will not be
              accepted.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            3. Refund Procedure:
          </p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              Customers must submit their refund request through our official
              communication channels, including email or a designated customer
              support portal.
            </p>
            <p className="text-[16px] text-white text-left">
              The request should include relevant details such as the reason for
              the refund, transaction ID, and any supporting documentation.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            4. Eligible Circumstances for Refund:
          </p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              Technical issues that prevent the proper functioning of our
              services. Failure to deliver the promised features or services as
              per the agreed terms.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            5. Non-Eligible Circumstances for Refund:
          </p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              Change of mind or no longer needing the service. Misuse or
              violation of the terms of service by the customer.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            6. Refund Processing Time:
          </p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              Once a refund request is approved, it will be processed within
              [number of days] business days. The refunded amount will be
              credited using the original payment method.
            </p>
          </div>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            7. Contact Information:
          </p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              For refund requests or cancellations, please contact our customer
              support team at{" "}
              <span className="text-blue-500 underline">team@knoct.in</span> .
            </p>
          </div>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">8. Policy Updates:</p>
          <div className="p-3">
            <p className="text-[16px] text-white text-left">
              Knoct reserves the right to update or modify this Refund and
              Cancellations Policy at any time. Customers will be notified of
              any changes through our official communication channels.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundAndCancelation;
