import React from "react";

const OurPhilosophy = () => {
  return (
    <div className="md:py-[25px] extra_padding p-5 text-start mt-[75px]">
      <h1 className="header leading-[90px !important] mb-[25px]">
        Our Philosophy
      </h1>
      <p className="text-[clamp(16px,5vw,22px)] font-normal">
        Identity is not a mere reflection of who we are, it defines us. But it's
        not just about having the right documents, it's about owning and
        controlling our unique expression of self. We envision a world where
        digital identity is powered by personality, where each individual's
        distinctiveness is key to secure their online presence. We firmly
        believe that identity is a human right, and it should be accessible to
        all. By expanding access to social rights like voting and financial
        services, we can empower people to thrive in the web3 economy.
      </p>
      <br />
      <p className="text-[clamp(16px,5vw,22px)] font-normal">
        As pioneers in decentralized identity, we approach this challenge with
        unwavering confidence. Our experience has taught us that we can bring
        this vision to life. And we invite you to join us in building a future
        where identity is not just a label, but a dynamic representation of our
        true selves. Let's work together to make this vision a reality.
      </p>
    </div>
  );
};

export default OurPhilosophy;
