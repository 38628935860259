import React from "react";
import { Link } from "react-router-dom";

const FlexiblePricing = () => {
  return (
    <div className=" my-[75px] pt-[60px]">
      <div className="hightlight  extra_padding  p-5 flex justify-between items-center flex-wrap gap-2 ">
      <div   className="text-start space-y-[10px]">
        <h1 className="text_bg">Clear and Flexible Pricing</h1>
        <p className="md:text-[16px] text-sm">Clear and Flexible Pricing to provide the best value possible.</p>
      </div>
      <Link to={'/pricing'}  className="btn-solid-header">
      Checkout Pricing
      </Link>
    </div>
    </div>
  );
};

export default FlexiblePricing;
