import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import dash from "../../images/icons/dash.png";
import wallet from "../../images/icons/wallet.png";
import flow from "../../images/icons/flow.png";
import customer from "../../images/icons/ex_8.png";
import business from "../../images/icons/business.png";
import web from "../../images/icons/ex_2.png";
import help from "../../images/icons/help.png";
import call from "../../images/icons/call.png";

const Navbar = () => {
  const options = (
    <>
      <li id="mainbar" tabIndex={0}>
        <NavLink to={"/"} className="justify-between ">
          <span className="border-b">Products</span>
          <MdOutlineKeyboardArrowDown />
        </NavLink>
        <ul className="px-4 cardBg text-white z-50 lg:w-[180px] md:w-[180px] py-2">
          <li id="sidebar">
            <NavLink to={"/dash"} className="flex items-center gap-2">
              <img src={dash} alt="dash" className="w-[20px]" />
              <span>Knoct Dash</span>
            </NavLink>
          </li>
          <li id="sidebar">
            <NavLink to={"/wallet"} className="flex items-center gap-2">
              <img src={wallet} alt="dash" className="w-[20px]" />
              <span>Knoct Wallet</span>
            </NavLink>
          </li>
          <li id="sidebar">
            <NavLink to={"/flow"} className="flex items-center gap-2">
              <img src={flow} alt="dash" className="w-[20px]" />
              <span>knoct Flow</span>
            </NavLink>
          </li>
        </ul>
      </li>
      <li id="mainbar">
        <NavLink to={"/pricing"}>
          <span>Pricing</span>
        </NavLink>
      </li>
      <li id="mainbar">
        <NavLink to={"/technology"}>
          <span>Technology</span>
        </NavLink>
      </li>
      <li id="mainbar" tabIndex={0}>
        <NavLink to={"/user/developer"} className="justify-between">
          <span>Users</span>
          <MdOutlineKeyboardArrowDown />
        </NavLink>
        <ul className="px-4 cardBg text-white z-50 lg:w-[180px] md:w-[180px] py-2">
          <li id="sidebar">
            <NavLink to={"/user/developer"} className="flex items-center gap-2">
              <img src={customer} alt="dash" className="w-[20px]" />
              <span> Developers </span>
            </NavLink>
          </li>
          <li id="sidebar">
            <NavLink to={"/user/consumers"} className="flex items-center gap-2">
              <img src={customer} alt="dash" className="w-[20px]" />
              <span> Consumers </span>
            </NavLink>
          </li>
          <li id="sidebar">
            <NavLink to={"/user/business"} className="flex items-center gap-2">
              <img src={business} alt="dash" className="w-[20px]" />
              <span> Business </span>
            </NavLink>
          </li>
          <li id="sidebar">
            <NavLink to={"/user/web3"} className="flex items-center gap-2">
              <img src={web} alt="dash" className="w-[20px]" />
              <span> WEB3 Dapps </span>
            </NavLink>
          </li>
        </ul>
      </li>
      <li id="mainbar">
        <NavLink to={"/resources"} className="justify-between">
          <span>Resources</span>
        </NavLink>
      </li>
      <li id="mainbar" tabIndex={0}>
        <NavLink to={"/company/about"} className="justify-between">
          <span>Company</span>
          <MdOutlineKeyboardArrowDown />
        </NavLink>
        <ul className="px-4 cardBg text-white z-50 lg:w-[180px] md:w-[180px] py-2">
          <li id="sidebar">
            <NavLink to={"/company/about"} className="flex items-center gap-2">
              <img src={help} alt="dash" className="w-[20px]" />
              <span> About Us </span>
            </NavLink>
          </li>
          <li id="sidebar">
            <NavLink
              to={"/company/contact"}
              className="flex items-center gap-2"
            >
              <img src={call} alt="dash" className="w-[20px]" />
              <span> Contact us </span>
            </NavLink>
          </li>
        </ul>
      </li>
    </>
  );
  return (
    <div className="navbar xl:h-[100px] lg:h-[100px]  md:h-[100px] lg:px-[48px] md:px-[20px]  p-1 border-b border-gray-800  w-full bg-black rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 fixed top-0 z-50">
      <div className="navbar-start ">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow  px-4 cardBg text-white z-50 lg:w-[180px] md:w-[180px] py-2"
          >
            {options}
          </ul>
        </div>
        <Link to={'/'}>
          <img src={logo} className="w-[142px]" alt="logo" />
        </Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 ">{options}</ul>
      </div>
      <div className="navbar-end flex justify-center items-center">
        <a href="https://www.wallet.knoct.in"  target="_blank" rel="noreferrer" className=" lg:px-[24px] lg:py-[12px] px-4 py-[10px] rounded-xl  bg-gradient-to-t from-[#74E6AE] to-[#18D678] hover:to-[#74E6AE] hover:from-[#18D678] text-black font-semibold  md:text-[20px] text-sm">
          Log in to Wallet
        </a>
      </div>
    </div>
  );
};

export default Navbar;
