import React from "react";
import { Link } from "react-router-dom";
import dash from "../images/products/product_3.png";
const ProductKnoctDash = () => {
  return (
    <div className="my-[30px]">
      <div className=" grid md:grid-cols-2 grid-cols-1 md:gap-[35px] gap-5 shadow-inner shadow-purple-200 rounded-lg  knoct_bg md:rounded-[30px]">
        <div
          
          data-aos="fade-left"
          data-aos-duration="1000"
          className="flex justify-center items-center sm:p-7 p-4"
        >
          <img
            src={dash}
            alt=""
            className="lg:w-[587px]   knoct_dash_shadow"
          />
        </div>
        <div
         
         data-aos="fade-left"
         data-aos-duration="1000"
          className="text-start lg:pr-[60px] lg:pl-0 lg:py-[74px] p-5"
        >
          <h1 className="knoct_header">Knoct Dash</h1>
          <p className="knoct_text lg:my-[35px] my-5">
            Ensure that users meet the requirements that you determine and
            control. Add in identity, age, uniqueness, or liveness verification
            to keep your online spaces secure:
          </p>
          <Link to={"/dash"}>
            <button className="btn-solid rounded-lg px-3 py-1">
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductKnoctDash;
