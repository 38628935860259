import React from "react";
import CustomeSlider from "./CustomeSlider";
import press from "../images/press.png";
import { inPressData } from "../data/inPressData";
const InPress = () => {

  return (
    <div className="main_padding my-[75px]">
      <h1 className="header mb-[25px] text-start">In Press</h1>
      <div>
        <CustomeSlider>
          {inPressData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center w-full h-full shadow-sm shadow-gray-600 rounded-xl"
            >
              <img src={press} alt="" className="w-full" />
              <div className="px-[25px] py-[30px] space-y-[25px] cardBg text-start">
                <h1 className="xl:text-[36px] lg:text-[20px] md:text-[36px] text-[36px] leading-[35px] font-medium ">{item.title}</h1>
                <p className="md:text-[16px] text-[12px] leading-[20px] text-start text-accent">
                  {item.desc}
                </p>
              </div>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default InPress;
