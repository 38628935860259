import React from "react";
import frame14 from "../images/products/knoct_sdk.png";
import icon from "../images/icons/flow_5.png";
import icon2 from "../images/icons/flow_6.png";
const BuildForScale = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[35px] mt-[75px] main_padding">
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className=" text-start flex justify-center flex-col gap-[15px] md:order-1 order-2"
      >
        <h1 className="text_bg leading-[50px !important] text-start">
          Built for scale and flexibility
        </h1>
        <p className="knoct_pro_hero_child_text leading-[22px!important]">
          the powerful multi-factor authentication (MFA) solution that enables
          users to securely access various protocols and applications using a
          single wallet.
        </p>
        <div className="flex items-start gap-[25px]">
          <img src={icon} alt="" className="w-[45px] h-[45px]" />
          <div className="space-y-2">
            <h1 className="knoct_sub_normal_header leading-[22px !important]">
              Manage role-based access.
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px!important]">
              Manage multiple users and locations with role-based access
              controls Enable multi-factor authentication for additional
              security
            </p>
          </div>
        </div>
        <div className="flex items-start gap-[25px]">
          <img src={icon2} alt="" className="w-[45px] h-[45px]" />
          <div className="space-y-2">
            <h1 className="knoct_sub_normal_header leading-[22px !important]">
              Built to Scale. Issue records in bulk.
            </h1>
            <p className="knoct_pro_hero_child_text leading-[22px!important]">
              Support high customer volumes with bulk issuance in batches of up
              to 10,000 records. Access detailed reporting on credential
              workflows
            </p>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        className="colorful_bg flex justify-center items-center md:px-[49px] md:py-[59px] p-5 md:order-2 order-1"
      >
        <img src={frame14} alt="" className="w-[468px]" />
      </div>
    </div>
  );
};

export default BuildForScale;
