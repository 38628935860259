export const whitepapersData = [
  {
    title: "The Inevitable Rise of Self-Sovereign Identity",
    desc: "An introduction to the key concepts of self-sovereign identity, and why Sovrin represents the solution to so many of today’s identity challenges.",
  },
  {
    title: "IoT Whitepaper",
    desc: "It’s no secret that computers have gotten faster, smaller and more connected. What isn’t often realized is they are also more pervasive. Commonly known as the Internet of Things (IoT), our automobiles, thermostats, medical devices, livestock tags and door locks now compute and connect across global networks.",
  },
  {
    title: "The Technical Foundations of Knoct",
    desc: "A description of Knoct's technical underpinnings and the various components which make up the Knoct Identity Network..",
  },
  {
    title:
      "Decentralized Identity for Banking: Enhancing Security and Customer Experience",
    desc: "This whitepaper explores the use cases of Knoct's decentralized identity solution in the banking industry, including KYC compliance, secure digital identity, and improved customer experience.",
  },
  {
    title:
      "Privacy by Design: The Importance of Building Trust in Self-Sovereign Identity",
    desc: "This whitepaper discusses the importance of privacy by design in self-sovereign identity and how Knoct's solution can help build trust with users by ensuring their data remains private and secure.",
  },
  {
    title:
      "Simplifying Identity Verification with Decentralized Identity Solutions",
    desc: "This whitepaper examines how Knoct's decentralized identity solution can simplify and streamline identity verification processes across multiple industries, from banking to healthcare.",
  },
  {
    title:
      "Revolutionizing Healthcare Identity Management with Decentralized Identity",
    desc: "This whitepaper explores the use cases of Knoct's decentralized identity solution in the healthcare industry, including patient identity verification, secure medical record sharing, and improving patient outcomes.",
  },
  {
    title:
      "Decentralized Identity for Education: Verifying Student Credentials and Achievements",
    desc: "This whitepaper explores how Knoct's decentralized identity solution can help verify student credentials and achievements, providing a permanent and secure way to store educational certificates and diplomas.",
  },
  {
    title: "Building Trust in Online Marketplaces with Decentralized Identity",
    desc: "This whitepaper discusses how Knoct's decentralized identity solution can help build trust in online marketplaces by providing a secure and verifiable digital identity for buyers and sellers.",
  },
  {
    title:
      "Decentralized Identity for Government: Improving Security and Efficiency",
    desc: "This whitepaper examines the use cases of Knoct's decentralized identity solution in government, including identity verification for voting, passport issuance, and secure access to government services.",
  },
  {
    title:
      "Decentralized Identity for Non-Profits: Improving Transparency and Accountability",
    desc: "This whitepaper discusses how Knoct's decentralized identity solution can help non-profits improve transparency and accountability by providing a verifiable digital identity for donors and beneficiaries.",
  },
  {
    title:
      "Improving Supply Chain Management with Decentralized Identity Solutions",
    desc: "This whitepaper examines how Knoct's decentralized identity solution can help improve supply chain management by providing a secure and verifiable way to track and verify product authenticity.",
  },
];



  
   
