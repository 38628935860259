import React from "react";
import frame5 from "../images/products/frame_5.png";
const SimpleIntergation = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-[44px]  mt-[75px] main_padding">
      <div data-aos="fade-left" data-aos-duration="1000">
        <img src={frame5} alt="hero" className="w-full object-contain" />
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        className="text-start gap-4  flex justify-center items-start flex-col lg:w-[498px]"
      >
        <h1 className="text-[clamp(36px,5vw,64px)]  font-medium md:leading-[75px] leading-[50px]">
          <span className="text-green-500">1 Simple Integration</span> <br />{" "}
          for the powerful ID Gateway.
        </h1>
        <p className="text-start md:text-[16px] text-sm leading-[150%]">
          Built or speed and convenience. No-code builder <br /> platform.
          Customize simply as a business.
        </p>
      </div>
    </div>
  );
};

export default SimpleIntergation;
