import { useState } from "react";
import NormalFAQ from "./NormalFAQ";
import { productsQuestionData } from "../data/questionData";
const ProductFaqs = () => {
  const data = [
    "All",
    "InstaCheck",
    "SSI SDK",
    "Wallet SDK",
    "Technology",
    "Other",
  ];

  const [check, setCheck] = useState("All");

  return (
    <div class="mt-[60px] extra_padding    p-5">
      <div className="space-y-3">
        <h1 className="header text-start mb-[36px] ">FAQs</h1>
        <p className="text-start text-[#BEB7C4] md:text-[16px] text-sm mb-[30px] leading-[24px]">
          Subscribe to our news-letter so you don’t missout on latest news.
        </p>

        <div className="flex items-center gap-[20px] text-start flex-wrap mb-[30px]">
          {data.map((item, index) => (
            <button
              key={index}
              onClick={() => setCheck(item)}
              className={`border border-green-500 rounded-lg px-4 py-1  ${
                item === check ? "bg-green-800" : ""
              }`}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
      <NormalFAQ questions={productsQuestionData} />
    </div>
  );
};

export default ProductFaqs;
