import React from "react";
import { Link } from "react-router-dom";

const FreeBusinessAccount = () => {
  return (
    <div className="hightlight  extra_padding  p-5 flex justify-between items-center flex-wrap gap-2 mt-[75px]">
      <h1   className="text_bg text-start  md:w-[757px]">
        Create a free Business Account to Get Started.
      </h1>

      <Link to={'/coming'}   className="btn-solid-header">Get Started</Link>
    </div>
  );
};

export default FreeBusinessAccount;
