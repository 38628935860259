import React from "react";
import "../../pages/Resources/Resource.css";
import press from "../../images/press.png";
import Thumbnail from "../../images/thumbnail.png";
import profile from "../../images/profile.png";
import CustomeSlider from "../../components/CustomeSlider";
import { blogData } from "../../data/blogData";
import { useParams } from "react-router-dom";

const ViewBlog = () => {
  const { id } = useParams();


  return (
    <div className="viewBlogPage main_padding pt-[75px]">
      <div className="flex flex-col gap-5 justify-center items-center">
        <div className=" text-center space-y-5  mx-auto">
          <h1 className="lg:text-6xl text-2xl font-semibold">
            The Future of Digital Identity
          </h1>
          <p className="text-sm ">
            Unlocking the Potential: Exploring the Future of Digital Identity
            with Knoct
          </p>
          <p>2 days ago · 5min read </p>
          <div className="flex justify-center gap-8 items-start flex-wrap">
            <div className="flex gap-3 items-center">
              <img src={profile} alt="press" className="w-[80px]" />
              <div className="space-y-2 text-start">
                <h3 className="text-lg font-semibold">Product Team</h3>
                <p className="text-sm">CEO, Title</p>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              <img src={profile} alt="press" className="w-[80px]" />
              <div className="space-y-2 text-start">
                <h3 className="text-lg font-semibold">Content Team</h3>
                <p className="text-sm">CEO, Title</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={Thumbnail} alt="thumbnail" className="icon_shadow" />
        </div>
      </div>
      <div className="flex flex-wrap mt-[40px]">
        <div className="contexts">
          <h2>Contents</h2>
          <ol className="lg:space-y-[15px] space-y-[10px]">
            <li className="list-disc border-b">intro</li>
            <li className="list-disc border-b">Improved Security</li>
            <li className="list-disc border-b">Increased Privacy</li>
            <li className="list-disc border-b">Interoperability</li>
            <li className="list-disc border-b">User-Centric Design</li>
            <li className="list-disc border-b">Future-Proofing</li>
          </ol>
        </div>
        <div className="Desc-para space-y-5 md:text-[16px] text-sm">
          <p className="md:text-[16px] text-sm">
            In today's world, our digital identity is just as important as our
            physical identity. It's how we interact with each other online, how
            we access services, and how we protect our personal information.
            However, the way we currently manage our digital identity is flawed.
            Our data is scattered across various platforms, often controlled by
            third-party companies, and can be easily compromised. This is where
            Self-Sovereign Identity (SSI) comes in.
            <br />
            <br />
            SSI is a decentralized identity model that puts the control of
            identity back into the hands of the user. It's built on blockchain
            technology, ensuring data security, privacy, and interoperability
            across various platforms. With SSI, users have full control over
            their personal data, deciding who has access to it and how it's
            shared. This eliminates the need for centralized authorities to
            manage identities and reduces the risk of data breaches. At Knoct,
            we're building tools and platforms that enable developers to build
            SSI-based applications easily. Our decentralized storage, identity
            access management, and KMS tools help developers build secure and
            privacy-preserving apps. We believe that the future of digital
            identity is in SSI, and here's why:
          </p>

          <h1 className="font-bold">Improved Security</h1>
          <p>
            Traditional digital identity models rely on centralized authorities
            to manage and verify identities. This makes them vulnerable to
            hacking and data breaches, as we've seen with several high-profile
            incidents in recent years. SSI eliminates this risk by putting the
            control of identity back into the hands of the user. With SSI, users
            have full control over their personal data, and it's stored in a
            decentralized manner, making it much harder for hackers to breach.
          </p>

          <h1 className="font-bold">Increased Privacy</h1>
          <p>
            With traditional digital identity models, our personal data is
            scattered across various platforms, often controlled by third-party
            companies. This makes it challenging to know who has access to our
            data and how it's being used. SSI puts users in control of their
            data, enabling them to decide who has access to it and how it's
            shared. This means that users can maintain their privacy and prevent
            their data from being used for unintended purposes.
          </p>

          <h1 className="font-bold">Interoperability</h1>
          <p>
            One of the significant benefits of SSI is interoperability across
            various platforms. Traditional digital identity models are often
            siloed, with different platforms using different methods of
            verification. SSI eliminates this problem by providing a universal
            standard for identity verification, making it easier to use across
            various platforms. This means that users can use their SSI-based
            identity to access a wide range of services and platforms
            seamlessly.
          </p>

          <h1 className="font-bold">User-Centric Design</h1>
          <p>
            SSI puts users at the center of the identity verification process,
            giving them full control over their data. This means that the design
            of SSI-based applications is user-centric, making it easy for users
            to understand and manage their identity. With SSI, users can manage
            their identity without relying on centralized authorities, reducing
            the risk of data breaches and enhancing privacy.
          </p>

          <h1 className="font-bold">Future-Proofing</h1>
          <p>
            The digital landscape is continually evolving, and traditional
            digital identity models may become obsolete in the future. SSI is
            designed to be future-proof, providing a universal standard for
            identity verification that can adapt to changing technological
            environments. This means that SSI-based applications will remain
            relevant and secure, even as technology evolves.
          </p>
          <p>
            In conclusion, Self-Sovereign Identity is the future of digital
            identity. It's secure, private, interoperable, user-centric, and
            future-proof. At Knoct, we're building tools and platforms that
            enable developers to build SSI-based applications easily. We believe
            that SSI will transform the way we manage our digital identity,
            enabling us to take back control of our personal data and enhance
            our privacy and security online.
          </p>
        </div>
      </div>
      <div className="similar-posts mt-[85px] ">
        <h1 className="header mb-[90px]">Similar Posts</h1>

        <CustomeSlider>
          {blogData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center  shadow shadow-gray-200 border-b border-gray-500 rounded-[8px] "
            >
              <img src={press} alt="" className="w-full h-[161px]" />
              <div className="space-y-[25px] cardBg text-start  h-[250px]  p-[25px]  rounded-[8px] ">
                <h1 className="text-[36px] leading-[32px] font-semibold">
                  {item.title}
                </h1>
                <p className="md:text-[16px] text-sm leading-[20px] text-start text-accent">
                  {item.content.slice(0,120)}...
                </p>
              </div>
            </div>
          ))}
        </CustomeSlider>
      </div>
    </div>
  );
};

export default ViewBlog;
