import React from "react";
import verify from "../images/technology/tech_2.png";
const VerifyIdentity = () => {
  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-[75px] main_padding">
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className="flex flex-col justify-center items-start text-start lg:gap-[35px] gap-[25px] md:order-1 order-2"
      >
        <h1 className="text-[clamp(36px,5vw,64px)] leading-[77px]  font-bold">
          <span className="text-green-500">Verify Identity </span>
          <span className="sub_text_bg">
            {" "}
            without actually sharing sensitive details.
          </span>
        </h1>
        <p className="text-[20px] leading-[24px]">
          using ZK Proof technology, you can verify your identity without
          actually sharing the credentials.
        </p>
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        className="w-[100%] md:order-2 order-1"
      >
        <img src={verify} className="w-full object-contain" alt="" />
      </div>
    </div>
  );
};

export default VerifyIdentity;
