import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "../../pages/Resources/Resource.css";
import { FaSistrix } from "react-icons/fa";
import { FiArrowUpCircle } from "react-icons/fi";
import press from "../../images/press.png";
import { blogData } from "../../data/blogData";
import { Link, useLocation } from "react-router-dom";
import Subscribe from "../../components/Subscribe";
import ScrollToTop from "react-scroll-to-top";
const ShowBlogs = () => {
  const data = [
    "All",
    "InstaCheck",
    "SSI SDK",
    "Wallet SDK",
    "Technology",
    "Other",
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 1.5,
    },
    tablet: {
      breakpoint: { max: 900, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  const [check, setCheck] = useState("All");
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="pt-[75px]">
      <button className="title_border mb-[30px] "> Top posts</button>
      <div className="main_padding">
        <Carousel
          infinite={true}
          autoPlaySpeed={3000}
          autoPlay={true}
          keyBoardControl={true}
          responsive={responsive}
          dotListClass="custom-dot-list-style"
          containerClass="carousel-container z"
          itemClass="carousel-item-padding-40-px"
        >
          {blogData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center w-full shadow-sm shadow-gray-500 rounded-[50px] h-[491px] "
              style={{
                backgroundImage: `url(${press}`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(360deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.646495) 33.59%, rgba(0, 0, 0, 0) 100%), url(GithubStockIllos_OSS__2_.png)",
                  borderRadius: "50px",
                }}
                className="p-[45px] flex justify-end  h-full flex-col items-start gap-[10px]  "
              >
                <h1 className="md:text-[48px] leading-[60px] text-2xl font-semibold text-start">
                  {item.title}
                </h1>
                <p className="md:text-[16px] text-sm text-start w-2/3">{item.content}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div>
        <div className="text-center main_padding mt-[75px] ">
          <h1 className="header mb-[50px]">All post</h1>
          <div className="flex items-center gap-3 flex-wrap justify-center">
            <div className=" flex items-center border border-gray-700 rounded-lg pl-2 py-1 w-[490px]">
              <FaSistrix className="text-2xl" />
              <input
                type="text"
                className=" bg-transparent text-lg p-1 outline-none w-full"
                placeholder="    Search"
              />
            </div>

            <button className=" px-6 py-2  border border-green-500 rounded-lg">
              Search
            </button>
          </div>
          <div className="flex items-center gap-2 flex-wrap justify-center my-[33px]">
            {data.map((item, index) => (
              <button
                key={index}
                onClick={() => setCheck(item)}
                className={`border border-green-500 rounded-lg px-[18px] py-[8px] md:text-[16px] text-sm  ${
                  item === check ? "bg-green-800" : ""
                }`}
              >
                {item}
              </button>
            ))}
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
            {blogData.map((item, index) => (
              <Link to={`/resources/viewblog/${item._id}`}>
                <div
                  key={index}
                  className="flex flex-col items-center justify-center w-full h-full shadow-sm shadow-gray-500 rounded-xl"
                >
                  <img src={press} alt="" className="w-full" />
                  <div className="p-5 space-y-3 cardBg text-start h-full">
                    <h1 className="text-[36px] font-semibold">{item.title}</h1>
                    <p className="md:text-[16px] text-sm text-start text-accent">
                      {item.content}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <button className="text-center border-b mt-[33px]">Load More</button>
      </div>
      <div >
        <Subscribe />
      </div>
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default ShowBlogs;
