export const questionData = [
  {
    title: "What is the cost of Knoct?",
    value:
      "Knoct offers a flexible pricing model based on your specific needs. Please contact us for a personalized quote.",
  },
  {
    title: "Is there a free trial?",
    value:
      "Knoct offers a flexible pricing model based on your specific needs. Please contact us for a personalized quote.",
  },
  {
    title: "How is Knoct priced?",
    value:
      "Knoct offers a flexible pricing model based on your specific needs. Please contact us for a personalized quote.",
  },
  {
    title: "Are there any additional fees?",
    value:
      "Knoct offers a flexible pricing model based on your specific needs. Please contact us for a personalized quote.",
  },
];
export const productsQuestionData = [
  {
    title: "What products does Knoct offer?",
    value:
      "Knoct offers a range of products designed to enhance digital identity management and verification, including Knoct Wallet SDK, Knoct Wallet, Knoct Dash, Knoct Identity, InstaCheck, Authy, Onboarding, Dvault, Dactions, and DMarket.",
  },
  {
    title: "What is Knoct Wallet SDK?",
    value:
      "Knoct offers a range of products designed to enhance digital identity management and verification, including Knoct Wallet SDK, Knoct Wallet, Knoct Dash, Knoct Identity, InstaCheck, Authy, Onboarding, Dvault, Dactions, and DMarket.",
  },
  {
    title: "What is Knoct Wallet?",
    value:
      "Knoct offers a range of products designed to enhance digital identity management and verification, including Knoct Wallet SDK, Knoct Wallet, Knoct Dash, Knoct Identity, InstaCheck, Authy, Onboarding, Dvault, Dactions, and DMarket.",
  },
  {
    title: "What is InstaCheck?",
    value:
      "Knoct offers a range of products designed to enhance digital identity management and verification, including Knoct Wallet SDK, Knoct Wallet, Knoct Dash, Knoct Identity, InstaCheck, Authy, Onboarding, Dvault, Dactions, and DMarket.",
  },
];
