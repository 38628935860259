import React from "react";
import "../Resource.css";
import Thumbnail from "../../../images/thumbnail.png";
import CustomeSlider from "../../../components/CustomeSlider";
import SingleUseCases from "../../../components/SingleUseCases";
import { productUseCases } from "../../../data/products";

const SingleUseCasePage = () => {
  return (
    <div className="viewBlogPage main_padding mt-[75px]">
      <div className="flex flex-col gap-5 justify-center items-center">
        <div className=" text-center space-y-[20px]">
          <span className="title_border">Use Case ➙ Finance</span>
          <h1 className="lg:text-[96px] text-5xl leading-[110px] font-semibold">
            Decentralized Banking
          </h1>
          <p className="md:text-[16px] text-sm ">
            Transforming Banking Identity: How Knoct's Decentralized Identity
            Solution Can Benefit Banks and Customers
          </p>
          <p>5min read </p>
        </div>
        <div className="my-8">
          <img src={Thumbnail} alt="thumbnail" className="icon_shadow" />
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="contexts">
          <h2>Contents</h2>
          <ol className="lg:space-y-[15px] space-y-[10px]">
            <li className="list-disc border-b">intro</li>
            <li className="list-disc border-b">Improved Security</li>
            <li className="list-disc border-b">Increased Privacy</li>
            <li className="list-disc border-b">Interoperability</li>
            <li className="list-disc border-b">User-Centric Design</li>
            <li className="list-disc border-b">Future-Proofing</li>
          </ol>
        </div>

        <div className="Desc-para space-y-5">
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien frin
            gilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
            mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare
            leo, non suscipit magna interdum eu. Curabitur pellentesque nibh
            nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus
            at sodales sodales. Quisque sagittis orci ut diam condimentum, vel
            euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque vestibulum,
            nulla ex pharetra sapien, tempor posuere massa neque nec felis.
            Aliquam sem ipsum, vehicula ac tortor vel, egestas ullamcorper dui.
            Curabitur at risus sodales, tristique est id, euismod justo. Mauris
            nec leo non libero sodales lobortis. Quisque a neque pretium, dictum
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien frin
            gilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
            mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare
            leo, non suscipit magna interdum eu. Curabitur pellentesque nibh
            nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus
            at sodales sodales. Quisque sagittis orci ut diam condimentum, vel
            euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque vestibulum,
            nulla ex pharetra sapien, tempor posuere massa neque nec felis.
            Aliquam sem ipsum, vehicula ac tortor vel, egestas ullamcorper dui.
            Curabitur at risus sodales, tristique est id, euismod justo. Mauris
            nec leo non libero sodales lobortis. Quisque a neque pretium, dictum
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien frin
            gilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
            mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare
            leo, non suscipit magna interdum eu. Curabitur pellentesque nibh
            nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus
            at sodales sodales. Quisque sagittis orci ut diam condimentum, vel
            euismod erat placerat. In iaculis arcu eros, eget tempus orci
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien frin
            gilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae
            mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare
            leo, non suscipit magna interdum eu. Curabitur pellentesque nibh
            nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus
            at sodales sodales. Quisque sagittis orci ut diam condimentum, vel
            euismod erat placerat. In iaculis arcu eros, eget tempus orci
          </p>
        </div>
      </div>
      <div className="similar-posts mt-[30px]">
        <div>
          <h1 className="header mb-[90px]">Explore Other Use Cases</h1>
        </div>
        <div>
          <CustomeSlider>
            {productUseCases.map((item, index) => (
              <SingleUseCases key={index} item={item} />
            ))}
          </CustomeSlider>
        </div>
      </div>
    </div>
  );
};

export default SingleUseCasePage;
