import React from "react";
import form from "../images/Resources/details_form.png";
const DetailsForm = () => {
  return (
    <div className="flex justify-center items-center lg:gap-[62px] md:gap-[40px] gap-5 main_padding mt-[75px] flex-wrap md:flex-nowrap">
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className=" lg:w-[605px]"
      >
        <img src={form} className=" lg:w-[400px] icon_shadow " alt="" />
      </div>
      <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className="flex flex-col justify-center items-start text-start md:gap-[30px] gap-3 md:w-[634px]"
      >
        <h1 className="lg:text-[64px] md:text-[56px] leading-[75px]   font-bold">
          <span className="sub_text_bg">Fill Long Forms with just </span>
          <span className="text-green-500">One Click</span>
        </h1>
        <p className="md:text-[16px] text-sm leading-[24px]">
          You are more than your wallet. Create a public profile to showcase
          your NFTs
        </p>
      </div>
    </div>
  );
};

export default DetailsForm;
