
import airline from '../images/icons/services/service_1.png'
import insurance from '../images/icons/services/service_2.png'
import hrms from '../images/icons/services/service_3.png'
import workspaces from '../images/icons/services/service_4.png'
import banks from '../images/icons/services/service_5.png'
import hotels from '../images/icons/services/service_6.png'
import hospitals from '../images/icons/services/service_7.png'
import visa from '../images/icons/services/service_8.png'
import digital from '../images/icons/services/service_9.png'



export const allServices = [
  {img: airline,title: "Airlines"},
  {img: insurance,title: "Insurance"},
  {img: hrms,title: "HRMS"},
  {img: workspaces,title: "Workspaces"},
  {img: banks,title: "Banks"},
  {img: hotels,title: "Hotels"},
  {img: hospitals,title: "Hospitals"},
  {img: visa,title: "Visa Centers"},
  {img: digital,title: "Digital Services"},
];
