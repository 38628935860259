import './App.css';
import { RouterProvider, useLocation } from 'react-router-dom';
import routes from './routes/routes';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';


function App() {
 useEffect(()=>{
  AOS.init({startEvent: 'DOMContentLoaded', disable: false,offset: 30});
 },[])

  return (
    <div className="App">
      
       <RouterProvider router={routes} />
    </div>
  );
}

export default App;
