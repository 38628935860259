import React, { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FiArrowUpCircle } from "react-icons/fi";
import ProductFaqs from "../../components/ProductFaqs";
import ProductHero from "./ProductHero";
import SimpleIntergation from "../../components/SimpleIntergation";
import OurMission from "../../components/OurMission";
import Technology from "./Technology";
import ProductEcosystem from "../../components/ProductEcosystem";
import UseCases from "../../components/UseCases";
import HearCustomers from "../../components/HearCustomers";
import Subscribe from "../../components/Subscribe";
import Questions from "../../components/Questions";
import ProductKINWallet from "../../components/ProductKINWallet";
import ProductKnoctDash from "../../components/ProductKnoctDash";
import ProductKnoctFlow from "../../components/ProductKnoctFlow";
import OurClients from "../../components/OurClients";
import icon1 from "../../images/icons/ex_1.png";
import icon2 from "../../images/icons/ex_3.png";
import icon3 from "../../images/icons/ex_8.png";
import icon4 from "../../images/icons/encryption.png";
import icon5 from "../../images/icons/ex_7.png";
import EyesRoadProduct from "../../components/EyesRoadProduct";
import { Link, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

const Products = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="pt-[75px]">
      <div className="text-center relative ">
        <div data-aos-duration="1000" data-aos="zoom-in">
          <h1 className="big-header">
            Own Your Personal <br /> Data & Digital Identity
          </h1>
          <p className="md:text-[24px] text-lg pt-[20px] text-[#F8F9FB] leading-[30px]">
            Build secure & privacy preserving apps, easily.
          </p>

          <div className="flex justify-center items-center gap-[23px] flex-wrap py-[35px]">
            {/* <Link to={"/identity"} className="btn-solid-header">
              Get Started
            </Link> */}
            <a
              href="https://www.dash.knoct.in/"
              target="_blank" 
              rel="noreferrer"
              className="btn-outline-header  flex items-center gap-2"
            >
              Book a demo <FaArrowRight />
            </a>
          </div>
        </div>
        <div className="w-full h-full ">
          <div className="absolute left-[45%] top-[-15%] opacity-30">
            <img
              src={icon1}
              alt=""
              className="lg:w-[50px] md:w-[40px] w-[30px]"
            />
          </div>
          <div className="absolute left-[10%] top-[0%] opacity-30">
            <img
              src={icon2}
              alt=""
              className="lg:w-[50px] md:w-[40px] w-[30px]"
            />
          </div>
          <div className="absolute right-[10%] top-[0%] opacity-30">
            <img
              src={icon3}
              alt=""
              className="lg:w-[50px] md:w-[40px] w-[30px]"
            />
          </div>
          <div className="absolute left-[5%] top-[59%] opacity-30">
            <img
              src={icon4}
              alt=""
              className="lg:w-[50px] md:w-[40px] w-[30px]"
            />
          </div>
          <div className="absolute right-[5%] top-[59%] opacity-30">
            <img
              src={icon5}
              alt=""
              className="lg:w-[50px] md:w-[40px] w-[30px]"
            />
          </div>
        </div>
      </div>

      <ProductHero />
      <div className="main_padding">
        <ProductKINWallet />
        <ProductKnoctDash />
        <ProductKnoctFlow />
      </div>
      <SimpleIntergation />
      <OurMission />
      <Technology />
      <EyesRoadProduct />
      <ProductEcosystem />
      <UseCases />
      <OurClients />
      <HearCustomers />
      <Subscribe />
      <ProductFaqs />
      <Questions />
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default Products;
