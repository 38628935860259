import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className=" text-white text-left main_padding">
      <div className="p-5  space-y-5 ">
        <div>
          <h2 className="text-3xl mb-4 font-semibold">Privacy Policy</h2>
          <p className="text-sm text-white text-left">
            Last updated on 09-02-2024 15:23:47
          </p>
        </div>
        <div>
          <p className="text-[16px] text-white text-left">
            This privacy policy (“Policy”) relates to the manner VESAK
            TECHNOLOGIES PRIVATE LIMITED (“we”, “us”, “our”) in which we use,
            handle and process the data that you provide us in connection with
            using the products or services we offer. By using this website or by
            availing goods or services offered by us, you agree to the terms and
            conditions of this Policy, and consent to our use, storage,
            disclosure, and transfer of your information or data in the manner
            described in this Policy.
          </p>
          <br />
          <p className="text-[16px] text-white text-left">
            {" "}
            We are committed to ensuring that your privacy is protected in
            accordance with applicable laws and regulations. We urge you to
            acquaint yourself with this Policy to familiarize yourself with the
            manner in which your data is being handled by us.
          </p>{" "}
          <br />
          <p className="text-[16px] text-white text-left">
            {" "}
            VESAK TECHNOLOGIES PRIVATE LIMITED may change this Policy
            periodically and we urge you to check this page for the latest
            version of the Policy in order to keep yourself updated.
          </p>
        </div>

        <div>
          <h3 className="text-xl">What data is being collected</h3>
          <p className="text-[16px] text-white text-left">
            We may collect the following information from you:
          </p>
          <ol className="list-disc space-y-1 p-5">
            <li>Name</li>
            <li>Contact information including address and email address</li>
            <li>Demographic information or, preferences or interests</li>
            <li>
              Personal Data or Other information relevant/ required for
              providing the goods or services to you
            </li>
            <li>
              The meaning of Personal Data will be as defined under relevant
              Indian laws
            </li>
          </ol>
          <p className="text-[16px] text-white text-left">
            <b>Note:</b> Notwithstanding anything under this Policy as required
            under applicable Indian laws, we will not Note be storing any credit
            card, debit card or any other similar card data of yours. Please
            also note that all data or information collected from you will be
            strictly in accordance with applicable laws and guidelines.
          </p>
        </div>

        <div>
          <h3 className="text-xl">What we do with the data we gather</h3>
          <p className="text-[16px] text-white text-left">
            We require this data to provide you with the goods or services
            offered by us including but not limited, for the below set out
            purposes:
          </p>
          <ol className="list-disc space-y-1 p-5">
            <li>Internal record keeping.</li>
            <li>For improving our products or services.</li>
            <li>
              For providing updates to you regarding our products or services
              including any special offers.
            </li>
            <li>To communicate information to you</li>
            <li>For internal training and quality assurance purposes</li>
          </ol>
        </div>
        <div>
          <h3 className="text-xl">Who do we share your data with</h3>
          <p className="text-[16px] text-white text-left">
            We may share your information or data with:
          </p>
          <ol className="list-disc space-y-1 p-5">
            <li>
              Third parties including our service providers in order to
              facilitate the provisions of goods or services to you, carry out
              your requests, respond to your queries, fulfil your orders or for
              other operational and business reasons.
            </li>
            <li>With our group companies (to the extent relevant)</li>
            <li>
              Our auditors or advisors to the extent required by them for
              performing their services
            </li>
            <li>
              Governmental bodies, regulatory authorities, law enforcement
              authorities pursuant to our legal obligations or compliance
              requirements.
            </li>
          </ol>
        </div>
        <div>
          <h3 className="text-xl">How we use cookies</h3>
          <p className="text-[16px] text-white text-left">
            We use "cookies" to collect information and to better understand
            customer behaviour. You can instruct your browser to refuse all
            cookies or to indicate when a cookie is being sent. However, if you
            do not accept cookies, you may not be able to avail our goods or
            services to the full extent. We do not control the use of cookies by
            third parties. The third party service providers have their own
            privacy policies addressing how they use such information
          </p>
        </div>
        <div>
          <h3 className="text-xl">Your rights relating to your data</h3>
          <p className="text-[16px] text-white text-left">
            <i>
              <b>Right to Review</b>
            </i>{" "}
            You can review the data provided by you and can request us to
            correct or amend such data (to the extent feasible, as determined by
            us). That said, we will not be responsible for the authenticity of
            the data or information provided by you
          </p>
          <p className="text-[16px] text-white text-left">
            <i>
              <b>Withdrawal of your Consent</b>
            </i>{" "}
            You can choose not to provide your data, at any time while availing
            our goods or services or otherwise withdraw your consent provided to
            us earlier, in writing to our email ID: knoctio@gmail.comIn the
            event you choose to not provide or later withdraw your consent, we
            may not be able to provide you our services or goods.Please note
            that these rights are subject to our compliance with applicable
            laws.
          </p>
        </div>
        <div>
          <h3 className="text-xl">
            How long will we retain your information or data?
          </h3>
          <p className="text-[16px] text-white text-left">
            We may retain your information or data (i) for as long as we are
            providing goods and services to you; and (ii) as permitted under
            applicable law, we may also retain your data or information even
            after you terminate the business relationship with us. However, we
            will process such information or data in accordance with applicable
            laws and this Policy.
          </p>
        </div>
        <div>
          <h3 className="text-xl">Data Security</h3>
          <p className="text-[16px] text-white text-left">
            We will use commercially reasonable and legally required precautions
            to preserve the integrity and security of your information and data
          </p>
        </div>
        <div>
          <h3 className="text-xl">Queries/ Grievance Officer</h3>
          <p className="text-[16px] text-white text-left">
            For any queries, questions or grievances about this Policy, please
            contact us using the contact information provided on this website
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
