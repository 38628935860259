export const blogData=[
    {
        _id:1,
        title:"Privacy by Design: The Foundation of SSI",
        content:"In this blog, we discuss the importance of privacy by design in self-sovereign identity and how it can help build trust with users,means that users can decide what information to share"
    },
    {
        _id:2,
        title:"Future of Digital Identity",
        content:"This blog post explores the limitations of traditional authentication methods and how Knoct is transforming the digital identity landscape."
    },
    {
        _id:3,
        title:"Power of Self-Sovereign Identity",
        content:"This blog post discusses the benefits of self-sovereign identity and how Knoct enables users to take control of their personal data."
    },
    {
        _id:4,
        title:" ZKP and Knoct",
        content:"This blog post explores Knoct's use of zero-knowledge proofs to provide secure and private authentication without sharing sensitive data. A Breakthrough in Privacy-Preserving Authentication"
    },
    {
        _id:5,
        title:"Simplifying Digital Identity Management",
        content:"This blog post discusses Knoct's user-friendly approach to digital identity management and how it simplifies the authentication process."
    },
    {
        _id:6,
        title:"User-Centric Design in Digital IAM",
        content:"This blog post discusses the importance of user-centric design in digital identity management."
    },
    {
        _id:7,
        title:"Knoct vs. Traditional Authentication",
        content:"This blog post provides a comparison between Knoct and traditional authentication methods, highlighting the advantages and disadvantages "
    },
    {
        _id:8,
        title:"Revolutionizing  Authentication",
        content:"This blog post discusses Knoct's long-term vision for the future of digital identity  and how it aims to revolutionize authentication."
    },
    {
        _id:9,
        title:"Introducing Knoct",
        content:"This blog post provides an overview of Knoct and its innovative approach to digital identity management."
    },
   
    
]