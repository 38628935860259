import React from "react";
import frame13 from "../images/products/frame_13.png";
import icon from "../images/icons/icon_1.png";
import icon2 from "../images/icons/icon_2.png";
import icon3 from "../images/icons/icon_3.png";
const KnoctCerts = () => {
  return (
    <div
      data-aos="fade-left"
      data-aos-duration="1000"
      className="grid md:grid-cols-2 grid-cols-1 gap-[35px] mt-[75px] main_padding"
    >
      <div className="colorful_bg justify-center flex items-end ">
        <img src={frame13} alt="" className="object-contain" />
      </div>
      <div className=" text-start">
        <span className="title_border ">Knoct Certs</span>
        <h1 className="text_bg leading-[45px] lg:my-[35px] my-5">
          Issue Credentials. End Document Fraud.
        </h1>
        <div className="space-y-[10px]">
          <div className="flex items-start gap-[25px]">
            <img src={icon} alt="" className="w-[45px] h-[45px]" />
            <div className="space-y-[10px]">
              <h1 className="knoct_sub_normal_header">
                Wallet to wallet Verification
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px !important]">
                Your stakeholders can import the Verification QR Code to the
                Knoct Dock Wallet to enable Wallet-to-Wallet Verification. Users
                can scan the Verification QR Code directly from the Verifier’s
                wallet.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon2} alt="" className="w-[60px]" />
            <div className="space-y-[10px]">
              <h1 className="knoct_sub_normal_header">
                Selective and Anonymous Disclosure
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px !important]">
                When users submit a document for verification, they can share
                one part of the document without submitting the entire document.
                For example, a student could share the name of his university
                from his Student Card without sharing his name or student
                number.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-[25px]">
            <img src={icon3} alt="" className="w-[60px]" />
            <div className="space-y-[10px]">
              <h1 className="knoct_sub_normal_header">
                Issue from web or REST API
              </h1>
              <p className="knoct_pro_hero_child_text leading-[22px !important]">
                Your stakeholders can display the Verification QR Code on the
                web using a URL that holders can scan with their Knoct Dock
                Wallets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnoctCerts;
