import React from "react";
import { Link } from "react-router-dom";

const CaseStudy = () => {
  return (
    <div className="hightlight mt-[75px]  extra_padding  p-5 flex justify-between items-center gap-5 flex-wrap">
      <div   className="text-start max-w-[779px] md:w-[60%]">
        <h1 className="text_bg mb-[10px]">
          Knoct Flow in action. Read Case-Study
        </h1>
        <p className="text-[20px] leading-[30px]">
          See how Knoct Flow helps Big Orgamizations manage identities and
          credentials
        </p>
      </div>
      <Link to={'/coming'}  className="btn-solid-header">Read Case Study</Link>
    </div>
  );
};

export default CaseStudy;
