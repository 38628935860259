import React, { useEffect } from "react";

import DigitalWallet from "../../../components/DigitalWallet";
import ExchangeTrusted from "../../../components/ExchangeTrusted";
import Features from "../../../components/Features";
import IdentityFunction from "../../../components/IdentityFunction";
import Market from "../../../components/Market";
import Privacy from "../../../components/Privacy";
import UserControl from "../../../components/UserControl";
import wallet from "../../../images/icons/wallet.png";
import hero from "../../../images/products/knoct_hero.png";
import HearCustomers from "../../../components/HearCustomers";
import FreeAccount from "../../../components/FreeAccount";
import ProductFaqs from "../../../components/ProductFaqs";
import Questions from "../../../components/Questions";
import OurClients from "../../../components/OurClients";
import ScrollToTop from "react-scroll-to-top";
import { FiArrowRight, FiArrowUpCircle } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
const KnoctWallet = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className=" pt-[75px]" id="wallet">
      <div className="main_padding">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="md:py-[90px] p-5 md:rounded-[50px] rounded-[30px] max-w-[1255px] mx-auto"
        >
          <div
            data-aos-duration="1000"
            data-aos="zoom-in"
            className="max-w-[655px] mx-auto"
          >
            <div className="inline-block">
              <p className="title_border flex items-center gap-2">
                Product <FiArrowRight /> Knoct-wallet
              </p>
            </div>
            <div className="flex gap-[25px] knoct_product_hero mt-[33px] md:py-[40px] md:px-[25px] p-5">
              <div className="w-[90px] h-[110px]">
                <img src={wallet} alt="" className=" object-contain mx-auto" />
              </div>
              <div className=" text-start space-y-[25px]">
                <h2 className=" knoct_pro_hero_child_header">Knoct Wallet</h2>
                <p className="knoct_pro_hero_child_text">
                  The original self-sovereign digital wallet app.
                </p>
                <div className="flex justify-start items-center gap-[25px] flex-wrap">
                <a href="https://www.wallet.knoct.in/" target="_blank"  rel="noreferrer" className="btn-solid ">Get Access</a>
                <a href="https://app.gitbook.com/o/T5Y8WOJkdPjpMkI9IHJa/s/QHZVLH09VQ5qFzBaodk6/" target="_blank" rel="noreferrer" className="btn-outline ">View Documentation</a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserControl />
      <IdentityFunction />
      <Privacy />
      <ExchangeTrusted />
      <Market />
      <DigitalWallet />
      <Features />
      <OurClients />
      <HearCustomers />
      <FreeAccount />
      <ProductFaqs />
      <Questions />
      <ScrollToTop
        smooth
        top={1000}
        component={
          <div className="text-3xl text-green-500 flex justify-center items-center">
            <FiArrowUpCircle />
          </div>
        }
      />
    </div>
  );
};

export default KnoctWallet;
