import React from "react";
import { GoClock, GoLocation } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";

import Subscribe from "../../../components/Subscribe";
import { Link, useParams } from "react-router-dom";

const SingleJob = () => {
  const { id } = useParams();

  return (
    <div className=" text-start mt-[75px] pt-[75px]">
      <div className="main_padding text-start">
        <div className="space-y-[50px]">
          <span className="title_border">Job Opening</span>
          <h1 className="header">Product Designer</h1>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <GoLocation />
              <p className="md:text-[16px] text-sm">Remote</p>
            </div>
            <div className="flex items-center gap-2">
              <GoClock />
              <p className="md:text-[16px] text-sm">Full-time</p>
            </div>
          </div>
          <Link to={'/coming'} className="btn-solid  flex items-center gap-4">
            Apply for this position <FaArrowRight />{" "}
          </Link>
        </div>

        <div className="space-y-5 py-[85px]">
          <h1 className="lg:text-[36px] text-2xl font-semibold">
            Job Description
          </h1>
          <p className="md:text-[16px] text-sm">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque vestibulum,
            nulla ex pharetra sapien, tempor posuere massa neque nec felis.
            Aliquam sem ipsum, vehicula ac tortor vel, egestas ullamcorper dui.
            Curabitur at risus sodales, tristique est id, euismod justo. Mauris
            nec leo non libero sodales lobortis. Quisque a neque pretium, dictum
            tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
            dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
            maximus tempor eget augue. Aenean at ultricies lorem.
          </p>
          <p className="md:text-[16px] text-sm">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque vestibulum,
            nulla ex pharetra sapien, tempor posuere massa neque nec felis.
            Aliquam sem ipsum, vehicula ac tortor vel, egestas ullamcorper dui.
            Curabitur at risus sodales, tristique est id, euismod justo. Mauris
            nec leo non libero sodales lobortis. Quisque a neque pretium, dictum
            tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
            dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
            maximus tempor eget augue. Aenean at ultricies lorem.
          </p>
          <p className="md:text-[16px] text-sm">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id.
          </p>
          <p className="md:text-[16px] text-sm">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id. Praesent lorem orci, mattis non efficitur id,
            ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce
            vel tempor elit. Proin tempus, magna id scelerisque vestibulum,
            nulla ex pharetra sapien, tempor posuere massa neque nec felis.
            Aliquam sem ipsum, vehicula ac
          </p>
          <p className="md:text-[16px] text-sm">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id.
          </p>
          <p className="md:text-[16px] text-sm">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
            fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
            vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor
            ornare leo, non suscipit magna interdum eu. Curabitur pellentesque
            nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo
            lacus at sodales sodales. Quisque sagittis orci ut diam condimentum,
            vel euismod erat placerat. In iaculis arcu eros, eget tempus orci
            facilisis id.
          </p>
        </div>
      </div>
      <Subscribe />
    </div>
  );
};

export default SingleJob;
