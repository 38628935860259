import React from "react";
import { teamData } from "../data/teamData";
const Team = () => {
  return (
    <div className="extra_padding md:py-[25px] my-[75px] ">
      <h1 className="header text-start ">The Team</h1>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-[35px] mt-[25px]">
        {teamData.map((people, index) => (
          <div
            key={index}
            className="flex gap-5 items-center justify-center flex-col cardBg  p-5 rounded-xl   border border-gray-600 hover:border-[#46DE93] duration-300 w-full mx-auto"
          >
            <div className="avatar ">
              <div className="w-[150px] h-[150px] rounded-full cursor-pointer  group transition-all bg-white">
                <img
                  src={people.img}
                  alt="profile"
                  className="object-contain block group-hover:hidden"
                />
                <img
                  src={people.img_second}
                  alt="profile"
                  className="object-contain group-hover:block hidden transform scale-105  "
                />
              </div>
            </div>

            <h1 className="text-[20px] font-semibold text-[#46DE93]">{people.name}</h1>
            <p className="text-[#726D75] text-[16px]">{people.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
