import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const KnoctForDevelopers = () => {
  return (
    <div className="hightlight  extra_padding  mt-[75px] flex  md:flex-row flex-col justify-between md:items-center items-start  gap-5 ">
      <div   className="text-start space-y-3 max-w-[663px]  md:w-[55%] w-full">
        <h1 className="text_bg leading-[60px!important] ">
          Excited to build with our technology?
        </h1>
        <p className="text-[20px] text-white leading-[33px]">
          Learn more about Knoct for developers and get started with a free
          developer account.
        </p>
      </div>
      <Link to={'/coming'}>
      <button  className="btn-solid-header flex items-center gap-3">
        Knoct for Developers <FaArrowRight className="text-2xl" />
      </button></Link>
    </div>
  );
};

export default KnoctForDevelopers;
