import React from "react";
import { Link } from "react-router-dom";

const FreeAccount = () => {
  return (
    <div className="hightlight  extra_padding  p-5 flex justify-between items-center gap-2 flex-wrap mt-[75px]">
      <div className="text-start ">
        <h1 className="text_bg lg:w-[757px] ">
          Create a free Account to Get Started.
        </h1>
      </div>
      <Link to={'/coming'} className="btn-solid-header">Get Started</Link>
    </div>
  );
};

export default FreeAccount;
