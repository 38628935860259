
import vedant from "../images/profile/vedant.png";
import swapnil from "../images/profile/shapnil.png";
import amit from "../images/profile/amit.png";
import sonali from "../images/profile/sonali.png";
import riaz from "../images/profile/riaz.png";
import abhishek from "../images/profile/abishek.png";
import shivang from "../images/profile/shivang.png";
import gautam from "../images/profile/gautam.png"

export const teamData = [
  { img: vedant,img_second: vedant, name: "Vedanth Padigelwar", title: "CEO" },
  { img: amit,img_second: amit, name: "Amit Sagar", title: "CTO" },
  { img: shivang,img_second: shivang, name: "Shivang Kamboj", title: "Product and Strategy" },
  { img: sonali,img_second: sonali, name: "Sonali Manjrekar", title: "VP , Human Resources" },
  { img: riaz,img_second: riaz, name: "Riaz Uddin", title: "Full Stack Developer" },
  { img: gautam,img_second: gautam, name: "Gautam Rawat", title: "Full Stack Developer" },
];
